import { Sender } from '@utils/observable';
import { ModelArrayType, ModelTypeMap } from '@baseModel/engine/types';
import { Query } from '@baseModel/engine/queryBuilder/types';
import { Engine } from '@baseModel/engine/engine';
import { useEffect, useState } from 'react';
import { useDeepMemo } from '@hooks/useDeepMemo';

export function useEngineQuery<K extends keyof ModelTypeMap>(
  engine: Engine,
  type: K,
  query?: Query<ModelTypeMap[K]>,
  sender?: Sender,
  skip = false
): ModelArrayType<K> {
  const memorizedQuery = useDeepMemo(query);
  const [data, setData] = useState<ModelArrayType<K>>([] as unknown as ModelArrayType<K>);
  useEffect(() => {
    if (skip) {
      return;
    }
    const subscription = engine.getObservableModels(type, memorizedQuery, sender).subscribe((queryData) => {
      setData(queryData);
    });
    return () => subscription.unsubscribe();
  }, [engine, memorizedQuery, sender, skip, type]);
  return data;
}
