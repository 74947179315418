import React from 'react';
import { Button, Table as SmwbTable, TableBody, TableCell, TableHead, TableHeadCell, TableRow } from '@smwb/summer-ui';
import isEmpty from 'lodash/isEmpty';
import kebabCase from 'lodash/kebabCase';
import { Models } from '@baseModel/engine/types';
import { EntityMetaModel } from '@baseModel/metaModel/entityMetaModel';
import { RelationMetaModel } from '@baseModel/metaModel/relationMetaModel';
import { TextCommonEntity, TextFieldEntityBasic } from '@baseModel/components/inputs/textFieldEntity';
import { DataStoreName } from '@baseModel/utils/dataJuggler';
import { ToggleFieldEntityBasic } from '@baseModel/components/inputs/toggleEntityField';
import { useFields } from '@baseModel/hooks/useFields';
import { SelectEntityField } from '@baseModel/components/inputs/selectEntityField';
import { ActionType } from '@components/metaTableView/types';
import { fieldColumns, FieldType } from '../utils/fieldsColumns';
import { useAppDispatch } from '../../../redux/store';
import { set } from '../redux/actions';
import { confirmDialog } from '../../confirmDialog/confirmDialog';
import { CellActions } from './cellActions';
import s from './table.module.less';

const requiredCommonFields = ['displayName'];
const requiredFields = ['id'];

export interface TableProps {
  model: EntityMetaModel | RelationMetaModel;
}

export function Table({ model }: TableProps) {
  const dispatch = useAppDispatch();
  const { commonFieldNames, fieldNames, relationFieldNames } = useFields(model.modelType, model.getName());

  const onDeleteField = React.useCallback(
    (dataStoreName: DataStoreName, name: string) => () => {
      switch (dataStoreName) {
        case DataStoreName.common:
          if (requiredCommonFields.includes(name)) {
            break;
          }

          model.removeCommon(name);
          break;
        case DataStoreName.fields:
          if (requiredFields.includes(name)) {
            break;
          }

          model.removeField(name);
          break;
        case DataStoreName.relations:
          model.removeRelation(name);
          break;
      }
    },
    [model]
  );

  const onDeleteAccept = React.useCallback(
    (dataStoreName: DataStoreName, name: string) => () => {
      confirmDialog({
        message: 'Безвозвратное удаление. Вы действительно хотите удалить?',
        accept: onDeleteField(dataStoreName, name)
      });
    },
    [onDeleteField]
  );

  const commonFields = React.useMemo(() => {
    return commonFieldNames.map((commonName) => {
      return (
        <TableRow key={commonName}>
          <TableCell>{kebabCase(commonName)}</TableCell>
          <TableCell>
            <TextCommonEntity
              modelType={model.modelType}
              modelName={model.getName()}
              fieldName={commonName}
              inline
              fullWidth
            />
          </TableCell>
          <CellActions
            onDelete={onDeleteAccept(DataStoreName.common, commonName)}
            deleteDisabled={requiredCommonFields.includes(commonName)}
          />
        </TableRow>
      );
    });
  }, [commonFieldNames, model, onDeleteAccept]);

  const getFieldColumns = React.useCallback(
    (dataStoreName: DataStoreName.fields | DataStoreName.relations, fieldNames: string[]) => {
      return fieldNames.map((fieldName) => (
        <TableRow key={fieldName}>
          <TableCell>{fieldName}</TableCell>
          {fieldColumns.map((fieldColumn) => {
            let node: React.ReactNode = null;

            switch (fieldColumn.type) {
              case FieldType.Text:
                node = (
                  <TextFieldEntityBasic
                    dataStoreName={dataStoreName}
                    modelType={model.modelType}
                    modelName={model.getName()}
                    fieldName={fieldName}
                    fieldPart={fieldColumn.key}
                    inline
                    fullWidth
                  />
                );

                break;
              case FieldType.Toggle:
                node = (
                  <div className={s.cellToggle}>
                    <ToggleFieldEntityBasic
                      dataStoreName={dataStoreName}
                      modelType={model.modelType}
                      modelName={model.getName()}
                      fieldName={fieldName}
                      fieldPart={fieldColumn.key}
                    />
                  </div>
                );

                break;
              case FieldType.Select: {
                const options = fieldColumn.options[dataStoreName]();

                node = (
                  <SelectEntityField
                    dataStoreName={dataStoreName}
                    modelType={model.modelType}
                    modelName={model.getName()}
                    fieldName={fieldName}
                    fieldPart={fieldColumn.key}
                    items={options.map(({ key, name }) => ({ key, value: key, text: name }))}
                    inline
                    fullWidth
                  />
                );

                break;
              }
            }

            return (
              <TableCell key={fieldColumn.key} cellAlign="center">
                {node}
              </TableCell>
            );
          })}
          <CellActions
            onDelete={onDeleteAccept(dataStoreName, fieldName)}
            deleteDisabled={requiredFields.includes(fieldName)}
          />
        </TableRow>
      ));
    },
    [model, onDeleteAccept]
  );

  const fieldsHeaderColumns = [<TableHeadCell key="field">Поле</TableHeadCell>];

  for (const fieldColumn of fieldColumns) {
    fieldsHeaderColumns.push(<TableHeadCell key={fieldColumn.key}>{fieldColumn.title}</TableHeadCell>);
  }

  fieldsHeaderColumns.push(<TableHeadCell key="actions" />);

  const fields = React.useMemo(() => getFieldColumns(DataStoreName.fields, fieldNames), [fieldNames, getFieldColumns]);
  const relations = React.useMemo(
    () => getFieldColumns(DataStoreName.relations, relationFieldNames),
    [getFieldColumns, relationFieldNames]
  );

  const onAddFieldButtonClick = () => {
    dispatch(set({ type: ActionType.Add, modelType: model.modelType, modelName: model.getName() }));
  };

  return (
    <div className={s.root}>
      <SmwbTable>
        <TableHead>
          <TableRow>
            <TableHeadCell>Системное имя</TableHeadCell>
            <TableHeadCell>Отображаемое имя</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{model.getName()}</TableCell>
            <TableCell>
              <TextCommonEntity
                modelType={model.modelType}
                modelName={model.getName()}
                fieldName="displayName"
                inline
                fullWidth
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={s.cellNestedTable} colSpan={2}>
              <div className={s.wrapper}>
                <div className={s.header}>
                  <div className={s.buttons}>
                    <Button className={s.iconButton} icon="add" onClick={onAddFieldButtonClick} />
                  </div>
                </div>
                <div className={s.tables}>
                  <SmwbTable className={s.table}>
                    <caption className={s.caption}>Общие поля</caption>
                    <TableHead>
                      <TableRow>
                        <TableHeadCell>Поле</TableHeadCell>
                        <TableHeadCell>Значение</TableHeadCell>
                        <TableHeadCell />
                      </TableRow>
                    </TableHead>
                    {!isEmpty(commonFields) && <TableBody>{commonFields}</TableBody>}
                  </SmwbTable>
                  <SmwbTable className={s.table}>
                    <caption className={s.caption}>Поля</caption>
                    <TableHead>
                      <TableRow>{fieldsHeaderColumns}</TableRow>
                    </TableHead>
                    {!isEmpty(fieldNames) && <TableBody>{fields}</TableBody>}
                  </SmwbTable>
                  {model.modelType === Models.RelationMetaModel && (
                    <SmwbTable className={s.table}>
                      <caption className={s.caption}>Поля связей</caption>
                      <TableHead>
                        <TableRow>{fieldsHeaderColumns}</TableRow>
                      </TableHead>
                      {!isEmpty(fieldNames) && <TableBody>{relations}</TableBody>}
                    </SmwbTable>
                  )}
                </div>
              </div>
            </TableCell>
          </TableRow>
        </TableBody>
      </SmwbTable>
    </div>
  );
}
