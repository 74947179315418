import React, { useState } from 'react';
import { isWindowsWithDrawio } from '../guards/isWindowsWithDrawio';
import { DRAWIO } from '../types/drawioGlobal';

export function useDrawioLoad() {
  const [iframeRef, setIframeRef] = useState<HTMLIFrameElement | null>(null);

  const [drawio, setDrawio] = useState<DRAWIO | undefined>();

  const onDrawioIframeLoad: React.ReactEventHandler<HTMLIFrameElement> = () => {
    const contentWindow = iframeRef?.contentWindow;
    if (!contentWindow) {
      console.error('drawio iframe is empty');
      return;
    }
    if (!isWindowsWithDrawio(contentWindow)) {
      console.error('iframe does not contain drawio');
      return;
    }
    contentWindow.Draw.loadPlugin((ui) => {
      setDrawio({ globals: contentWindow, ui });
    });
  };

  return { setIframeRef, iframeRef, drawio, onDrawioIframeLoad };
}
