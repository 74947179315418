import { EditorUi } from '../../types/drawio/draw';
import { drawioToSystemFieldMapping, SystemDrawioAttributes } from '../../const';
import { BaseElementData } from '../../types/baseElementData';
import { COMMON_PROPERTIES } from '@baseModel/basisModel/const';
import { Models } from '@baseModel/engine/types';

export function getDiagramModelObjects(ui: EditorUi): BaseElementData[] {
  const graph = ui.editor.graph;
  const cells = graph.model.cells;
  const elements: BaseElementData[] = [];
  for (const key in cells) {
    const cell = cells[key];
    const attributes: Record<string, string> = {};
    if (typeof cell.value !== 'string' && cell.getAttribute(COMMON_PROPERTIES.id)) {
      if (cell.value?.nodeType === 1) {
        const attrs = cell.value.attributes;
        for (let i = 0; i < attrs.length; i++) {
          const attr = attrs[i];
          attributes[drawioToSystemFieldMapping[attr.name] ?? attr.name] = attr.value;
        }
        const modelType = attributes[SystemDrawioAttributes.modelType] as Models;
        const element: BaseElementData = {
          modelType,
          attributes
        };
        elements.push(element);
      }
    }
  }
  return elements;
}
