import { closeConfigBlock } from '@components/markdownEditor/redux/markdownEditor';
import { Sheet } from '@smwb/summer-ui';
import React from 'react';
import { useTypedSelector } from '../../../redux/types';
import { useDispatch } from 'react-redux';
import { CustomWidgetsHandler } from '@components/markdownEditor/dataDisplayWidgets/index';
import { useIsMobile } from '@hooks/useIsMobile';

export function ConfigLayout() {
  const isMobile = useIsMobile();
  const dispatch = useDispatch();

  const configBlock = useTypedSelector((state) => state.app.markdownEditor.actions.configBlock);
  if (!configBlock.isOpen) {
    return null;
  }
  const Widget = CustomWidgetsHandler.registeredWidgets.get(configBlock.type);

  if (!Widget) {
    console.error('widget class not found', configBlock.type);
    return null;
  }

  const widget = new Widget(configBlock.id, configBlock.sortIndex);
  const ConfigViewWrapper = widget.getConfigViewWrapper();

  return (
    <Sheet
      isOpen={configBlock.isOpen}
      placement={isMobile ? 'bottom' : 'right'}
      onClose={() => dispatch(closeConfigBlock())}
      size={configBlock.size}
      variant="modal"
    >
      {ConfigViewWrapper}
    </Sheet>
  );
}
