import React from 'react';
import { Config as ConfigFinalForm } from 'final-form';
import { Form } from 'react-final-form';
import { ConfigViewProps } from '@components/markdownEditor/dataDisplayWidgets/baseWidget/baseWidget';
import { ModelLinkValue } from '../../modelLink';
import { getModelLinkValue, parseModelLinkValue } from '../../utils';
import { ConfigForm, FormFields } from '../../components/configForm/configForm';
import s from './config.module.less';

export function Config({ initData, onSave, isNew }: ConfigViewProps<ModelLinkValue>) {
  const onSubmit: ConfigFinalForm<FormFields>['onSubmit'] = async (values) => {
    try {
      await onSave({ value: getModelLinkValue(values) });
    } catch (error) {
      console.error(`Не удалось сохранить данные:`, error);
    }
  };

  const initialValues = parseModelLinkValue(initData.value);

  return (
    <div className={s.root}>
      <div className={s.header}>{`${isNew ? 'Создать' : 'Изменить'} Поле модели`}</div>
      <Form<FormFields>
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit }) => <ConfigForm onSubmit={handleSubmit} />}
      ></Form>
    </div>
  );
}
