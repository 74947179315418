import { useEffect } from 'react';
import { loadPreview } from '../utils/drawio/loadPreview';
import { DrawioGlobals } from '../types/drawio/drawioGlobals';
import { EditorUi } from '../types/drawio/draw';

export function useInitPreview(globals?: DrawioGlobals, editorUi?: EditorUi, base64 = '') {
  useEffect(() => {
    if (editorUi && globals) {
      loadPreview(globals.Graph, globals.mxUtils, globals.LocalFile, editorUi, base64);
    }
  }, [editorUi, base64, globals]);
}
