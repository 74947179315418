import { EditorUi, Graph } from '../../types/drawio/draw';
import { MxUtils } from '../../types/drawio/mx';
import { LocalFile } from '../../types/drawio/localFile';

export function loadPreview(
  graph: Graph,
  mxUtils: MxUtils,
  LocalFile: LocalFile,
  EditorUi: EditorUi,
  previewData: string
) {
  EditorUi.fileLoaded(new LocalFile(EditorUi, EditorUi.emptyDiagramXml));
  if (previewData) {
    const xml = graph.decompress(previewData);
    if (xml) {
      const xmlDoc = mxUtils.parseXml(xml);
      EditorUi.editor.setGraphXml(xmlDoc.documentElement);
    }
  }
}
