import invert from 'lodash/invert';

export const DRAW_IO_DEFAULT_SOURCE_PATH = process.env.PUBLIC_URL + '/modules/drawio/index.html';

import { COMMON_PROPERTIES } from '@baseModel/basisModel/const';

export enum SystemDrawioAttributes {
  label = 'label',
  modelType = 'drawio__modelType'
}

export const systemDrawioAttributesForRemove = Object.values(SystemDrawioAttributes);

export const systemToDrawioFieldMapping = {
  [COMMON_PROPERTIES.commonName]: SystemDrawioAttributes.label
};

export enum GraphElementType {
  vertex = 'vertex',
  edge = 'edge'
}

export const drawioToSystemFieldMapping = invert(systemToDrawioFieldMapping);

export const edgeCellDefaultStyle =
  'endArrow=classic;edgeStyle=orthogonalEdgeStyle;html=1;jettySize=auto;orthogonalLoop=1;';
export const vertexCellDefaultStyle =
  'whiteSpace=wrap;html=1;align=center;collapsible=0;container=1;recursiveResize=0;';

export const errorStyleColor = '#ff0000';
