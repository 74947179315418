import { Item, TreeNodeItem } from './treeNodeItem';
import { getMetaItem, prepareTreeNodes } from './utils';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import style from './treeNodeItem.less?raw';
import { useCallback, useEffect, useState } from 'react';
import { DRAWIO } from '../../../types/drawioGlobal';
import { Engine } from '@baseModel/engine/engine';
import { SidebarElement } from '../index';
import { convertMetaModelToSidebarElement } from '../utils/convertMetaModelToSidebarElement';
import { Models } from '@baseModel/engine/types';
import { convertModelToSidebarElement } from '../utils/convertModelToSidebarElement';
import { CellsAddListener, CellsDeletedListener } from '../../../types/events';
import { COMMON_PROPERTIES } from '@baseModel/basisModel/const';
import { useSubscribeAddCells } from '../../../hooks/useSubscribeAddCells';
import { MxCell } from '../../../types/drawio/mx/mxCell';
import { useSubscribeDeleteCells } from '../../../hooks/useSubscribeDeleteCells';
import { getDiagramModelObjects } from '../../utils/getDiagramObjects';
import groupBy from 'lodash/groupBy';
import { Theme, useTheme } from '@smwb/summer-ui';

export interface TreeNode {
  node: Element;
  cell: MxCell;
  label: string;
  group?: string;
  uuid: string;
}

export interface MetaViewProps {
  drawio: DRAWIO;
  engine: Engine;
}

export const MetaView = ({ drawio, engine }: MetaViewProps) => {
  const { setTheme } = useTheme();
  useEffect(() => {
    setTheme(Theme.dark);
  }, [setTheme]);
  const [entityMetaModelsNodes, setEntityMetaModelsNodes] = useState<TreeNode[] | undefined>([]);
  const [relationsMetaModelsNodes, setRelationsMetaModelsNodes] = useState<TreeNode[] | undefined>([]);
  const [entityModelsNodes, setEntityModelsNodes] = useState<TreeNode[]>([]);
  const [relationsModelsNodes, setRelationsModelsNodes] = useState<TreeNode[]>([]);
  useEffect(() => {
    if (drawio?.ui) {
      const entityMetaModelsData: SidebarElement[] = [];
      for (const [modelName, model] of engine.getEntityMetModelsIterator()) {
        entityMetaModelsData.push({
          ...convertMetaModelToSidebarElement(modelName, Models.EntityMetaModel),
          style: model.getCommonValue('style')
        });
      }
      setEntityMetaModelsNodes(prepareTreeNodes(drawio, entityMetaModelsData));

      const relationMetaModelsData: SidebarElement[] = [];
      for (const [modelName, model] of engine.getRelationMetaModelsIterator()) {
        relationMetaModelsData.push({
          ...convertMetaModelToSidebarElement(modelName, Models.RelationMetaModel),
          style: model.getCommonValue('style')
        });
      }
      setRelationsMetaModelsNodes(prepareTreeNodes(drawio, relationMetaModelsData));
    }
  }, [drawio, engine]);

  useEffect(() => {
    const diagramModelObjectsByType = groupBy(getDiagramModelObjects(drawio.ui), 'modelType');
    const entityIdsInDiagram = (diagramModelObjectsByType[Models.Entity] ?? []).map(
      (el) => el.attributes[COMMON_PROPERTIES.id]
    );
    const entityModelIds = engine.getEntitiesIds();
    const entityModelsData: SidebarElement[] = entityModelIds
      .filter((id) => !entityIdsInDiagram.includes(id))
      .map((modelId) => {
        return convertModelToSidebarElement(engine, modelId, Models.Entity);
      });
    const relationIdsInDiagram = (diagramModelObjectsByType[Models.Relation] ?? []).map(
      (el) => el.attributes[COMMON_PROPERTIES.id]
    );
    const relationModelsIds = engine.getRelationsIds();
    const relationModelsData: SidebarElement[] = relationModelsIds
      .filter((id) => !relationIdsInDiagram.includes(id))
      .map((modelId) => {
        return convertModelToSidebarElement(engine, modelId, Models.Relation);
      });
    setEntityModelsNodes(prepareTreeNodes(drawio, entityModelsData) ?? []);
    setRelationsModelsNodes(prepareTreeNodes(drawio, relationModelsData) ?? []);
  }, [drawio, engine]);

  const drawioAddCellsEventListener = useCallback<CellsAddListener>(({ changes }) => {
    changes.forEach((change) => {
      const id = change.newAttributes[COMMON_PROPERTIES.id];
      if (
        !id ||
        change.engineModelType === Models.RelationMetaModel ||
        change.engineModelType === Models.EntityMetaModel
      ) {
        return;
      }
      if (change.engineModelType === Models.Entity) {
        setEntityModelsNodes((prevState) => {
          return prevState.filter(
            (el) => el.cell.getAttribute(COMMON_PROPERTIES.id) !== change.newAttributes[COMMON_PROPERTIES.id]
          );
        });
      } else if (change.engineModelType === Models.Relation) {
        setRelationsModelsNodes((prevState) => {
          return prevState.filter(
            (el) => el.cell.getAttribute(COMMON_PROPERTIES.id) !== change.newAttributes[COMMON_PROPERTIES.id]
          );
        });
      }
    });
  }, []);

  const drawioCellsDeletedEventListener = useCallback<CellsDeletedListener>(
    ({ changes }) => {
      changes.forEach((change) => {
        if (
          !change.engineModelId ||
          change.engineModelType === Models.EntityMetaModel ||
          change.engineModelType === Models.RelationMetaModel
        ) {
          return;
        }
        const sidebarElement = convertModelToSidebarElement(engine, change.engineModelId, change.engineModelType);
        const node = prepareTreeNodes(drawio, [sidebarElement])?.[0];
        if (!node) {
          return;
        }
        if (change.engineModelType === Models.Entity) {
          setEntityModelsNodes((prevState) => {
            prevState.push(node);
            return [...prevState];
          });
        } else if (change.engineModelType === Models.Relation) {
          setRelationsModelsNodes((prevState) => {
            prevState.push(node);
            return [...prevState];
          });
        }
      });
    },
    [drawio, engine]
  );

  useSubscribeAddCells(drawio, drawioAddCellsEventListener);
  useSubscribeDeleteCells(drawio, drawioCellsDeletedEventListener);

  if (!entityMetaModelsNodes || !relationsMetaModelsNodes) {
    return null;
  }

  const metaNodes: Item | undefined =
    !entityMetaModelsNodes.length && !relationsMetaModelsNodes.length
      ? undefined
      : {
          label: 'Мета модель',
          key: 'Мета модель',
          openByDefault: true,
          children: [
            {
              label: 'Сущности',
              key: 'Сущности',
              children: getMetaItem(entityMetaModelsNodes)
            },
            {
              label: 'Связи',
              key: 'Связи',
              children: getMetaItem(relationsMetaModelsNodes)
            }
          ]
        };

  const modelNodes: Item | undefined =
    !entityModelsNodes.length && !relationsModelsNodes.length
      ? undefined
      : {
          label: 'Модель',
          key: 'Модель',
          openByDefault: true,
          children: [
            {
              label: 'Сущности',
              key: 'Сущности',
              children: getMetaItem(entityModelsNodes)
            },
            {
              label: 'Связи',
              key: 'Связи',
              children: getMetaItem(relationsModelsNodes)
            }
          ]
        };
  const treeData: Item[] = [];
  if (metaNodes) {
    treeData.push(metaNodes);
  }
  if (modelNodes) {
    treeData.push(modelNodes);
  }
  return (
    <>
      <style>{style}</style>
      <div data-testid="tree-view" className={'smwb-tree'}>
        {treeData.map((d) => (
          <div className="smwb-tree__wrapper" key={d.label}>
            <TreeNodeItem item={d} />
          </div>
        ))}
      </div>
    </>
  );
};
