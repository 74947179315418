import { Engine } from '@baseModel/engine/engine';
import { COMMON_PROPERTIES } from '@baseModel/basisModel/const';

/**
 * TODO попробовать сделать SQL-like
 * @param engine
 * @param metaModelName
 */
export function getItems(engine: Engine, metaModelName?: string) {
  const entityIds = engine.getEntitiesIdsByMetaName(metaModelName);
  return entityIds.map((entityId) => {
    const entity = engine.getEntityById(entityId);
    const fieldName = entity.getFieldValue(COMMON_PROPERTIES.commonName);
    const description = entity.getFieldValue('description');
    const text = `${fieldName} (id: ${entityId})`;

    return {
      key: entityId,
      text,
      value: entityId,
      helperText: description as string
    };
  });
}
