import React from 'react';
import { Button, Icon } from '@smwb/summer-ui';
import s from './columnActionsCell.module.less';

interface Props {
  onDelete: React.MouseEventHandler;
  onEdit: React.MouseEventHandler;
}

export function ColumnActionsCell({ onDelete, onEdit }: Props) {
  return (
    <div className={s.actionsCell}>
      <Button variant="text" className={s.iconButton} onClick={onEdit} type="button">
        <Icon size={18} name="edit" />
      </Button>
      <Button variant="text" className={s.iconButton} onClick={onDelete} type="button">
        <Icon size={18} name="delete" />
      </Button>
    </div>
  );
}
