import { Graph } from '../../types/drawio/draw';
import { MxCodec, MxUtils } from '../../types/drawio/mx';

export function getBase64(graph: Graph, mxCodec: MxCodec, mxUtils: MxUtils): string {
  const model = graph.getModel();
  const codec = new mxCodec();
  codec.lookup = (id: string) => model.getCell(id);
  const nodeModel = codec.encode(model);
  const nodeModelXml = mxUtils.getXml(nodeModel);
  return graph.compress(nodeModelXml);
}
