import { createSidebarElementVertex } from '../utils/createMxElements/createSidebarElementVertex';
import { SidebarElement } from '../index';
import { DRAWIO } from '../../../types/drawioGlobal';
import { createSidebarElementEdge } from '../utils/createMxElements/createSidebarElementEdge';
import { TreeNode } from './metaView';
import { Item } from './treeNodeItem';
import { groupBy, map } from 'lodash';
import { Models } from '@baseModel/engine/types';
import { SystemDrawioAttributes } from '../../../const';

export function prepareTreeNodes(drawio: DRAWIO, elementsEntity: SidebarElement[]): TreeNode[] | undefined {
  const sidebar = drawio?.ui.sidebar;
  if (!sidebar || !sidebar?.wrapper) {
    console.error('sidebar.wrapper is undefined');
    return;
  }
  return elementsEntity.map((el) => {
    const uuid = drawio.globals.Editor.guid();
    const modelType = el.attributes[SystemDrawioAttributes.modelType] as Models;
    if (modelType === Models.Entity || modelType === Models.EntityMetaModel) {
      const vertex = createSidebarElementVertex(
        sidebar,
        drawio.ui.editor.graph,
        drawio.globals.mxCell,
        drawio.globals.mxGeometry,
        el.attributes,
        uuid,
        el.style
      );
      return { ...vertex, label: el.label, group: el.group, uuid };
    }
    if (modelType === Models.Relation || modelType === Models.RelationMetaModel) {
      const edge = createSidebarElementEdge(
        sidebar,
        drawio.ui.editor.graph,
        drawio.globals.mxCell,
        drawio.globals.mxPoint,
        drawio.globals.mxGeometry,
        el.attributes,
        uuid,
        el.style
      );
      return { ...edge, label: el.label, group: el.group, uuid };
    }
    console.error('unknown element data type', el);
    throw new Error('unknown element data type');
  });
}

export function getMetaItem(treeNodes: TreeNode[]): Item[] {
  // Если группа не указана, считаем, что это матамодель и группировать не надо
  if (!treeNodes[0]?.group) {
    return treeNodes.map((el) => {
      return {
        key: el.uuid,
        label: el.label,
        htmlElement: el.node
      };
    });
  }
  const groups = groupBy<TreeNode>(treeNodes, 'group');
  return map(groups, (group, groupName) => {
    return {
      label: groupName,
      key: groupName,
      children: group.map((el) => {
        return {
          key: el.uuid,
          label: el.label,
          htmlElement: el.node
        };
      })
    };
  });
}
