import { Entity } from '../model/entity';
import { Relation } from '../model/relation';
import { EntityMetaModel } from '../metaModel/entityMetaModel';
import { RelationMetaModel } from '../metaModel/relationMetaModel';
import { ModelUpdateData } from '../utils/dataJuggler';
import { Document } from '../document/document';
import { Observable } from 'rxjs';
import { Sender } from '@utils/observable';

export type ModelTypes = Entity | Relation | EntityMetaModel | RelationMetaModel | 'unknown';

export enum ModelEventTypes {
  all = 'all',
  none = 'none',
  add = 'add',
  remove = 'remove',
  addOrRemove = 'addOrRemove',
  update = 'update'
}

export enum ModelAbstracts {
  entity = 'entity',
  meta = 'meta'
}

export enum Models {
  Entity = 'Entity',
  Relation = 'Relation',
  EntityMetaModel = 'EntityMetaModel',
  RelationMetaModel = 'RelationMetaModel'
}

export type InstanceModels = Models.Entity | Models.Relation;
export type MetaModels = EntityMetaModel | RelationMetaModel;
export const ModelAbstractsMapping = {
  [ModelAbstracts.entity]: [Models.Entity, Models.Relation],
  [ModelAbstracts.meta]: [Models.EntityMetaModel, Models.RelationMetaModel]
};

export interface EngineEventAddOrRemoveData {
  name?: string;
  id?: string;
}

export interface EngineEventUpdateData extends EngineEventAddOrRemoveData {
  data: ModelUpdateData;
}

export type EngineEventData = EngineEventUpdateData | EngineEventAddOrRemoveData;

export interface EngineEvent<T> {
  modelType: Models;
  event: ModelEventTypes;
  data: T;
}

export type UnitTypes = {
  entityMetaModels: EntityMetaModel;
  relationMetaModels: RelationMetaModel;
  entities: Entity;
  relations: Relation;
  document: Document;
};

export type JSONSerializeEngine<K extends keyof UnitTypes> = {
  [T in K]: T extends 'document' ? ReturnType<UnitTypes[T]['toJSON']> : ReturnType<UnitTypes[T]['toJSON']>[];
};

export interface ChangeEngineModel {
  model: Models;
  modelKey: string;
  value: EngineEvent<EngineEventData>;
}

export type ModelTypeMap = {
  [Models.Entity]: Entity;
  [Models.Relation]: Relation;
  [Models.EntityMetaModel]: EntityMetaModel;
  [Models.RelationMetaModel]: RelationMetaModel;
};

export type ModelArrayType<K extends keyof ModelTypeMap> = K extends K ? ModelTypeMap[K][] : never;
export type ObservableMapUnit<T> = Map<string, Observable<T>>;
export type ObservableWithSender<K extends keyof ModelTypeMap> = {
  sender: Sender | undefined;
  value: ModelArrayType<K>;
};
export type ObservableMap = {
  [K in keyof ModelTypeMap]: ObservableMapUnit<ObservableWithSender<K>>;
};
