import React from 'react';
import { object, ObjectSchema, string } from 'yup';
import {
  BaseWidget,
  ConfigViewProps,
  EditorViewProps
} from '@components/markdownEditor/dataDisplayWidgets/baseWidget/baseWidget';
import { EditorView } from './editorView/editorView';
import { ConfigView } from './configView/configView';
import { SheetProps } from '@smwb/summer-ui';
import { Models } from '@baseModel/engine/types';
import { DrawioContains } from './configView/types';

export interface DrawioWidgetValue {
  base64: string;
  contains: DrawioContains;
}

export const diagramContainsSchema = object()
  .shape({
    [Models.Entity]: object(),
    [Models.Relation]: object()
  })
  .required();

const valueSchema: ObjectSchema<DrawioWidgetValue> = object().shape({
  base64: string().defined(),
  contains: diagramContainsSchema
});

export class DrawioWidget extends BaseWidget<DrawioWidgetValue> {
  public override valueSchema = valueSchema;

  public override getName(): string {
    return 'drawio';
  }

  public override getMenuItem(): string {
    return 'Диаграмма draw.io';
  }

  public override getEditorView(): React.FC<EditorViewProps<DrawioWidgetValue>> | null {
    return EditorView;
  }

  public override getConfigView(): React.FC<ConfigViewProps<DrawioWidgetValue>> | null {
    return ConfigView;
  }

  public override getConfigViewSize(): SheetProps['size'] {
    return '80%';
  }

  protected override async getInitConfig(): Promise<DrawioWidgetValue> {
    return new Promise((resolve) =>
      resolve({
        base64: '',
        contains: {
          [Models.Entity]: {},
          [Models.Relation]: {}
        }
      })
    );
  }
}

CustomWidgets.register(DrawioWidget);
