import React from 'react';
import { Button, Sheet } from '@smwb/summer-ui';
import { SelectField, TextField, Toggle } from '@smwb/summer-ui/dist/connects/rff';
import { Form, FormProps } from 'react-final-form';
import { DataStoreName } from '@baseModel/utils/dataJuggler';
import { Models } from '@baseModel/engine/types';
import { Engine } from '@baseModel/engine/engine';
import { useAppDispatch } from '../../../redux/store';
import { reset } from '../redux/actions';
import { ActionType } from '../types';
import { fieldNameValidate, fieldTypeValidate, fieldValidate } from '../utils/validations';
import { fieldColumns, FieldType } from '../utils/fieldsColumns';

import s from './addFieldShellForm.module.less';
import { JsonField, JsonSource } from '@baseModel/types/jsonDescription';
import { RelationMetaModel } from '@baseModel/metaModel/relationMetaModel';
import { useTypedSelector } from '../../../redux/types';
import { useIsMobile } from '@hooks/useIsMobile';

const engine = Engine.getInstance();

function fieldTypeOptions(modelType?: Models.EntityMetaModel | Models.RelationMetaModel) {
  if (modelType === undefined) {
    return [];
  }

  const options = [
    { key: DataStoreName.common, value: DataStoreName.common, text: 'Общее поле' },
    { key: DataStoreName.fields, value: DataStoreName.fields, text: 'Поле' }
  ];

  if (modelType === Models.RelationMetaModel) {
    options.push({ key: DataStoreName.relations, value: DataStoreName.relations, text: 'Поле связи' });
  }

  return options;
}

interface DataValue {
  fieldType: DataStoreName | undefined;
  name: string;
  commonValue: string;
  fields: JsonField | JsonSource;
}

const initialValues: DataValue = {
  fieldType: undefined,
  name: '',
  commonValue: '',
  fields: fieldColumns.reduce((acc, value) => {
    if (value.type === FieldType.Text || value.type === FieldType.Select) {
      acc[value.key] = '';
    }

    if (value.type === FieldType.Toggle) {
      acc[value.key] = false;
    }

    return acc;
  }, {} as DataValue['fields'])
};

export function AddFieldShellForm() {
  const isMobile = useIsMobile();
  const { type: actionType, modelType, modelName } = useTypedSelector((state) => state.app.metaTableView.actions);
  const dispatch = useAppDispatch();
  const isOpen = actionType === ActionType.Add;

  const onClose = () => {
    dispatch(reset());
  };

  const onSubmit: FormProps<DataValue>['onSubmit'] = (values, form) => {
    if (modelType === undefined || modelName === undefined || values.fieldType === undefined) {
      return;
    }

    const model =
      modelType === Models.EntityMetaModel
        ? engine.getMetaEntityByName(modelName)
        : engine.getMetaRelationByName(modelName);

    switch (values.fieldType) {
      case DataStoreName.common:
        model.setCommonValue(values.name, values.commonValue);
        break;
      case DataStoreName.fields:
        model.setFieldValue(values.name, values.fields);
        break;
      case DataStoreName.relations:
        if (model instanceof RelationMetaModel) {
          model.setRelationValue(values.name, values.fields);
        }
        break;
    }

    form.reset();
    onClose();
  };

  return (
    <Sheet isOpen={isOpen} onClose={onClose} variant="modal" placement={isMobile ? 'bottom' : 'right'} size="450px">
      <div className={s.heading}>Создание поля</div>
      <Form<DataValue>
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting, form: { getFieldState } }) => {
          const fieldTypeState = getFieldState('fieldType');
          const fieldValue = fieldTypeState?.value;
          const fieldType = fieldTypeState?.value;

          return (
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            <form className={s.stack} onSubmit={handleSubmit}>
              <SelectField
                validate={fieldTypeValidate(modelType)}
                name="fieldType"
                label="Тип поля"
                items={fieldTypeOptions(modelType)}
              />
              {fieldValue && (
                <>
                  <TextField
                    variant="filled"
                    validate={fieldNameValidate(modelType, modelName, fieldType)}
                    name="name"
                    label="Название поля"
                    autoComplete="off"
                  />
                  {fieldType === DataStoreName.common && (
                    <TextField variant="filled" name="commonValue" label="Значение" autoComplete="off" />
                  )}
                  {(fieldType === DataStoreName.fields || fieldType === DataStoreName.relations) &&
                    fieldColumns.map((column) => {
                      const name = `fields[${column.key}]`;
                      const label = column.title;
                      let node: React.ReactNode = null;

                      switch (column.type) {
                        case FieldType.Text:
                          node = (
                            <TextField
                              variant="filled"
                              key={column.key}
                              name={name}
                              label={label}
                              validate={fieldValidate(column.config)}
                            />
                          );

                          break;
                        case FieldType.Toggle:
                          node = <Toggle key={column.key} name={name} label={label} />;

                          break;
                        case FieldType.Select: {
                          if (fieldType === DataStoreName.fields || fieldType === DataStoreName.relations) {
                            const options = column.options[fieldType]();

                            node = (
                              <SelectField
                                key={column.key}
                                validate={fieldValidate(column.config)}
                                items={options.map(({ key, name }) => ({ key, value: key, text: name }))}
                                name={name}
                                label={label}
                              />
                            );
                          }

                          break;
                        }
                      }

                      return node;
                    })}
                  <Button variant="contained" type="submit" disabled={submitting}>
                    Сохранить
                  </Button>
                </>
              )}
            </form>
          );
        }}
      />
    </Sheet>
  );
}
