import { DRAWIO } from '../types/drawioGlobal';

import { CellsDeletedListener, EventNames, HandledModelsDelete } from '../types/events';
import { useEffect } from 'react';
import { MxEvent } from '../types/drawio/mx';
import { EventListener } from '../types/drawio/draw';
import { MxEventObject, MxEventObjectProperty } from '../types/drawio/mx/mxEvent';
import { getAttributes } from '../utils/drawio/getAttributes';
import { COMMON_PROPERTIES } from '@baseModel/basisModel/const';
import { getBase64 } from '../utils/drawio/getBase64';
import { SystemDrawioAttributes } from '../const';
import { Models } from '@baseModel/engine/types';

export function useSubscribeDeleteCells(drawio: DRAWIO | undefined, listener: CellsDeletedListener) {
  useEffect(() => {
    if (!drawio) {
      return;
    }
    const {
      ui: {
        editor: { graph }
      },
      globals: { mxCodec, mxUtils }
    } = drawio;
    const drawioEventListener: EventListener<MxEventObject> = function (_, evt) {
      const changes = evt.getProperty(MxEventObjectProperty.cells);

      const result: HandledModelsDelete[] = [];

      (Array.isArray(changes) ? changes : [changes]).forEach((cell) => {
        const attributes = getAttributes(cell);
        const modelId = attributes[COMMON_PROPERTIES.id];
        if (!modelId) {
          return;
        }
        const engineModelType = attributes[SystemDrawioAttributes.modelType] as Models;
        result.push({ engineModelType, event: EventNames.delete, engineModelId: modelId });
      });
      const base64 = getBase64(graph, mxCodec, mxUtils);
      listener({ changes: result, base64 });
    };
    graph.addListener(MxEvent.CELLS_REMOVED, drawioEventListener);
    return () => {
      graph.removeListener(MxEvent.CELLS_REMOVED, drawioEventListener);
    };
  }, [drawio, listener]);
}
