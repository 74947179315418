import { Button } from '@smwb/summer-ui';
import { SelectField, TextField, Toggle } from '@smwb/summer-ui/dist/connects/rff';
import { RelationMetaModel } from '@baseModel/metaModel/relationMetaModel';
import { ActionType, FormData, FormFields } from '@baseModel/components/modelForm/types';
import { InstanceModels, MetaModels, Models } from '@baseModel/engine/types';
import { useModel } from '@baseModel/hooks/useModel';
import { Form, FormProps } from 'react-final-form';
import { Entity } from '@baseModel/model/entity';
import { Relation } from '@baseModel/model/relation';
import { Engine } from '@baseModel/engine/engine';
import { ReactElement, useMemo } from 'react';
import { SimpleValueType } from '@baseModel/types/simpleValueType';
import uniqueId from 'lodash/uniqueId';
import { validationField } from '@baseModel/components/modelForm/utils/validateionField';
import { getItems } from '@baseModel/components/modelForm/utils/getItems';
import { COMMON_PROPERTIES } from '@baseModel/basisModel/const';
import { getHeading } from '@baseModel/components/modelForm/utils/getHeading';
import { getModel } from '@baseModel/components/modelForm/utils/getModel';

export interface ModelFormPropsCommon {
  actionType: ActionType;
  modelType: InstanceModels;
  metaModelName: string;
  modelId?: string;
  nested?: {
    // relation
    name: string;
    // endTargetName
    master?: string;
    // endSelfName
    slave: string;
  };
}

export interface ModelFormPropsAdd extends ModelFormPropsCommon {
  actionType: ActionType.Add;
}

export interface ModelFormPropsUpdate extends ModelFormPropsCommon {
  actionType: ActionType.Update;
  modelId: string;
}

export type ModelFormProps = (ModelFormPropsUpdate | ModelFormPropsAdd) & {
  engine: Engine;
  onClose: () => void;
};

export function ModelForm({
  actionType,
  modelId,
  modelType,
  metaModelName,
  engine,
  nested: relationProp,
  onClose
}: ModelFormProps) {
  const id = actionType === ActionType.Add ? undefined : modelId;
  const actionModel = useModel(id, modelType) as Entity | Relation | undefined;
  const metaModel = useModel(
    metaModelName,
    modelType === Models.Entity ? Models.EntityMetaModel : Models.RelationMetaModel
  ) as MetaModels | undefined;

  const onSave: FormProps<FormData>['onSubmit'] = (data) => {
    const model = getModel(actionType, metaModel, actionModel);
    if (!model) {
      return;
    }
    try {
      const fields = data[FormFields.Fields];
      for (const key in fields) {
        model.setFieldValue(key, fields[key]);
      }

      if (model instanceof Relation) {
        const relations = data[FormFields.Relations];
        for (const key in relations) {
          model.setRelationValue(key, relations[key]);
        }
      }

      if (actionType === ActionType.Add) {
        model.setFieldValue(COMMON_PROPERTIES.type, model.getMetaModel().getName());

        if (model instanceof Entity) {
          engine.addEntity(model);

          if (relationProp !== undefined && modelId !== undefined && relationProp.master) {
            const metaRelationModel = engine.getMetaRelationByName(relationProp.name);
            const relation = new Relation(metaRelationModel);
            const id = uniqueId('md-' + Date.now().toString());
            const idName = relation.getIdFieldName();

            if (!idName) {
              console.error(`id name is unknown in metamodel`);
              return;
            }
            relation.setFieldValue(idName, id);
            relation.setRelationValue(relationProp.master, model.getId() as string);
            relation.setRelationValue(relationProp.slave, modelId);

            engine.addRelation(relation);
          }
        } else {
          engine.addRelation(model);
        }
      }

      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  const fields = useMemo(() => {
    const fields: ReactElement[] = [];

    if (metaModel === undefined) {
      return fields;
    }

    const fieldNames = metaModel.getFieldNames() || [];

    for (const fieldName of fieldNames) {
      const metaFieldValue = metaModel.getFieldValue(fieldName);

      if (metaFieldValue === undefined) {
        continue;
      }

      const isId = fieldName === metaModel.getIdFieldName();
      const isAddId = actionType === ActionType.Add && isId;
      const isUpdateId = actionType === ActionType.Update && isId;

      let defaultValue: SimpleValueType = '';

      if (isAddId) {
        defaultValue = uniqueId('md-' + Date.now().toString());
      } else if (ActionType.Update && actionModel !== undefined) {
        const updateValue = actionModel.getFieldValue(fieldName);
        if (updateValue !== undefined) {
          defaultValue = updateValue;
        }
      }

      const name = [FormFields.Fields, fieldName].join('.');

      if (metaFieldValue.type === 'boolean') {
        fields.push(
          <Toggle
            key={fieldName}
            initialValue={defaultValue === true || defaultValue === 'true' ? 1 : 0}
            readOnly={isUpdateId}
            validate={validationField(metaFieldValue)}
            name={name}
            autoComplete="off"
            label={metaFieldValue['display-name']}
            disabled={isAddId}
          />
        );
      } else {
        fields.push(
          <TextField
            variant="filled"
            key={fieldName}
            initialValue={defaultValue as string}
            readOnly={isUpdateId}
            validate={validationField(metaFieldValue)}
            name={name}
            autoComplete="off"
            label={metaFieldValue['display-name']}
            disabled={isAddId}
          />
        );
      }
    }

    if (metaModel instanceof RelationMetaModel) {
      const relationNames = metaModel.getRelationNames();

      for (const relationName of relationNames) {
        const metaRelationValue = metaModel.getRelationValue(relationName);

        if (metaRelationValue === undefined) {
          continue;
        }

        let defaultValue = '';

        if (ActionType.Update && actionModel !== undefined) {
          const updateValue = actionModel?.getRelationValue(relationName);

          if (updateValue !== undefined) {
            defaultValue = String(updateValue);
          }
        } else if (relationProp && !relationProp.master && modelId) {
          defaultValue = modelId;
        }
        const name = [FormFields.Relations, relationName].join('.');

        fields.push(
          <SelectField
            disabled={relationProp && !relationProp.master && relationName === relationProp.slave}
            key={relationName}
            initialValue={defaultValue}
            name={name}
            validate={validationField(metaRelationValue)}
            label={metaRelationValue['display-name']}
            items={getItems(engine, metaRelationValue.type)}
            menuMaxHeight={220}
          />
        );
      }
    }

    return fields;
  }, [metaModel, actionType, actionModel, relationProp, modelId, engine]);

  return (
    <>
      <div>{getHeading(actionType, modelType)}</div>
      <Form
        onSubmit={onSave}
        render={({ handleSubmit, submitting }) => (
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          <form onSubmit={handleSubmit}>
            {fields}
            <Button variant="contained" type="submit" disabled={submitting}>
              Сохранить
            </Button>
          </form>
        )}
      />
    </>
  );
}
