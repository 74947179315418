import { MxCell } from '../../types/drawio/mx/mxCell';
import { isMxCell } from '../../guards/isMxCell';
import { isNode } from '../../guards/isNode';
import { drawioToSystemFieldMapping } from '../../const';

export function getAttributes(node: Node | MxCell): Record<string, string> {
  const attrs: Record<string, string> = {};

  if (isMxCell(node)) {
    return !node.value || typeof node.value === 'string' ? attrs : getAttributes(node.value);
  } else if (isNode(node)) {
    const element = node as Element;
    for (let i = 0; i < element.attributes?.length; i++) {
      const attr = element.attributes[i];
      const mapAttributeName = drawioToSystemFieldMapping[attr.name] ?? attr.name;
      attrs[mapAttributeName] = attr.value;
    }
  } else {
    console.error('getAttributes', node, 'node is string', new Error().stack);
  }
  return attrs;
}
