import { BaseWidget } from '@components/markdownEditor/dataDisplayWidgets/baseWidget/baseWidget';
import { openConfigBlock } from '@components/markdownEditor/redux/markdownEditor';
import { store } from '../../../redux/store';
import { ConfigLayout } from '@components/markdownEditor/dataDisplayWidgets/configLayout';
import { SheetProps } from '@smwb/summer-ui';
import { Engine } from '@baseModel/engine/engine';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type BaseWidgetConstructor = new (blockId?: string, sortIndex?: number) => BaseWidget<any>;

export interface FindBlockIndex {
  (): [string, number | undefined];
}

export interface MenuInfo {
  type: string;
  menuItem: string;
  size?: SheetProps['size'];
  hasConfig: boolean;
  askInitConfig: boolean;
  onClick: (findBlockIndex: FindBlockIndex) => void;
}

export class CustomWidgetsHandler {
  public static registeredWidgets: Map<string, BaseWidgetConstructor> = new Map();
  public static registeredMenu: Map<string, MenuInfo> = new Map();

  private static onBlockMenuItemClick(widgetType: string, findBlockIndex: FindBlockIndex) {
    const menuInfo = CustomWidgetsHandler.registeredMenu.get(widgetType);
    const WidgetClass = CustomWidgetsHandler.registeredWidgets.get(widgetType);
    if (!menuInfo) {
      console.error('widget menu info not found', widgetType);
      return;
    }
    if (!WidgetClass) {
      console.error('widget class not found', widgetType);
      return;
    }

    const [id, sortIndex] = findBlockIndex();
    if ((menuInfo.hasConfig && !menuInfo.askInitConfig) || !menuInfo.hasConfig) {
      const widget = new WidgetClass(id, sortIndex);
      void widget.save();
    }
    store.dispatch(openConfigBlock({ id, sortIndex, type: widgetType, size: menuInfo.size }));
  }

  public static getBlockIdsByWidgetName(name: string): string[] {
    const engine = Engine.getInstance();
    const systemName = BaseWidget.generateType(name);
    return engine
      .getDocument()
      .getBlocks(systemName)
      .map((el) => el.getId());
  }

  public static register(WidgetClass: BaseWidgetConstructor) {
    const instance = new WidgetClass();
    const type = instance.getType();
    const size = instance.getConfigViewSize();
    const menuItem = instance.getMenuItem();
    const hasConfig = !!instance.getConfigView();
    const askInitConfig = instance.askInitConfig();
    CustomWidgetsHandler.registeredWidgets.set(type, WidgetClass);
    if (menuItem) {
      const onClick = CustomWidgetsHandler.onBlockMenuItemClick.bind(undefined, type);
      CustomWidgetsHandler.registeredMenu.set(type, { type, menuItem, hasConfig, askInitConfig, onClick, size });
    }
  }

  public static getConfigComponent() {
    return ConfigLayout;
  }
}
