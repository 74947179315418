import { AvailableBlock, BlockType } from './blocks/types';
import { Markdown } from './blocks/markdown';
import { Loggable } from '../loggable';
import { Listener, Observable } from '@utils/observable';
import { EmptyObject } from '../utils/dataJuggler';
import { ModelError } from '../errors';
import { Engine } from '../engine/engine';
import { AnyObject } from 'react-final-form';
import { JSONSerializeBlock } from '@baseModel/document/blocks/block';

export enum EventType {
  all = 'all',
  none = 'none',
  add = 'add',
  remove = 'remove',
  addOrRemove = 'addOrRemove',
  update = 'update',
  sortIndex = 'sortIndex'
}

export interface DocumentEvent<T> {
  type: BlockType | string;
  id: string;
  event: EventType;
  position: number;
  data: T;
}

export type BlockTypes = {
  [BlockType.markdown]: Markdown;
};

export const blockMapping = {
  [BlockType.markdown]: Markdown
};

export type JSONSerializeDocument<K extends keyof typeof blockMapping> = ReturnType<BlockTypes[K]['toJSON']>[];

export type DocumentEventData = undefined | number | string | AnyObject;

export const DOCUMENT_SENDER = 'document';

// TODO убрать
// Костыль для простой реализации возможности добавления новых компонентов между кастомными блоками
export const MARKDOWN_NEW_BLOCK_ID = 'newMarkdownBlock';

export class Document extends Loggable {
  private readonly blocks: Map<string, AvailableBlock> = new Map();

  private readonly listeners: Map<EventType, Observable<DocumentEvent<DocumentEventData>>> = new Map();

  constructor(private readonly engine: Engine) {
    super(DOCUMENT_SENDER);
  }

  public getBlocksCount(): number {
    return this.blocks.size;
  }

  public setBlockSortIndex(blockId: string, newIndex: number, senderId: string | EmptyObject) {
    const targetBlock = this.blocks.get(blockId);
    if (!targetBlock) {
      throw new ModelError(`block ${targetBlock} not found`);
    }
    if (newIndex >= this.blocks.size) {
      newIndex = this.blocks.size - 1;
    }
    if (newIndex < 0) {
      newIndex = 0;
    }
    // Предыдущий индекс сортировки.
    let prevTargetSortIndex: number | undefined;
    try {
      prevTargetSortIndex = targetBlock.getSortIndex();
    } catch (e) {
      // empty
    }

    // Сразу меняем на новый порядок.
    targetBlock.setSortIndex(newIndex, senderId);

    this.blocks.forEach((block) => {
      // Незачем повторно менять уже обновлённый индекс.
      if (blockId === block.getId()) {
        return;
      }

      const currentSortIndex = block.getSortIndex();

      // Если это новый блок, подвинем всех
      if (prevTargetSortIndex === undefined) {
        if (newIndex <= currentSortIndex) {
          block.setSortIndex(currentSortIndex + 1, senderId);
        }
        // Уменьшаем индексы там, где они перестроились.
      } else if (newIndex > prevTargetSortIndex) {
        // Не меняем индексы там, где это не требуется, они уже идут в правильном порядке.
        if (currentSortIndex > newIndex || currentSortIndex < prevTargetSortIndex) {
          return;
        }

        block.setSortIndex(currentSortIndex - 1, senderId);
      }
      // Прибавляем индексы там, где они перестроились.
      else {
        // Не меняем индексы там, где это не требуется, они уже идут в правильном порядке.
        if (currentSortIndex < newIndex || currentSortIndex > prevTargetSortIndex) {
          return;
        }

        block.setSortIndex(currentSortIndex + 1, senderId);
      }
    });
  }

  public addBlock(block: AvailableBlock, customSortIndex?: number, senderId?: string | EmptyObject) {
    let blockSortIndex: number | undefined;
    try {
      blockSortIndex = block.getSortIndex();
    } catch (e) {
      // empty
    }
    const sortIndex = customSortIndex ?? blockSortIndex ?? this.blocks.size;
    const id = block.getId();
    if (this.blocks.has(id)) {
      throw new ModelError(`Block с id ${id} существует`);
    }
    block.linkToEngine(this.engine);
    this.blocks.set(id, block);
    this.emit(block.type, id, EventType.add, block.getValue(), sortIndex, senderId);
    this.logger(`block with type ${block.type} with id ${id} added`);
    this.setBlockSortIndex(id, sortIndex, senderId ?? {});

    if (block instanceof Markdown) {
      block.subscribe(
        this.blockObserve(BlockType.markdown, id, () => block.getSortIndex()),
        DOCUMENT_SENDER
      );
    } else {
      block.subscribe(
        this.blockObserve(block.type, id, () => block.getSortIndex()),
        DOCUMENT_SENDER
      );
    }

    block.sortIndexSubscribe(
      this.sortIndexObserve(block.type, id, () => block.getSortIndex()),
      DOCUMENT_SENDER
    );
  }

  private emit(
    blockType: BlockType | string,
    id: string,
    eventType: EventType,
    data: AnyObject | undefined | string | number,
    sortIndex: number,
    senderId?: string | EmptyObject | undefined
  ): void {
    const event: DocumentEvent<DocumentEventData> = {
      type: blockType,
      id,
      event: eventType,
      data,
      position: sortIndex
    };
    const mapEvents = this.listeners.get(eventType);
    if (mapEvents) {
      mapEvents.setValue(event, senderId);
    }
    if (eventType === EventType.add || eventType === EventType.remove) {
      const observableAddOrRemove = this.listeners.get(EventType.addOrRemove);
      if (observableAddOrRemove) {
        observableAddOrRemove.setValue(event, senderId);
      }
    }
    const observableAll = this.listeners.get(EventType.all);
    if (observableAll) {
      observableAll.setValue(event, senderId);
    }
    this.logger(`block ${blockType} with id ${id} with sortIndex ${sortIndex} ${eventType}, event data %O`, data);
  }

  private blockObserve(
    blockType: string,
    id: string,
    getSortIndex: () => number
  ): (updateData: DocumentEventData, senderId?: string | EmptyObject) => void {
    return (updateData, senderId) => {
      this.emit(blockType, id, EventType.update, updateData, getSortIndex(), senderId);
    };
  }

  private sortIndexObserve(
    blockType: BlockType | string,
    id: string,
    getSortIndex: () => number
  ): (newSortIndex: number | undefined, senderId?: string | EmptyObject) => void {
    return (newSortIndex, senderId) => {
      this.emit(blockType, id, EventType.sortIndex, newSortIndex, getSortIndex(), senderId);
    };
  }

  public getBlocks(type: BlockType.markdown): Markdown[];
  public getBlocks(): AvailableBlock[];
  public getBlocks(type: string): AvailableBlock[];
  public getBlocks(type?: string): Markdown[] | AvailableBlock[] | [] {
    const blocks = Array.from(this.blocks.values());
    if (type) {
      return blocks.filter((block) => block.type === type);
    }
    return blocks;
  }

  public removeBlockById(type: BlockType | string, id: string, senderId: string | EmptyObject): void {
    const blockForDelete = this.blocks.get(id);
    if (!blockForDelete) {
      console.warn(`Блок с ИД ${id} Не найден для удаления`);
      return;
    }
    const sortIndex = blockForDelete.getSortIndex();
    blockForDelete.deleteMe();
    this.blocks.delete(id);
    this.emit(type, id, EventType.remove, undefined, sortIndex, senderId);
    this.blocks.forEach((v) => {
      if (v.getSortIndex() > sortIndex) {
        v.setSortIndex(v.getSortIndex() - 1, senderId);
      }
    });
  }

  public getBlockById(id: string, type: BlockType.markdown): Markdown | undefined;
  public getBlockById(id: string, type: BlockType | string): AvailableBlock | undefined;
  public getBlockById(id: string): AvailableBlock | undefined;
  public getBlockById(id: string, type?: BlockType | string): AvailableBlock | undefined {
    if (!type) {
      return this.getBlocks().find((el) => el.getId() === id);
    }
    if (type === BlockType.markdown) {
      return this.getBlocks(type).find((el) => el.getId() === id);
    }
  }

  /**
   * Получить конфигурацию mardown по id
   * @param id
   */
  public getMarkdownById(id: string): Markdown | undefined {
    const markdown = this.getBlocks(BlockType.markdown);
    return markdown.find((el) => el.getId() === id);
  }

  public getMarkdown(): string {
    return Array.from(this.blocks.values())
      .sort((a, b) => (a.getSortIndex() > b.getSortIndex() ? 1 : -1))
      .reduce((res, el) => res + el.getMarkdown(), '');
  }

  public subscribe(
    event: EventType,
    listener: Listener<DocumentEvent<DocumentEventData>>,
    sender: string | EmptyObject
  ) {
    let observable = this.listeners.get(event);
    if (!observable) {
      observable = new Observable<DocumentEvent<DocumentEventData>>(DOCUMENT_SENDER);
      this.listeners.set(event, observable);
    }
    return observable.subscribe(listener, sender);
  }

  public setBlockValue(
    type: BlockType.markdown,
    id: string,
    value: string | undefined,
    sender: string | EmptyObject
  ): void;
  public setBlockValue(type: BlockType, id: string, value: DocumentEventData, sender: string | EmptyObject): void {
    if (type === BlockType.markdown) {
      const block = this.getBlockById(id, BlockType.markdown);
      if (!block) {
        throw new ModelError(`Block ${id} not found`);
      }
      block.setValue(value as string, sender);
      return;
    }
  }

  public toJSON(): (JSONSerializeBlock<AnyObject> | JSONSerializeBlock<string>)[] {
    return Array.from(this.blocks.values())
      .sort((a, b) => (a.getSortIndex() > b.getSortIndex() ? 1 : -1))
      .map((el) => el.toJSON());
  }

  public removeAll() {
    this.blocks.forEach((block) => {
      this.removeBlockById(block.type, block.getId(), DOCUMENT_SENDER);
    });
    this.blocks.clear();
  }
}
