import React, { useCallback, useEffect } from 'react';
import { DRAW_IO_DEFAULT_SOURCE_PATH } from '../const';
import { DrawioWidgetValue } from '../drawioWidget';
import { EditorViewProps } from '@components/markdownEditor/dataDisplayWidgets/baseWidget/baseWidget';
import { useInitPreviewFrame } from './hooks/useInitPreviewFrame';
import { useDrawioLoad } from '../hooks/useDrawioLoad';
import { useInitPreview } from '../hooks/useInitPreview';
import { EngineEvent, EngineEventUpdateData, ModelAbstracts, ModelEventTypes } from '@baseModel/engine/types';
import { BaseElementData } from '../types/baseElementData';
import { useSubscribeChangeModel } from '../hooks/useSubscribeChangeModel';
import debounce from 'lodash/debounce';
import { changeGraphElement } from '../utils/drawio/changeGraphElement';
import { ChangesWithBase64Listener } from '../types/events';
import { COMMON_PROPERTIES } from '@baseModel/basisModel/const';
import { previewResize } from './tools/previewResize';

function convertUpdateEvent({ modelType, data }: EngineEvent<EngineEventUpdateData>): BaseElementData | undefined {
  if (!data.id) {
    console.error('Model does not have ID', data);
    return;
  }

  return {
    modelType,
    attributes: {
      [COMMON_PROPERTIES.id]: data.id,
      [data.data.fieldName]: data.data.value?.toString() ?? ''
    }
  };
}

export function EditorView({ configData, engine, onSave }: EditorViewProps<DrawioWidgetValue>) {
  const wrapperRef = React.useRef<HTMLDivElement>(null);

  const { setIframeRef, iframeRef, drawio, onDrawioIframeLoad } = useDrawioLoad();
  useInitPreviewFrame(wrapperRef, iframeRef, drawio?.globals);
  useInitPreview(drawio?.globals, drawio?.ui, configData.base64);
  useEffect(() => {
    if (drawio) {
      engine.subscribe(ModelAbstracts.entity, ModelEventTypes.update, (data) => {
        const updates = convertUpdateEvent(data);
        if (!updates) {
          return;
        }
        changeGraphElement(drawio.ui.editor.graph, drawio.globals.mxUtils, updates);
      });
    }
  }, [engine, drawio]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const saveDebounced = useCallback(debounce(onSave, 50), [onSave]);
  const drawioEventsListener = useCallback<ChangesWithBase64Listener>(
    ({ base64 }) => {
      // Если base64 изменился, сохраним
      void saveDebounced({ ...configData, base64 });
    },
    [configData, saveDebounced]
  );

  useEffect(() => {
    if (iframeRef) {
      setTimeout(() => {
        previewResize(iframeRef);
      }, 500);
    }
  }, [configData.base64, iframeRef]);

  useSubscribeChangeModel(drawio, drawioEventsListener, true);

  return (
    <div ref={wrapperRef}>
      <iframe
        id="drawioFrame"
        src={`${
          !process.env.REACT_APP_DRAW_IO_PATH ? DRAW_IO_DEFAULT_SOURCE_PATH : process.env.REACT_APP_DRAW_IO_PATH
        }?lightbox=1`}
        onLoad={onDrawioIframeLoad}
        ref={setIframeRef}
        style={{ borderStyle: 'none', width: '100%', minHeight: '100px' }}
      />
    </div>
  );
}
