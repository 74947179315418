import ReactDOM from 'react-dom/client';
import { MetaView } from './components/metaView';
import { DRAWIO } from '../../types/drawioGlobal';
import { Engine } from '@baseModel/engine/engine';

const rootContainerId = 'DRAWIO-REACT-SIDEBAR';

function createContainer(sidebarWrapper: HTMLDivElement): { div: HTMLElement; itsNew: boolean } {
  const container: HTMLDivElement | null = sidebarWrapper.querySelector(`#${rootContainerId}`);
  const div: HTMLDivElement = container ?? document.createElement('div');
  div.id = rootContainerId;
  div.className = 'geSidebar';
  div.style.backgroundColor = 'white';
  div.style.borderTop = '1px solid';
  div.style.borderBottom = '1px solid';
  sidebarWrapper.insertBefore(div, sidebarWrapper.firstChild);
  return { div, itsNew: !!container };
}

export interface SidebarElement {
  label: string;
  style?: string;
  attributes: Record<string, string>;
  group?: string;
}

export function createSidebar(drawio: DRAWIO, engine: Engine) {
  const sidebar = drawio?.ui.sidebar;
  if (!sidebar || !sidebar?.wrapper) {
    console.error('sidebar.wrapper is undefined');
    return;
  }
  const { div, itsNew } = createContainer(sidebar.wrapper);

  if (!itsNew) {
    const root = ReactDOM.createRoot(div);
    root.render(<MetaView drawio={drawio} engine={engine} />);
    return;
  }
}
