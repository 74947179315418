import omit from 'lodash/omit';
import camelCase from 'lodash/camelCase';
import {
  JsonDescription,
  JsonDocumentBlocks,
  JsonDocumentBlockType,
  JsonEntity,
  JsonRelation
} from '../types/jsonDescription';
import { EntityMetaModel } from '../metaModel/entityMetaModel';
import { RelationMetaModel } from '../metaModel/relationMetaModel';
import { Entity } from '../model/entity';
import { Relation } from '../model/relation';
import { Engine } from './engine';
import { Loggable } from '../loggable';
import { AvailableBlock } from '../document/blocks/types';
import { Markdown } from '../document/blocks/markdown';
import { JsonDocumentBlock } from '@components/markdownEditor/dataDisplayWidgets/baseWidget/block/block';
import { CustomWidgetsHandler } from '@components/markdownEditor/dataDisplayWidgets';

const ENGINE_LOADER = 'EngineLoader';

export class EngineLoader extends Loggable {
  constructor() {
    super('EngineLoader');
  }

  private static onModelLoadedListeners: (() => void)[] = [];

  public static onModelLoaded(listener: () => void) {
    EngineLoader.onModelLoadedListeners.push(listener);
    return () =>
      (EngineLoader.onModelLoadedListeners = EngineLoader.onModelLoadedListeners.filter((el) => el !== listener));
  }

  protected readonly engine: Engine = Engine.getInstance();

  private schemaLoadEntitiesMetaModels(entities: JsonDescription['metamodel']['entities']): EntityMetaModel[] {
    const entityMetaModels: EntityMetaModel[] = [];

    for (const metaModelName of Object.keys(entities)) {
      const metaModelDescription = entities[metaModelName];
      const metaModel = new EntityMetaModel(metaModelName);
      const common = omit(metaModelDescription, 'fields') as Record<string, string>;
      const displayName = metaModelDescription['display-name'];

      if (typeof displayName === 'string') {
        metaModel.setDisplayName(displayName);
      }

      for (const commonKey of Object.keys(common || {})) {
        metaModel.setCommonValue(camelCase(commonKey), common[commonKey]);
      }

      for (const fieldKey of Object.keys(metaModelDescription.fields || {})) {
        metaModel.setFieldValue(fieldKey, metaModelDescription.fields[fieldKey]);
      }

      entityMetaModels.push(metaModel);
    }

    return entityMetaModels;
  }

  private schemaLoadRelationsMetaModels(relations: JsonDescription['metamodel']['relations']): RelationMetaModel[] {
    const relationMetaModels: RelationMetaModel[] = [];
    for (const metaModelName of Object.keys(relations)) {
      const metaModelDescription = relations[metaModelName];
      const relationMetaModel = new RelationMetaModel(metaModelName);
      const common = omit(metaModelDescription, 'fields', 'ends') as Record<string, string>;
      const displayName = metaModelDescription['display-name'];

      if (typeof displayName === 'string') {
        relationMetaModel.setDisplayName(displayName);
      }

      for (const commonKey of Object.keys(common || {})) {
        relationMetaModel.setCommonValue(camelCase(commonKey), common[commonKey]);
      }

      for (const fieldKey of Object.keys(metaModelDescription.fields || {})) {
        relationMetaModel.setFieldValue(fieldKey, metaModelDescription.fields[fieldKey]);
      }

      for (const endName of Object.keys(metaModelDescription.ends || {})) {
        relationMetaModel.setRelationValue(endName, metaModelDescription.ends[endName]);
      }

      relationMetaModels.push(relationMetaModel);
    }

    return relationMetaModels;
  }

  private schemaLoadEntitiesModels(entities: JsonEntity[]): Entity[] {
    const entityModels: Entity[] = [];
    entities.forEach((el) => {
      const metaModel = this.engine.getMetaEntityByName(el.__type);
      const entity = new Entity(metaModel);
      for (const field of Object.keys(el || {})) {
        entity.setFieldValue(field, el[field]);
      }
      entityModels.push(entity);
    });
    return entityModels;
  }

  private schemaLoadRelationModels(relations: JsonRelation[]): Relation[] {
    const relationModels: Relation[] = [];
    relations.forEach((el) => {
      const metaRelation = this.engine.getMetaRelationByName(el.type);
      const relation = new Relation(metaRelation);
      for (const field of Object.keys(el.fields || {})) {
        relation.setFieldValue(field, el.fields[field]);
      }
      for (const end of Object.keys(el.ends || {})) {
        relation.setRelationValue(end, el.ends[end]);
      }
      relationModels.push(relation);
    });
    return relationModels;
  }

  private schemaLoadDocument(document: JsonDocumentBlocks[] | null | undefined): AvailableBlock[] {
    if (!document) {
      return [];
    }
    const blocks: AvailableBlock[] = [];
    document.forEach((block) => {
      if (block.type === JsonDocumentBlockType.markdown) {
        const markdown = Markdown.fromJSON(block);
        blocks.push(markdown);
      } else {
        const widgetJsonBlock = block as unknown as JsonDocumentBlock;

        const Widget = CustomWidgetsHandler.registeredWidgets.get(widgetJsonBlock.type);

        if (!Widget) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-member-access
          console.error(`Неизвестный тип ${(block as any).type}`);
          return;
        }

        const widget = new Widget();
        const widgetBlock = widget.getBlock();

        blocks.push(widgetBlock.fromJSON(widgetJsonBlock));
      }
    });

    return blocks;
  }

  public schemaLoad(json: JsonDescription) {
    const metaModel = json.metamodel;

    this.schemaLoadEntitiesMetaModels(metaModel.entities).forEach((el) => {
      try {
        this.engine.addEntityMetaModel(el, ENGINE_LOADER);
      } catch (e) {
        this.logger(e);
      }
    });
    this.schemaLoadRelationsMetaModels(metaModel.relations).forEach((el) => {
      try {
        this.engine.addRelationMetaModel(el, ENGINE_LOADER);
      } catch (e) {
        this.logger(e);
      }
    });
    const model = json.model;
    this.schemaLoadEntitiesModels(model.entities).forEach((el) => {
      try {
        this.engine.addEntity(el, ENGINE_LOADER);
      } catch (e) {
        this.logger(e);
      }
    });
    this.schemaLoadRelationModels(model.relations).forEach((el) => {
      try {
        this.engine.addRelation(el, ENGINE_LOADER);
      } catch (e) {
        this.logger(e);
      }
    });

    this.schemaLoadDocument(json.document).forEach((el) => {
      try {
        this.engine.getDocument().addBlock(el, undefined, ENGINE_LOADER);
      } catch (e) {
        this.logger(e);
      }
    });

    EngineLoader.onModelLoadedListeners.forEach((el) => {
      setTimeout(el);
    });
  }
}
