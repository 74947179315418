// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.cdnfonts.com/css/sf-pro-display);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*TODO: Перенести в кастомную тему*/

html {
  min-height: 100%;
}

body {
  min-height: 100%;
  /*TODO: Перенести в кастомную тему*/
  font-family: 'SF Pro Display', sans-serif !important;
}

#root {
  height: 100%;
  padding: 0;
}

.geDialog {
  width: 50% !important;
  height: 50% !important;
}
`, "",{"version":3,"sources":["webpack://./src/styles/globalStyles.css"],"names":[],"mappings":"AAAA,mCAAmC;;AAGnC;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,mCAAmC;EACnC,oDAAoD;AACtD;;AAEA;EACE,YAAY;EACZ,UAAU;AACZ;;AAEA;EACE,qBAAqB;EACrB,sBAAsB;AACxB","sourcesContent":["/*TODO: Перенести в кастомную тему*/\n@import url('https://fonts.cdnfonts.com/css/sf-pro-display');\n\nhtml {\n  min-height: 100%;\n}\n\nbody {\n  min-height: 100%;\n  /*TODO: Перенести в кастомную тему*/\n  font-family: 'SF Pro Display', sans-serif !important;\n}\n\n#root {\n  height: 100%;\n  padding: 0;\n}\n\n.geDialog {\n  width: 50% !important;\n  height: 50% !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
