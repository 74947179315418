import React, { useMemo } from 'react';
import { createPortal } from 'react-dom';
import { ExternalNodeAttributes } from './utils/markdownSchemaWithExtraNodes';
import { CustomWidgetsHandler } from '@components/markdownEditor/dataDisplayWidgets';

export interface ExternalNodesViewProps {
  externalNodes: NodeListOf<HTMLDivElement> | null;
}

export function ExternalNodesView({ externalNodes }: ExternalNodesViewProps) {
  const nodes = useMemo(() => {
    if (!externalNodes) {
      return null;
    }

    const nodes: ReturnType<typeof createPortal>[] = [];

    externalNodes.forEach((el) => {
      const type = el.getAttribute(ExternalNodeAttributes.externalNodeType);

      if (!type) {
        console.error('Внешний узел не имеет типа для элемента', el);
        return;
      }

      const id = el.getAttribute(ExternalNodeAttributes.externalNodeId);

      if (!id) {
        console.error(`Внешний узел типа ${type} не имеет идентификатора`);
        return null;
      }

      let node: React.ReactNode | null = null;
      switch (type) {
        default: {
          const Widget = CustomWidgetsHandler.registeredWidgets.get(type);

          if (Widget) {
            const widget = new Widget(id);
            const editorView = widget.getEditorView();

            if (editorView !== null) {
              node = widget.getEditorViewWrapper();
            }
          } else {
            console.error('Widget type', type, 'not found');
          }
        }
      }

      if (node === null) {
        return;
      }
      nodes.push(createPortal(node, el, id));
    });

    return nodes;
  }, [externalNodes]);

  return <>{nodes}</>;
}
