import { MxCell, MxCellClass } from '../../../../types/drawio/mx/mxCell';
import { Graph } from '../../../../types/drawio/draw';
import { MxGeometryClass, MxPointClass } from '../../../../types/drawio/mx';
import { forEach } from 'lodash';
import { edgeCellDefaultStyle } from '../../../../const';

export function createElementEdgeCell(
  graph: Graph,
  mxCell: MxCellClass,
  mxPoint: MxPointClass,
  mxGeometry: MxGeometryClass,
  attributes: Record<string, string>,
  style?: string
): MxCell {
  const edge = new mxCell('', new mxGeometry(0, 0, 0, 0), style ?? edgeCellDefaultStyle);
  if (edge.geometry) {
    edge.geometry.setTerminalPoint(new mxPoint(0, 0), true);
    edge.geometry.setTerminalPoint(new mxPoint(100, 0), false);
    edge.geometry.relative = true;
  }
  edge.edge = true;

  forEach(attributes, (attribute, fieldName) => {
    graph.setAttributeForCell(edge, fieldName, attribute);
  });

  return edge;
}
