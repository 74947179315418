export function shallowCompare(a: unknown, b: unknown): boolean {
  // Check for strict equality first
  if (a === b) return true;

  // If either value is null or undefined, they are not equal
  if (a == null || b == null) return false;

  // Compare types
  const typeA = typeof a;
  const typeB = typeof b;

  // If types are different, they are not equal
  if (typeA !== typeB) return false;

  // Handle strings
  if (typeA === 'string') {
    return a === b;
  }

  // Handle arrays
  if (Array.isArray(a) && Array.isArray(b)) {
    // Arrays must be the same length
    if (a.length !== b.length) return false;
    // Compare each element (shallow comparison)
    for (let i = 0; i < a.length; i++) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  }

  // Handle plain objects
  if (typeA === 'object') {
    // Get keys from both objects
    const keysA = Object.keys(a);
    const keysB = Object.keys(b);

    // Objects must have the same number of keys
    if (keysA.length !== keysB.length) return false;

    // Compare each key and value (shallow comparison)
    for (const key of keysA) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line no-prototype-builtins
      if (!b.hasOwnProperty(key) || a[key] !== b[key]) return false;
    }
    return true;
  }

  // Fallback to strict equality
  return false;
}
