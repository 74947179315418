import React, { useMemo } from 'react';
import { Models } from '@baseModel/engine/types';
import { Engine } from '@baseModel/engine/engine';
import { useModels } from '@baseModel/hooks/useModels';
import { EntityRelation, Table, TableProps } from '../table/table';
import s from './expandTable.module.less';
import { Relation } from '@baseModel/model/relation';
import { TableBlock, TableBlockColumn, TableBlockShowLink } from '../../../types';
import { getHierarchyLinkTitle } from '../../../utils/getHierarchyLinkTitle';
import { Sender } from '@utils/observable';

export interface ExpandTableProps {
  sender: Sender;
  parentModelId: string;
  inheritModelId?: string;
  showLink: TableBlockShowLink;
  engine: Engine;
  onSave: (columns: TableBlockColumn[]) => void;
  setEditModalData: TableProps['setEditModalData'];
}

export function ExpandTable({
  engine,
  sender,
  parentModelId,
  inheritModelId,
  showLink,
  onSave,
  setEditModalData
}: ExpandTableProps) {
  const relationIds = useModels(Models.Relation, showLink.relation);
  const metaRelation = engine.getMetaRelationByName(showLink.relation);
  const endSelfValue = metaRelation.getRelationValue(showLink.endSelf);
  const endTargetValue = showLink.endTarget ? metaRelation.getRelationValue(showLink.endTarget) : undefined;

  const configData = useMemo<TableBlock | undefined>(() => {
    if (!showLink.table.columns) {
      return;
    }
    return {
      modelType: showLink.endTarget ? Models.Entity : Models.Relation,
      rowType: endTargetValue?.type ? endTargetValue?.type : showLink.relation,
      columns: showLink.table.columns,
      showLink: showLink.showLink
    };
  }, [endTargetValue?.type, showLink.endTarget, showLink.relation, showLink.showLink, showLink.table.columns]);
  if (showLink.relation === undefined || showLink.endSelf === undefined) {
    return <div>showLink not found</div>;
  }
  if (endSelfValue === undefined) {
    return (
      <div>
        The `{endSelfValue}` field was not found in the relations of the `{showLink.relation}` metamodel.
      </div>
    );
  }

  const models: EntityRelation[] = [];
  const relationModels: Relation[] = [];
  for (const relationId of relationIds) {
    try {
      const relationModel = engine.getRelationById(relationId);
      const endSelfId = relationModel.getRelationValue(showLink.endSelf);
      if (endSelfId === undefined) {
        continue;
      }

      if (endSelfId === parentModelId) {
        if (showLink.endTarget && endTargetValue) {
          const endTargetId = relationModel.getRelationValue(showLink.endTarget);

          if (endTargetId === undefined) {
            continue;
          }

          const entity = engine.getEntityById(String(endTargetId));

          if (entity.getMetaModel().getName() === endTargetValue.type) {
            models.push(new EntityRelation(relationId, entity));
          }
        } else {
          relationModels.push(relationModel);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  if (!configData) {
    return null;
  }

  return (
    <Table
      sender={sender}
      className={s.table}
      setEditModalData={setEditModalData}
      configData={configData}
      models={endTargetValue ? models : relationModels}
      title={getHierarchyLinkTitle(engine, showLink)}
      modelId={parentModelId}
      nestedTable={{
        relation: showLink.relation,
        endSelfName: showLink.endSelf,
        endTargetName: showLink.endTarget
      }}
      hiddenModelId={inheritModelId}
      onSave={(newData) => {
        if (newData?.columns) {
          onSave(newData?.columns);
        }
        return Promise.resolve();
      }}
    />
  );
}
