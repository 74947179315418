import { combineReducers } from 'redux';
import { metaTableViewReducers } from '@components/metaTableView/redux';
import { yamlEditorReducers } from '@components/yamlEditor/redux';
import { markdownEditorReducers } from '@components/markdownEditor/redux';

export const appReducer = combineReducers({
  metaTableView: metaTableViewReducers,
  yamlEditor: yamlEditorReducers,
  markdownEditor: markdownEditorReducers
});
