import { ConfigViewProps } from '@components/markdownEditor/dataDisplayWidgets/baseWidget/baseWidget';
import { TableBlock, TableBlockColumn, TableBlockShowLink } from '../types';
import { Button, SelectField } from '@smwb/summer-ui';
import { getRowTypeItems, modelTypes } from '@utils/getRowTypeItems';
import React, { useMemo, useState } from 'react';
import { ColumnsPreview } from './components/columnsPreview/columnsPreview';
import s from '../../diagramGeneration/view/config/config.module.less';
import TableHierarchiesForm from './components/tableHierarchiesForm/tableHierarchiesForm';
import { InstanceModels } from '@baseModel/engine/types';
import { CustomWidgetsHandler } from '@components/markdownEditor/dataDisplayWidgets';

export function ConfigView({ initData, isNew, onSave, senderId }: ConfigViewProps<TableBlock>) {
  const diagramsIdsItems = useMemo(
    () =>
      CustomWidgetsHandler.getBlockIdsByWidgetName('drawio').map((el) => ({
        value: el,
        text: el,
        key: el
      })),
    []
  );
  console.log('diagramsIdsItems', diagramsIdsItems);
  const [selectedModelType, setSelectedModelType] = useState<InstanceModels | undefined>(initData.modelType);
  const [selectedRowType, setSelectedRowType] = useState<string | undefined>(initData.rowType);
  const [diagramFilter, setDiagramFilter] = useState<string | undefined>();
  const [columns, setColumns] = useState<TableBlockColumn[]>(initData.columns ?? []);

  const [hierarchy, setHierarchy] = useState<TableBlockShowLink | undefined>(initData.showLink);

  const availableRowTypes = useMemo(() => getRowTypeItems(selectedModelType), [selectedModelType]);
  return (
    <>
      <div className={s.header}>{`${isNew ? 'Создать' : 'Изменить'} Таблицу`}</div>
      <SelectField
        label={`model-type`}
        items={modelTypes}
        onChange={(e, data) => setSelectedModelType(data.value as InstanceModels)}
        value={selectedModelType}
        menuMaxHeight={240}
      />
      {selectedModelType && (
        <SelectField
          label={`row-type`}
          items={availableRowTypes}
          onChange={(e, data) => setSelectedRowType(data.value as string)}
          value={selectedRowType}
          menuMaxHeight={240}
        />
      )}
      <SelectField
        label={`Фильтр по диаграммам`}
        items={diagramsIdsItems}
        onChange={(_, v) => setDiagramFilter(v.value?.toString())}
        value={diagramFilter}
        menuMaxHeight={240}
      />
      {selectedModelType && selectedRowType && (
        <ColumnsPreview
          columns={columns}
          setColumns={setColumns}
          modelType={selectedModelType}
          rowType={selectedRowType}
        />
      )}
      {selectedRowType && selectedModelType && (
        <TableHierarchiesForm
          sender={senderId}
          modelType={selectedModelType}
          rowType={selectedRowType}
          hierarchy={hierarchy}
          setHierarchy={setHierarchy}
        />
      )}
      {selectedModelType && selectedRowType && (
        <Button
          type="button"
          variant={'contained'}
          disabled={!selectedModelType || !columns.length}
          onClick={() => {
            void onSave({
              modelType: selectedModelType,
              rowType: selectedRowType,
              columns: columns,
              filter: {
                diagram: diagramFilter
              },
              showLink: hierarchy
            });
          }}
        >
          Сохранить таблицу
        </Button>
      )}
    </>
  );
}
