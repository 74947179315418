import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import throttle from 'lodash/throttle';
import pick from 'lodash/pick';
import { EditorViewProps } from '@components/markdownEditor/dataDisplayWidgets/baseWidget/baseWidget';
import { DiagramGenerationValue } from '../../diagramGeneration';
import { ModelAbstracts, ModelEventTypes } from '@baseModel/engine/types';
import { toYAML } from '@baseModel/utils/toYAML';
import config from '../../../../config';

export function Editor({ configData, engine }: EditorViewProps<DiagramGenerationValue>) {
  const [svgData, setSvgData] = useState<string>();

  const fetchSvg = useCallback(() => {
    const documentJson = engine.toJSON();
    const document = toYAML(documentJson, (json) => pick(json, ['version', 'model']));

    axios
      .post<string>(`${config.diagramGenerator.url}/generate`, {
        document: encodeURIComponent(document),
        mapping: encodeURIComponent(configData.mapping),
        tool: 'd2'
      })
      .then((response) => {
        if (response.status === axios.HttpStatusCode.Ok) {
          setSvgData(response.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [configData.mapping, engine]);

  useEffect(() => {
    fetchSvg();
  }, [fetchSvg]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledFetchSvg = useCallback(throttle(fetchSvg, 5000, { leading: false }), [fetchSvg]);

  useEffect(() => {
    const unsubscribes = [
      engine.subscribe(ModelAbstracts.entity, ModelEventTypes.all, () => {
        throttledFetchSvg();
      })
    ];

    return () => {
      unsubscribes.map((unsubscribe) => unsubscribe());
      throttledFetchSvg.cancel();
    };
  }, [throttledFetchSvg, engine]);

  return svgData ? <div dangerouslySetInnerHTML={{ __html: svgData }} /> : null;
}
