import { Button, Menu, MenuItem, MenuItemText, MenuSubmenu, MenuItemIcon, Icon } from '@smwb/summer-ui';
import React, { useMemo, useState } from 'react';
import { getValueItems } from './utils/getValueItems';
import { Models } from '@baseModel/engine/types';
import { Engine } from '@baseModel/engine/engine';

interface Props {
  engine: Engine;
  rowType: string;
  modelType: Models;
  value?: string;
  onChange: (value: string) => void;
  label?: string;
}

export function EditColumnMenu({ value, onChange, engine, rowType, modelType }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);

  const availableValues = useMemo(() => getValueItems(engine, rowType, modelType), [engine, modelType, rowType]);

  const onClose = () => setIsOpen(false);
  const onClick = (v: string) => {
    onChange(v);
    onClose();
  };
  return (
    <>
      <Button ref={setAnchorElement} onClick={() => setIsOpen(true)} type="button">
        {value || 'Нажмите для установки'}
      </Button>
      <Menu isOpen={isOpen} onClose={onClose} anchorElement={anchorElement} dense>
        {availableValues.map((el) => {
          return typeof el === 'string' ? (
            <MenuItem onClick={() => onClick(el)} key={el}>
              <MenuItemText inset>{el}</MenuItemText>
            </MenuItem>
          ) : (
            <MenuSubmenu
              key={el.name}
              toggleElement={
                <MenuItem>
                  <MenuItemIcon>
                    <Icon name="link" />
                  </MenuItemIcon>
                  <MenuItemText>{el.name}</MenuItemText>
                  <MenuItemIcon>
                    <Icon name="chevron_right" />
                  </MenuItemIcon>
                </MenuItem>
              }
            >
              {el.items.map((r) => (
                <MenuItem onClick={() => onClick(r)} key={r}>
                  <MenuItemText>{r}</MenuItemText>
                </MenuItem>
              ))}
            </MenuSubmenu>
          );
        })}
      </Menu>
    </>
  );
}
