import {
  AcceptedMetaModel,
  AcceptedUnion,
  ConditionValue,
  LogicalCondition,
  LogicalOperator,
  Query
} from '@baseModel/engine/queryBuilder/types';
import { DataStoreName } from '@baseModel/utils/dataJuggler';
import {
  matchesConditionMapMetaModels,
  matchesConditionMapModels
} from '@baseModel/engine/queryBuilder/matchesConditionMap';
import { isLogicalOperator } from '@baseModel/engine/queryBuilder/guards';
import { JsonField, JsonSource } from '@baseModel/types/jsonDescription';
import { RelationMetaModel } from '@baseModel/metaModel/relationMetaModel';
import { EntityMetaModel } from '@baseModel/metaModel/entityMetaModel';

function isAcceptedMetaModel(dataJuggler: AcceptedUnion): dataJuggler is AcceptedMetaModel {
  return dataJuggler instanceof RelationMetaModel || dataJuggler instanceof EntityMetaModel;
}

export function matchesConditionDataJuggler<T extends AcceptedUnion>(
  dataJuggler: T,
  condition: Query<T>['where']
): boolean {
  for (const key in condition) {
    if (isLogicalOperator(key)) {
      const logicalConditions = condition[key] ?? [];
      if (key === LogicalOperator.$and) {
        if (!logicalConditions.every((cond) => matchesConditionDataJuggler(dataJuggler, cond))) {
          return false;
        }
      } else if (key === LogicalOperator.$or) {
        if (!logicalConditions.some((cond) => matchesConditionDataJuggler(dataJuggler, cond))) {
          return false;
        }
      } else if (key === LogicalOperator.$not) {
        if (matchesConditionDataJuggler(dataJuggler, logicalConditions[0])) {
          return false;
        }
      }
    } else {
      const storeName = key as DataStoreName;
      const storeCondition = condition[storeName];
      if (!storeCondition) {
        return false;
      }

      if (isAcceptedMetaModel(dataJuggler)) {
        const typedCondition = storeCondition as Record<string, ConditionValue<JsonSource | JsonField>>;
        if (storeName === DataStoreName.fields) {
          return matchesConditionMapMetaModels(dataJuggler.getFieldIterator(), typedCondition);
        }
        if (storeName === DataStoreName.relations) {
          return matchesConditionMapMetaModels(dataJuggler.getRelationIterator(), typedCondition);
        }
        return false;
      }
      const typedCondition = storeCondition as Record<string, LogicalCondition>;
      if (storeName === DataStoreName.fields) {
        return matchesConditionMapModels(dataJuggler.getFieldIterator(), typedCondition);
      }
      if (storeName === DataStoreName.relations) {
        return matchesConditionMapModels(dataJuggler.getRelationIterator(), typedCondition);
      }
      return false;
    }
  }
  return true;
}
