import { Models } from '@baseModel/engine/types';
import { Button, SelectField, SelectFieldProps, Toggle } from '@smwb/summer-ui';
import React, { useMemo, useState } from 'react';
import style from './tableHierarchiesForm.module.less';
import { Engine } from '@baseModel/engine/engine';
import { TableBlockShowLink } from '../../../types';
import { ColumnsPreview } from '../columnsPreview/columnsPreview';
import { getRelationEndsItems } from './utils/getRelationEndsItems';
import { getHierarchyLinkTitle } from '../../../utils/getHierarchyLinkTitle';
import { useEngineQuery } from '@baseModel/hooks/useEngineQuery';
import { ANY_OF_FIELDS } from '@baseModel/engine/queryBuilder/const';
import { Sender } from '@utils/observable';

interface TableHierarchiesFormProps {
  modelType: Models.Entity | Models.Relation;
  rowType: string;
  hierarchy?: TableBlockShowLink;
  setHierarchy: (h: TableBlockShowLink | undefined) => void;
  sender: Sender;
}

const engine = Engine.getInstance();

function TableHierarchiesForm({ modelType, setHierarchy, hierarchy, rowType, sender }: TableHierarchiesFormProps) {
  const [showEditingHierarchy, setShowEditingHierarchy] = useState(false);

  const [hierarchyType, setHierarchyType] = useState<Models.Entity | Models.Relation>(
    hierarchy?.relation && !hierarchy.endTarget ? Models.Relation : Models.Entity
  );
  const [localHierarchy, setLocalHierarchy] = useState<Partial<TableBlockShowLink>>(hierarchy ?? {});

  const isEntity = modelType === Models.Entity;

  const handleChangeEndSelf = (e: React.SyntheticEvent<Element, Event>, data: SelectFieldProps) => {
    setLocalHierarchy((prev) => ({
      ...prev,
      endSelf: (data.value ?? '').toString()
    }));
  };

  const handleChangeEndTarget = (e: React.SyntheticEvent<Element, Event>, data: SelectFieldProps) => {
    setLocalHierarchy((prev) => ({
      ...prev,
      endTarget: (data.value ?? '').toString()
    }));
  };

  const handleDeleteHierarchy = () => {
    setHierarchy(undefined);
    setShowEditingHierarchy(false);
  };

  const onShowEditingHierarchy = () => {
    if (showEditingHierarchy) return;
    setShowEditingHierarchy(true);
  };

  const handleSaveHierarchy = () => {
    if (!localHierarchy.endSelf) {
      console.warn(`!endSelf`);
      return;
    }
    if (localHierarchy.relation === undefined) {
      console.warn(`!relation`);
      return;
    }
    setHierarchy(localHierarchy as TableBlockShowLink);
    setShowEditingHierarchy(false);
  };

  const inheritRowType = useMemo<string | undefined>(() => {
    if (localHierarchy.relation && localHierarchy.endTarget) {
      try {
        const relationMetaModel = engine.getMetaRelationByName(localHierarchy.relation);
        return relationMetaModel.getRelationValue(localHierarchy.endTarget)?.type;
      } catch (e) {
        // empty
      }
    } else if (localHierarchy.relation) {
      return localHierarchy.relation;
    }
  }, [localHierarchy.endTarget, localHierarchy.relation]);

  const relationModelsItems = useEngineQuery(
    engine,
    Models.RelationMetaModel,
    {
      where: {
        relations: {
          [ANY_OF_FIELDS]: {
            type: {
              $eq: rowType
            }
          }
        }
      }
    },
    sender,
    !rowType
  );

  const columnEditDisabled = !localHierarchy.endSelf || (!localHierarchy.endTarget && rowType === Models.Entity);

  if (!showEditingHierarchy) {
    return (
      <>
        <div className={style.hierarchies}>
          {localHierarchy.relation ? (
            <div>
              <div className={style.buttonsGroup}>
                <Button icon="account_tree" type="button" onClick={handleDeleteHierarchy}>
                  Удалить иерархию
                </Button>
                <Button icon="account_tree" type="button" onClick={onShowEditingHierarchy}>
                  Редактировать иерархию
                </Button>
              </div>
              <div>
                <div>{`Тип: ${hierarchyType}`}</div>
                <div>{`Строки моделей: ${rowType}`}</div>
                <div>{getHierarchyLinkTitle(engine, localHierarchy.showLink)}</div>
                <div>{`Поля: ${localHierarchy.table?.columns.map((el) => el.value).join(', ')}`}</div>
              </div>
            </div>
          ) : (
            <Button
              icon="account_tree"
              type="button"
              disabled={!isEntity}
              onClick={() => setShowEditingHierarchy(true)}
            >
              Добавить иерархию
            </Button>
          )}
        </div>
        {hierarchy && inheritRowType && (
          <TableHierarchiesForm
            hierarchy={hierarchy.showLink}
            setHierarchy={(childHierarchy) => {
              setHierarchy({
                ...(localHierarchy as TableBlockShowLink),
                showLink: childHierarchy
              });
            }}
            modelType={hierarchyType}
            rowType={inheritRowType}
            sender={sender}
          />
        )}
      </>
    );
  }

  return (
    <>
      <div className={style.hierarchiesForm}>
        <Toggle
          label={hierarchyType}
          onChange={(e, data) => {
            setHierarchyType(data.checked ? Models.Relation : Models.Entity);
            setLocalHierarchy({});
          }}
          checked={hierarchyType === Models.Entity}
        />
        <SelectField
          label={`Связь`}
          items={
            relationModelsItems?.map((el) => ({
              key: el.getName(),
              value: el.getName(),
              text: el.getName()
            })) ?? []
          }
          onChange={(e: React.SyntheticEvent<Element, Event>, data: SelectFieldProps) => {
            setLocalHierarchy(() => ({
              relation: (data.value ?? '').toString()
            }));
          }}
          value={localHierarchy.relation}
        />
        {localHierarchy.relation && (
          <SelectField
            label={`Связь с родительской моделью`}
            items={getRelationEndsItems(engine, localHierarchy.relation, rowType)}
            onChange={handleChangeEndSelf}
            value={localHierarchy.endSelf}
          />
        )}
        {localHierarchy.relation && hierarchyType === Models.Entity && (
          <SelectField
            label={`Связь с дочерней моделью`}
            items={getRelationEndsItems(engine, localHierarchy.relation)}
            onChange={handleChangeEndTarget}
            value={localHierarchy.endTarget}
          />
        )}
      </div>
      {!columnEditDisabled && inheritRowType && (
        <ColumnsPreview
          columns={localHierarchy?.table?.columns ?? []}
          setColumns={(columns) => {
            setLocalHierarchy({
              ...localHierarchy,
              table: {
                ...localHierarchy?.table,
                columns
              }
            });
          }}
          modelType={hierarchyType}
          rowType={inheritRowType}
        />
      )}
      <div>
        <Button icon="account_tree" type="button" onClick={() => setShowEditingHierarchy(false)}>
          Отмена
        </Button>
        <Button icon="account_tree" type="button" onClick={handleSaveHierarchy} disabled={columnEditDisabled}>
          Применить
        </Button>
      </div>
    </>
  );
}

export default TableHierarchiesForm;
