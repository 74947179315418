import React, { useMemo } from 'react';
import { EditorViewProps } from '@components/markdownEditor/dataDisplayWidgets/baseWidget/baseWidget';
import { ModelLinkValue } from '../../modelLink';
import { useModel } from '@baseModel/hooks/useModel';
import { Models } from '@baseModel/engine/types';
import { highlightModelFieldByPath } from '@components/yamlEditor/plugins/hightLight/highlightModelFieldByPath';
import { highlightDocumentBlockById } from '@components/yamlEditor/plugins/hightLight/highlightDocumentBlockById';
import { TextFieldEntityBasic } from '@baseModel/components/inputs/textFieldEntity';
import { parseModelLinkValue } from '../../utils';
import s from './editor.module.less';

export function Editor({ configData }: EditorViewProps<ModelLinkValue>) {
  const modelLinkInfo = useMemo(() => {
    return parseModelLinkValue(configData?.value);
  }, [configData]);

  const model = useModel(modelLinkInfo?.modelId, modelLinkInfo?.modelType as Models);

  const getValueRef = () => {
    try {
      return model?.getValueRefByPath(modelLinkInfo?.field || '');
    } catch (err) {
      console.error(err);
    }
  };

  const valueRef = getValueRef();

  if (typeof valueRef === 'string' || typeof valueRef === 'number' || !valueRef) {
    return <span>{valueRef || ''}</span>;
  }

  const meta = 'meta' in valueRef ? valueRef.meta : null;

  const onTextFieldFocus = () => {
    if (!meta) {
      return;
    }

    const { dataStoreName, fieldName, modelType, modelName = '' } = meta;

    highlightModelFieldByPath(modelType, modelName, dataStoreName, fieldName);
    highlightDocumentBlockById();
  };

  return meta ? (
    <div
      className={s.root}
      onKeyDown={stopPropagation}
      onKeyUp={stopPropagation}
      onKeyPress={stopPropagation}
      onPaste={stopPropagation}
      onCopy={stopPropagation}
    >
      <TextFieldEntityBasic
        inline
        fieldName={meta.fieldName}
        modelName={meta.modelName || ''}
        dataStoreName={meta.dataStoreName}
        modelType={meta.modelType}
        fullWidth
        onFocus={onTextFieldFocus}
      />
    </div>
  ) : null;
}

function stopPropagation(event: React.SyntheticEvent) {
  event.stopPropagation();
}
