import { Engine } from '@baseModel/engine/engine';
import { SelectFieldProps } from '@smwb/summer-ui';

/**
 * Получаем список доступных связей для селекта
 * @param engine
 * @param relation выбранное имя мета связи
 * @param targetName возвращает только связи с таким типом, если указано
 */
export const getRelationEndsItems = (
  engine: Engine,
  relation: string,
  targetName?: string
): SelectFieldProps['items'] => {
  const metaRelation = engine.getMetaRelationByName(relation);
  const relationNames = metaRelation.getRelationNames();
  const permittedRelations = !targetName
    ? relationNames
    : relationNames.filter((relationName) => metaRelation.getRelationValue(relationName)?.type === targetName);
  return permittedRelations.map((end) => {
    return {
      key: end,
      value: end,
      text: end
    };
  });
};
