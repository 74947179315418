import React, { useRef, useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { Button, Icon, Menu } from '@smwb/summer-ui';
import { getBlockIdInCursorPosition } from '@components/markdownEditor/utils/getEndBlockCursorPosition';
import { Engine } from '@baseModel/engine/engine';
import { ProseMirrorView } from '@components/markdownEditor/proseMirrorView';
import style from './topMenu.module.less';
import { MenuItem } from '@components/markdownEditor/topMenu/menuItem';
import { Schema } from 'prosemirror-model';
import { MarkButton } from '@components/markdownEditor/topMenu/markButton';
import { MENU_ICON_SIZE } from '@components/markdownEditor/topMenu/const';
import { HeaderBlockTypeMenuItems } from '@components/markdownEditor/topMenu/blockTypeMenuItem';
import { wrapInList } from 'prosemirror-schema-list';
import { wrapIn } from 'prosemirror-commands';
import { useBooleanState } from '@hooks/useBooleanState';
import CreateTableForm from '../createTableForm/createTableForm';
import { Markdown } from '@baseModel/document/blocks/markdown';
import uniqueId from 'lodash/uniqueId';
import { CustomWidgetsHandler } from '@components/markdownEditor/dataDisplayWidgets';

export interface TopMenuProps {
  menuNodes: NodeListOf<HTMLDivElement> | null;
  proseMirrorView: ProseMirrorView | null;
  schema: Schema;
}

const engine = Engine.getInstance();

function MenuBar({ proseMirrorView, schema }: Pick<TopMenuProps, 'proseMirrorView' | 'schema'>) {
  const anchorInsertMenuRef = useRef<HTMLButtonElement>(null);
  const anchorHeadMenuRef = useRef<HTMLButtonElement>(null);
  const anchorCreateTableMenuRef = useRef<HTMLButtonElement>(null);

  const [insertMenuOpen, setInsertMenuOpen] = useState(false);
  const [headMenuOpen, setHeadMenuOpen] = useState(false);
  const [, setUp] = useState(false);
  const {
    isTrue: isCreateTableMenuOpen,
    setFalse: onCloseCreateTableMenu,
    toggleValue: toggleCreateTablemenu
  } = useBooleanState();

  useEffect(() => {
    return proseMirrorView?.topMenuOnUpdateSubscribe(() => setUp((prev) => !prev));
  }, [proseMirrorView]);

  const findBlockIndex = (): [string, number | undefined] => {
    if (!proseMirrorView) {
      return ['', undefined];
    }
    const document = engine.getDocument();
    const blockInfo = getBlockIdInCursorPosition(proseMirrorView);
    const block = document.getBlockById(blockInfo.id);
    if (!block) {
      console.error(`Block ${blockInfo.type} ${blockInfo.id} не найден`);
    }
    return [uniqueId('md-' + Date.now().toString()), block ? block.getSortIndex() + 1 : undefined];
  };

  const handleInsertMdBlock = () => {
    if (!proseMirrorView) return;
    const [maxId, sortIndex] = findBlockIndex();
    const document = engine.getDocument();
    const markdown = new Markdown(maxId);
    markdown.setValue('новый блок ...');
    document.addBlock(markdown, sortIndex);
  };

  const insertMenuItems = (
    <>
      <MenuItem onClick={handleInsertMdBlock}>Текстовый блок</MenuItem>
      {Array.from(CustomWidgetsHandler.registeredMenu).map(([type, { menuItem, onClick }]) => (
        <MenuItem
          key={type}
          onClick={() => {
            onClick(findBlockIndex);
            setInsertMenuOpen(false);
          }}
        >
          {menuItem}
        </MenuItem>
      ))}
    </>
  );

  if (!proseMirrorView) {
    return null;
  }

  return (
    <>
      <div className={style.container}>
        <MarkButton proseMirrorView={proseMirrorView} icon="format_bold" markType={schema.marks.strong} />
        <MarkButton proseMirrorView={proseMirrorView} icon="format_italic" markType={schema.marks.em} />
        <MarkButton proseMirrorView={proseMirrorView} icon="code" markType={schema.marks.code} />
        <Button
          className={style.button}
          variant={'outlined'}
          label={'Вставить'}
          ref={anchorInsertMenuRef}
          onClick={() => setInsertMenuOpen(() => !insertMenuOpen)}
        />
        <Menu
          anchorElement={anchorInsertMenuRef.current}
          isOpen={insertMenuOpen}
          onClose={() => setInsertMenuOpen(false)}
        >
          {insertMenuItems}
        </Menu>
        <Button
          className={style.button}
          variant={'outlined'}
          ref={anchorHeadMenuRef}
          icon={<Icon name="format_h1" size={MENU_ICON_SIZE} />}
          onClick={() => setHeadMenuOpen(() => !headMenuOpen)}
        />
        <Menu anchorElement={anchorHeadMenuRef.current} isOpen={headMenuOpen}>
          <HeaderBlockTypeMenuItems
            proseMirrorView={proseMirrorView}
            schema={schema}
            onClose={() => setHeadMenuOpen(false)}
          />
        </Menu>
        <Button
          className={style.button}
          variant={'outlined'}
          icon={<Icon name="format_list_bulleted" size={MENU_ICON_SIZE} />}
          onClick={() =>
            wrapInList(schema.nodes.bullet_list)(
              proseMirrorView.getState(),
              proseMirrorView.getDispatch(),
              proseMirrorView.getEditor()
            )
          }
          disabled={!wrapInList(schema.nodes.bullet_list)(proseMirrorView.getState())}
        />
        <Button
          className={style.button}
          variant={'outlined'}
          icon={<Icon name="format_list_numbered" size={MENU_ICON_SIZE} />}
          onClick={() =>
            wrapInList(schema.nodes.ordered_list)(
              proseMirrorView.getState(),
              proseMirrorView.getDispatch(),
              proseMirrorView.getEditor()
            )
          }
          disabled={!wrapInList(schema.nodes.ordered_list)(proseMirrorView.getState())}
        />
        <Button
          className={style.button}
          variant={'outlined'}
          icon={<Icon name="format_quote" size={MENU_ICON_SIZE} />}
          onClick={() => {
            wrapIn(schema.nodes.blockquote)(proseMirrorView.getState(), proseMirrorView.getDispatch());
          }}
          disabled={!wrapIn(schema.nodes.blockquote)(proseMirrorView.getState())}
        />

        <Button
          className={style.button}
          variant={'outlined'}
          icon={<Icon name="table" size={MENU_ICON_SIZE} />}
          onClick={toggleCreateTablemenu}
          ref={anchorCreateTableMenuRef}
        />
        <Menu
          anchorElement={anchorCreateTableMenuRef.current}
          isOpen={isCreateTableMenuOpen}
          onClose={onCloseCreateTableMenu}
        >
          <CreateTableForm proseMirrorView={proseMirrorView} />
        </Menu>
      </div>
    </>
  );
}

export function TopMenu({ menuNodes, proseMirrorView, schema }: TopMenuProps) {
  if (!menuNodes?.length) {
    return null;
  }

  return createPortal(<MenuBar proseMirrorView={proseMirrorView} schema={schema} />, menuNodes[0]);
}
