import { DRAWIO } from '../types/drawioGlobal';

import { CellsAddListener, EventNames, HandledModelsAdd } from '../types/events';
import { useEffect } from 'react';
import { MxEvent } from '../types/drawio/mx';
import { EventListener } from '../types/drawio/draw';
import { MxEventObject, MxEventObjectProperty } from '../types/drawio/mx/mxEvent';
import { getAttributes } from '../utils/drawio/getAttributes';
import { Models } from '@baseModel/engine/types';
import { getBase64 } from '../utils/drawio/getBase64';
import { SystemDrawioAttributes } from '../const';

export function useSubscribeAddCells(drawio: DRAWIO | undefined, listener: CellsAddListener) {
  useEffect(() => {
    if (!drawio) {
      return;
    }
    const {
      ui: {
        editor: { graph }
      },
      globals: { mxCodec, mxUtils }
    } = drawio;
    const drawioEventListener: EventListener<MxEventObject> = function (_, evt) {
      const changes = evt.getProperty(MxEventObjectProperty.cells);

      const result: HandledModelsAdd[] = [];

      (Array.isArray(changes) ? changes : [changes]).forEach((cell) => {
        const cellId = cell.getId();
        const newAttributes = getAttributes(cell);

        const engineModelType = newAttributes[SystemDrawioAttributes.modelType] as Models;
        // Объекты несвязанные с моделью не обрабатываем
        if (!engineModelType) {
          return;
        }

        result.push({
          engineModelType,
          event: EventNames.add,
          newAttributes,
          mxId: cellId
        });
      });
      const base64 = getBase64(graph, mxCodec, mxUtils);
      listener({ changes: result, base64 });
    };

    graph.addListener(MxEvent.CELLS_ADDED, drawioEventListener);
    return () => {
      graph.removeListener(MxEvent.CELLS_ADDED, drawioEventListener);
    };
  }, [drawio, listener]);
}
