import { DataStoreName } from '@baseModel/utils/dataJuggler';
import { RelationMetaModel } from '@baseModel/metaModel/relationMetaModel';
import { JsonField, JsonSource } from '@baseModel/types/jsonDescription';
import { EntityMetaModel } from '@baseModel/metaModel/entityMetaModel';
import { Relation } from '@baseModel/model/relation';
import { Entity } from '@baseModel/model/entity';
import { SimpleValueType } from '@baseModel/types/simpleValueType';

export enum ComparisonOperator {
  $eq = '$eq',
  $ne = '$ne',
  $in = '$in'
  // $gt = '$gt',
  // $lt = '$lt',
  // $gte = '$gte',
  // $lte = '$lte'
}

export enum LogicalOperator {
  $and = '$and',
  $or = '$or',
  $not = '$not'
}

export type AcceptedMetaModel = RelationMetaModel | EntityMetaModel;
export type AcceptedModel = Entity | Relation;
export type AcceptedUnion = AcceptedMetaModel | AcceptedModel;

export type LogicalCondition = { [operator in ComparisonOperator]?: SimpleValueType | SimpleValueType[] | undefined };

export type ConditionValue<T extends JsonSource | JsonField | undefined> = {
  [K in keyof T]?: LogicalCondition;
};

export type Condition<T extends AcceptedUnion> = DataJugglerCondition<T> & {
  [operator in LogicalOperator]?: Condition<T>[];
};

export interface Query<T extends AcceptedUnion> {
  where?: Condition<T>;
}

export type Subquery<T extends AcceptedUnion, K extends JsonSource | JsonField = never> = T extends AcceptedMetaModel
  ? ConditionValue<K>
  : LogicalCondition;

export type DataJugglerCondition<T extends AcceptedUnion> = {
  [DataStoreName.relations]?: {
    [name: string]: T extends AcceptedMetaModel ? ConditionValue<JsonSource> : LogicalCondition;
  };
  [DataStoreName.fields]?: {
    [name: string]: T extends AcceptedMetaModel ? ConditionValue<JsonField> : LogicalCondition;
  };
  [DataStoreName.common]?: {
    [name: string]: never;
  };
} & {
  [operator in LogicalOperator]?: DataJugglerCondition<T>[];
};
