import React, { useMemo } from 'react';
import cn from 'classnames';
import { Button } from '@smwb/summer-ui';
import { Models } from '@baseModel/engine/types';
import { ActionsCell } from '../actionsCell';
import { Engine } from '@baseModel/engine/engine';
import { Entity } from '@baseModel/model/entity';
import { Relation } from '@baseModel/model/relation';
import { confirmDialog } from '@components/confirmDialog/confirmDialog';
import s from './table.module.less';
import { ColumnDef, ColumnSizingState, Row } from '@tanstack/react-table';
import { Table as RTable } from '@smwb/summer-ui/dist/connects/rt/table';
import { TableBlock } from '../../../types';
import { ACTION_COLUMN, NestedTable } from '../../types';
import { getColumnName } from '../../utils/getColumnName';
import { RenderCell } from './renderCell';
import { TableHeader } from './tableHeader';
import { ExpandTable } from '../expandTable/expandTable';
import { ActionType } from '@baseModel/components/modelForm/types';
import { ModelFormPropsAdd, ModelFormPropsUpdate } from '@baseModel/components/modelForm/modelForm';
import { Sender } from '@utils/observable';

const engine = Engine.getInstance();

export class EntityRelation {
  constructor(readonly relationId: string, readonly model: Entity) {}
}

export interface TableProps {
  className?: string;
  configData: TableBlock;
  models: Entity[] | Relation[] | EntityRelation[];
  title?: React.ReactNode;
  modelId?: string;
  hiddenModelId?: string;
  nestedTable?: NestedTable;
  onSave: (data: TableBlock) => Promise<void> | undefined;
  setEditModalData: (data: ModelFormPropsAdd | ModelFormPropsUpdate | undefined) => void;
  sender: Sender;
}

const onDeleteRelation = (id: string) => () => {
  confirmDialog({
    message: 'Безвозвратное удаление. Вы действительно хотите удалить связь?',
    accept: () => {
      try {
        engine.removeRelation(id);
      } catch (error) {
        console.error(error);
      }
    }
  });
};

const onModelDelete = (model: Entity | Relation) => () => {
  const modelId = model.getId();

  if (modelId === undefined) {
    return;
  }

  confirmDialog({
    message: 'Безвозвратное удаление. Вы действительно хотите удалить модель?',
    accept: () => {
      try {
        if (model instanceof Entity) {
          engine.removeEntity(modelId);
        } else {
          engine.removeRelation(modelId);
        }
      } catch (error) {
        console.error(error);
      }
    }
  });
};

function getCurrentModelId(element: EntityRelation | Entity | Relation) {
  const model = element instanceof EntityRelation ? element.model : element;
  return (model instanceof EntityRelation ? model.model : model).getId() as string;
}

export function Table({
  className,
  sender,
  setEditModalData,
  configData,
  models,
  title,
  modelId,
  hiddenModelId,
  nestedTable,
  onSave
}: TableProps) {
  const isEntity = configData.modelType === Models.Entity;
  const modelNames = isEntity ? engine.getEntityMetaModelsNames() : engine.getRelationMetaModelsNames();

  const metaModel = isEntity
    ? engine.getMetaEntityByName(configData.rowType)
    : engine.getMetaRelationByName(configData.rowType);

  const rtColumns: ColumnDef<typeof models[number]>[] = useMemo(() => {
    const metaModelName = nestedTable && !nestedTable?.endTargetName ? nestedTable.relation : configData.rowType;

    const actionColumn: ColumnDef<typeof models[number]> = {
      id: ACTION_COLUMN.actionsLeft,
      header: () => (
        <div className={s.buttons}>
          <Button
            className={s.iconButton}
            icon="add"
            onClick={() =>
              setEditModalData({
                actionType: ActionType.Add,
                modelType: configData.modelType,
                metaModelName,
                modelId,
                nested: !nestedTable
                  ? undefined
                  : {
                      name: nestedTable.relation,
                      master: nestedTable.endTargetName,
                      slave: nestedTable.endSelfName
                    }
              } satisfies ModelFormPropsAdd)
            }
          />
        </div>
      ),
      cell: (row) =>
        row.row.getCanExpand() ? (
          <Button
            {...{
              onClick: row.row.getToggleExpandedHandler()
            }}
            className={s.iconButton}
            variant="text"
            icon={row.row.getIsExpanded() ? 'expand_less' : 'expand_more'}
            iconProps={{ size: 24 }}
          />
        ) : null,
      accessorFn: (row) => row,
      meta: {
        cellProps: {
          className: s.expandCell,
          style: configData.showLink
            ? {}
            : {
                display: 'none'
              }
        }
      },
      size: 36,
      enableResizing: false
    };
    return [actionColumn]
      .concat(
        configData.columns.map((column, i) => {
          return {
            id: getColumnName(engine, configData.modelType, configData.rowType, column) + '####' + i.toString(),
            header: () => <TableHeader model={metaModel} column={configData.columns[i]} />,
            cell: (row) => {
              const rowValue = row.getValue();
              if (rowValue instanceof Entity || rowValue instanceof Relation) {
                return <RenderCell model={rowValue} column={column} index={i} sender={sender} />;
              } else if (rowValue instanceof EntityRelation) {
                return <RenderCell model={rowValue.model} column={column} index={i} sender={sender} />;
              } else {
                return <span>{`unknown model type ${rowValue}`}</span>;
              }
            },
            accessorFn: (row) => row,
            meta: {
              cellProps: {
                colSpan: i === 0 && !configData.showLink ? 2 : 1
              },
              draggableColumn: true
            },
            size: column.size,
            enableResizing: i < configData.columns.length - 1
          };
        })
      )
      .concat([
        {
          id: ACTION_COLUMN.actionsRight,
          header: () => <div />,
          cell: (row) => (
            <ActionsCell
              onDelete={
                row.getValue() instanceof EntityRelation
                  ? onDeleteRelation((row.getValue() as EntityRelation).relationId)
                  : onModelDelete(row.getValue() as Entity | Relation)
              }
              onUpdate={() =>
                setEditModalData({
                  actionType: ActionType.Update,
                  modelId: (row.getValue() as Entity).getId() as string,
                  modelType: (row.getValue() as Entity).modelType,
                  metaModelName,
                  nested: !nestedTable
                    ? undefined
                    : {
                        name: nestedTable.relation,
                        master: nestedTable.endTargetName,
                        slave: nestedTable.endSelfName
                      }
                } satisfies ModelFormPropsUpdate)
              }
            />
          ),
          meta: {
            cellProps: {
              className: s.actionCell
            }
          },
          size: 72,
          enableResizing: false,
          accessorFn: (row) => row
        }
      ]);
  }, [
    configData.columns,
    configData.modelType,
    configData.rowType,
    configData.showLink,
    metaModel,
    modelId,
    nestedTable,
    sender,
    setEditModalData
  ]);

  if (!modelNames.includes(configData.rowType)) {
    return <div>Model type ${configData.rowType} not found.</div>;
  }
  // https://github.com/microsoft/TypeScript/issues/44373#issuecomment-1405744410
  const modelsFix: Array<typeof models[number]> = models;
  return (
    <>
      {/*Не даем редактору обрабатывать действия над таблицей*/}
      <div
        className={cn(s.table, className)}
        onKeyDown={(e) => e.stopPropagation()}
        onKeyUp={(e) => e.stopPropagation()}
        onKeyPress={(e) => e.stopPropagation()}
        onPaste={(e) => e.stopPropagation()}
        onCopy={(e) => e.stopPropagation()}
      >
        {title && (
          <div className={s.header}>
            <div className={s.title}>{title}</div>
          </div>
        )}
        <RTable
          data={modelsFix.filter((element) => {
            // Не показываем запись модели, стоящей на уровень выше по иерархии
            return getCurrentModelId(element) !== hiddenModelId;
          })}
          columns={rtColumns}
          expandable
          enableColumnResizing={rtColumns.length > 3}
          draggableColumns
          getRowCanExpand={() => !!configData.showLink}
          renderSubComponent={({ row }: { row: Row<EntityRelation | Entity | Relation> }) => {
            return configData.showLink ? (
              <ExpandTable
                sender={sender}
                setEditModalData={setEditModalData}
                parentModelId={getCurrentModelId(row.original)}
                inheritModelId={modelId}
                showLink={configData.showLink}
                engine={engine}
                onSave={(columns) => {
                  if (!configData.showLink) {
                    return;
                  }
                  void onSave({
                    ...configData,
                    showLink: {
                      ...configData.showLink,
                      table: {
                        ...configData.showLink?.table,
                        columns
                      }
                    }
                  });
                }}
              />
            ) : (
              <span>showLink not found</span>
            );
          }}
          onColumnSizingChange={(oldSize: ColumnSizingState, newSize: ColumnSizingState) => {
            const sizeKeys = Object.keys(newSize);
            for (const key of sizeKeys) {
              const index = +key.split('####')[1];
              configData.columns[index] = { ...configData.columns[index], size: newSize[key] };
            }
            void onSave({
              ...configData,
              columns: [...configData.columns]
            });
          }}
        />
      </div>
    </>
  );
}
