import { Graph } from '../../types/drawio/draw';
import { MxUtils } from '../../types/drawio/mx';
import { MxCell } from '../../types/drawio/mx/mxCell';
import { checkType } from './checkType';
import { COMMON_PROPERTIES } from '@baseModel/basisModel/const';
import { GraphElementType } from '../../const';

export function findElementByEngineModelId(
  graph: Graph,
  mxUtils: MxUtils,
  engineModelId: string,
  type?: GraphElementType
): MxCell | undefined {
  const model = graph.model;
  for (const id in model.cells) {
    const cell = model.cells[id];
    const node = <HTMLElement>cell?.value;
    if (!mxUtils.isNode(node, null, COMMON_PROPERTIES.id, engineModelId)) {
      continue;
    }
    if (type) {
      return checkType(cell, type) ? cell : undefined;
    }
    return cell;
  }
  return;
}
