import { object, ObjectSchema, string, array, number, mixed, lazy } from 'yup';
import { BaseWidget } from '@components/markdownEditor/dataDisplayWidgets/baseWidget/baseWidget';
import { Models } from '@baseModel/engine/types';
import { TableBlock, TableBlockColumn, TableBlockColumnTypes, TableBlockShowLink } from './types';
import { EditorView } from './editorView/editorView';
import { ConfigView } from './configView/configView';
import { SheetProps } from '@smwb/summer-ui';

const columnsValidate = array()
  .of<TableBlockColumn>(
    object({
      name: string(),
      displayName: string(),
      value: string(),
      size: number(),
      type: mixed<TableBlockColumnTypes>().oneOf<TableBlockColumnTypes | undefined>(
        Object.values(TableBlockColumnTypes)
      )
    })
  )
  .required();

const createChildShowLinkSchema = (): ObjectSchema<TableBlockShowLink> =>
  object().shape({
    relation: string().required(),
    endSelf: string().required(),
    endTarget: string().nonNullable(),
    showLink: lazy(() => createChildShowLinkSchema()),
    table: object({
      columns: columnsValidate
    }).required()
  });

const childShowLinkSchema = createChildShowLinkSchema();

const showLinkSchema = object<TableBlockShowLink>().shape({
  relation: string().required(),
  endSelf: string().required(),
  endTarget: string().nonNullable(),
  showLink: childShowLinkSchema,
  table: object({
    columns: columnsValidate
  }).required()
});

const tableSchema: ObjectSchema<TableBlock> = object<TableBlock>().shape({
  modelType: mixed<Models.Entity | Models.Relation>()
    .oneOf<Models.Entity | Models.Relation>([Models.Entity, Models.Relation])
    .required(),
  rowType: string().required(),
  columns: columnsValidate,
  showLink: showLinkSchema,
  filter: object({
    diagram: string()
  })
});

const valueSchema: ObjectSchema<TableBlock> = tableSchema;

export class TableViewWidget extends BaseWidget<TableBlock> {
  public override valueSchema = valueSchema;

  public override getName(): string {
    return 'table-view';
  }

  public override getMenuItem(): string {
    return 'Таблица моделей';
  }

  public override getEditorView() {
    return EditorView;
  }

  public override getConfigView() {
    return ConfigView;
  }

  public override getConfigViewSize(): SheetProps['size'] {
    return '50%';
  }

  protected getInitConfig(): Promise<TableBlock | undefined> {
    return Promise.resolve({} as TableBlock);
  }
}

CustomWidgets.register(TableViewWidget);
