import { Models } from '@baseModel/engine/types';

export enum TableBlockColumnTypes {
  autonumber = 'autonumber'
}

export interface TableBlockColumn {
  type?: TableBlockColumnTypes;
  displayName?: string;
  value?: string;
  name?: string;
  size?: number;
}

export interface JsonDocumentTableBlockShowLinkTable {
  columns: TableBlockColumn[];
}

export interface TableBlockShowLink {
  relation: string;
  endSelf: string;
  endTarget?: string;
  table: JsonDocumentTableBlockShowLinkTable;
  showLink?: TableBlockShowLink;
}

export interface TableFilter {
  diagram?: string;
}

export interface TableBlock {
  modelType: Models.Entity | Models.Relation;
  rowType: string;
  columns: TableBlockColumn[];
  showLink?: TableBlockShowLink;
  filter?: TableFilter;
}
