import { Modal } from '@ui/components';
import s from '@components/confirmDialog/confirmDialog.module.less';
import { Button } from '@smwb/summer-ui';
import React from 'react';

export interface ErrorModalProps {
  errorModalContent?: string;
  onClose: () => void;
}

export function ErrorModal({ onClose, errorModalContent }: ErrorModalProps) {
  return (
    <Modal className={s.root} open={!!errorModalContent} onClose={onClose} size="small" closeOnOutsideClick>
      <Modal.Header>Ошибка</Modal.Header>
      <Modal.Content>{errorModalContent}</Modal.Content>
      <Modal.Footer className={s.footer}>
        <Button variant="contained" onClick={onClose}>
          ОК
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
