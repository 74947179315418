import { MxCell } from '../../types/drawio/mx/mxCell';
import { Graph, Model } from '../../types/drawio/draw';
import { MxUtils } from '../../types/drawio/mx';

export function setCellStyle(
  mxUtils: MxUtils,
  graph: Graph,
  model: Model,
  cell: MxCell,
  key: string,
  style: string | undefined
) {
  const currentStyle = cell.getStyle() ?? '';
  const newStyle = mxUtils.setStyle(currentStyle, key, style);
  model.beginUpdate();
  try {
    model.setStyle(cell, newStyle ?? '');
  } finally {
    model.endUpdate();
  }
  graph.refresh(cell);
}
