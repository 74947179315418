import React from 'react';
import { AnyObject } from '@baseModel/utils/dataJuggler';
import { useBlockData } from '@components/markdownEditor/dataDisplayWidgets/baseWidget/hooks/useBlock';
import {
  BaseWidget,
  ConfigSave,
  EditorViewProps
} from '@components/markdownEditor/dataDisplayWidgets/baseWidget/baseWidget';

export interface EditorProps<T extends AnyObject> extends Omit<EditorViewProps<T>, 'configData'> {
  Component: NonNullable<ReturnType<BaseWidget<T>['getEditorView']>>;
  blockId: string | undefined;
  senderId: string;
  onSave: ConfigSave<T>;
}

export function Editor<T extends AnyObject>({
  Component,
  engine,
  blockId,
  senderId,
  onSave
}: EditorProps<T>): ReturnType<React.FC<EditorViewProps<T>>> {
  const [configData] = useBlockData<T>(blockId, senderId);

  // TODO: Может сделать проп configData в компонент необязательным, чтобы показывать индикатор загрузке в компоненте
  //  и/или прокидывать ещё проп isLoadingData?
  if (configData === undefined) {
    return null;
  }

  return <Component engine={engine} configData={configData} onSave={onSave} senderId={senderId} />;
}
