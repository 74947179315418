import { useEffect, useRef, useState } from 'react';
import { Icon } from '@smwb/summer-ui';
import cs from 'classnames';

export interface Item {
  label?: string;
  htmlElement?: Element;
  children?: Item[];
  key: string;
  openByDefault?: boolean;
}

export interface TreeNodeItemProps {
  listIcon?: string;
  nodeIcon?: { opened: string; closed: string };
  item: Item;
}

export const TreeNodeItem = ({
  item,
  nodeIcon = { closed: 'folder', opened: 'folder_open' },
  listIcon = 'draft'
}: TreeNodeItemProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const hasChildNodes = item.children && item.children?.length > 0;
  const [isOpen, setIsOpen] = useState(!!item.openByDefault);

  const toggleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (containerRef.current && item.htmlElement) {
      const htmlElement = item.htmlElement;
      containerRef.current.insertBefore(htmlElement, containerRef.current.firstChild);
      const removeChild = containerRef.current.removeChild.bind(containerRef.current);
      return () => {
        removeChild(htmlElement);
      };
    }
  }, [item.htmlElement]);
  return (
    <>
      <div className={cs('smwb-tree__node', 'tree_node_overloads')}>
        <span className={cs('smwb-tree__node__content', 'tree_node_content_overloads')} onClick={toggleIsOpen}>
          <div ref={containerRef} className="smwb-tree__node__label">
            {!item.htmlElement && hasChildNodes && isOpen && <Icon name={nodeIcon?.opened} fill={1} size={20} />}
            {!item.htmlElement && hasChildNodes && !isOpen && <Icon name={nodeIcon?.closed} fill={1} size={20} />}
            {!item.htmlElement && !hasChildNodes && null}
            <span>{item.label}</span>
          </div>
        </span>
        {item.children && isOpen && (
          <>
            {item.children.map((c) => (
              <TreeNodeItem key={c.key} nodeIcon={nodeIcon} listIcon={listIcon} item={c} />
            ))}
          </>
        )}
      </div>
    </>
  );
};
