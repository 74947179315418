import React, { useEffect } from 'react';
import { DrawioGlobals } from '../../types/drawio/drawioGlobals';
import { previewResize } from '../tools/previewResize';

export function useInitPreviewFrame(
  wrapperRef: React.RefObject<HTMLDivElement>,
  iframeRef: HTMLIFrameElement | null,
  drawio?: DrawioGlobals
): void {
  // Подгоняем размер окна под содержимое
  useEffect(() => {
    if (iframeRef && drawio) {
      const timeId = setTimeout(() => {
        previewResize(iframeRef);
      }, 500);
      return () => {
        clearTimeout(timeId);
      };
    }
  }, [drawio, iframeRef]);

  // Пробрасываем клик выше в виджет
  useEffect(() => {
    if (drawio) {
      const dispatchMouseUpEvent = () => {
        const mouseUpEvent = new MouseEvent('mouseup', {
          bubbles: true,
          cancelable: true,
          view: window
        });
        wrapperRef.current?.dispatchEvent(mouseUpEvent);
      };

      drawio.window.document.addEventListener('click', dispatchMouseUpEvent);

      return () => {
        drawio.window.document.removeEventListener('click', dispatchMouseUpEvent);
      };
    }
  }, [drawio, wrapperRef]);
}
