import React, { useEffect } from 'react';
import cn from 'classnames';
import { Link, NavLink, Outlet } from 'react-router-dom';
import { AppBar, Icon, Sidebar, Theme, useTheme } from '@smwb/summer-ui';
import { Urls } from '../../routes';
import s from './layout.module.less';
import { useIsMobile } from '@hooks/useIsMobile';

export function Layout() {
  const { setTheme } = useTheme();
  // const isDark = theme === Theme.dark;
  const isMobile = useIsMobile();

  useEffect(() => {
    setTheme(Theme.dark);
  }, [setTheme]);

  return (
    <div className={s.root}>
      <AppBar className={s.appbar} position="fixed" title="Документ" />
      {!isMobile && (
        <Sidebar
          className={s.sidebar}
          variant="compactMini"
          menuItems={[{ icon: <Icon name="source" />, as: SidebarLink, href: Urls.documents }]}
        />
      )}
      <div className={cn(s.content, isMobile && s.contentIsMobile)}>
        <Outlet />
      </div>
      {isMobile && (
        <div className={s.bottomBar}>
          <AppBar
            position="fixed"
            placement="bottom"
            dense
            leftContent={
              <div className={s.mobileMenu}>
                <MobileMenuLink to={Urls.documents}>Documents</MobileMenuLink>
              </div>
            }
          />
        </div>
      )}
    </div>
  );
}

function SidebarLink({ href, ...rest }: { href: string }) {
  return <Link to={href} {...rest} />;
}

function MobileMenuLink({ to, children }: { to: string; children: React.ReactNode }) {
  return (
    <NavLink className={({ isActive }) => cn(s.mobileMenuLink, isActive && s.mobileMenuLinkIsActive)} to={to}>
      {children}
    </NavLink>
  );
}
