import React, { useCallback, useRef, useState } from 'react';
import {
  DRAW_IO_DEFAULT_SOURCE_PATH,
  errorStyleColor,
  SystemDrawioAttributes,
  systemDrawioAttributesForRemove
} from '../const';
import { Models } from '@baseModel/engine/types';
import { ConfigViewProps } from '@components/markdownEditor/dataDisplayWidgets/baseWidget/baseWidget';
import { DrawioWidgetValue } from '../drawioWidget';
import { useDrawioLoad } from '../hooks/useDrawioLoad';
import { useInitPreview } from '../hooks/useInitPreview';
import debounce from 'lodash/debounce';
import { useSubscribeChangeModel } from '../hooks/useSubscribeChangeModel';
import { forEach } from 'lodash';
import { useStyleConnect } from './hooks/useStyleConnect';
import { useSidebar } from './sidebar/hooks/useSidebar';
import { useSubscribeAddCells } from '../hooks/useSubscribeAddCells';
import { CellsAddListener, CellsDeletedListener, ChangesWithBase64Listener, ConnectListener } from '../types/events';
import { COMMON_PROPERTIES } from '@baseModel/basisModel/const';
import { Entity } from '@baseModel/model/entity';
import { Relation } from '@baseModel/model/relation';
import { setCellAttributes } from './utils/setCellAttributes';
import { useSubscribeDeleteCells } from '../hooks/useSubscribeDeleteCells';
import { useSubscribeConnectModel } from '../hooks/useSubscribeConnectModel';
import { isHandledModelsConnected } from '../guards/isHandledModelsConnected';
import { ErrorModal } from '../components/errorModal';
import { MxCell } from '../types/drawio/mx/mxCell';
import { getAttributes } from '../utils/drawio/getAttributes';
import { setCellStyle } from './utils/setCellStyle';
import { getEnds } from './utils/getEnds';
import { DrawioContains } from './types';

interface SessionModelCreated {
  type: Models;
  id: string;
}

export function ConfigView({ initData, onSave, engine, senderId }: ConfigViewProps<DrawioWidgetValue>) {
  const { setIframeRef, iframeRef, drawio, onDrawioIframeLoad } = useDrawioLoad();
  const sessionModelCreated = useRef<SessionModelCreated[]>([]);
  const newContains = useRef<DrawioContains>(
    Array.isArray(initData.contains) || !initData.contains || !Object.keys(initData.contains).length
      ? {
          [Models.Entity]: {},
          [Models.Relation]: {}
        }
      : initData.contains
  );
  const [errorModalContent, setErrorModalContent] = useState('');
  useStyleConnect(iframeRef);
  useInitPreview(drawio?.globals, drawio?.ui, initData.base64);
  useSidebar(engine, drawio);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const saveDebounced = useCallback(debounce(onSave, 50), [onSave]);
  const drawioChangeEventListener = useCallback<ChangesWithBase64Listener>(
    ({ changes, base64 }) => {
      changes.forEach((change) => {
        const modelType = change.engineModelType;
        if (modelType === Models.Entity) {
          const modelId = change.engineModelId;
          const entity = engine.getEntityById(modelId);
          forEach(change.newAttributes, (value, field) => {
            entity.setFieldValue(field, value, senderId);
          });
        }
        if (modelType === Models.Relation) {
          const modelId = change.engineModelId;
          const entity = engine.getRelationById(modelId);
          forEach(change.newAttributes, (value, field) => {
            entity.setFieldValue(field, value, senderId);
          });
        }
      });
      void saveDebounced({ ...initData, contains: newContains.current, base64 }, false);
    },
    [engine, initData, saveDebounced, senderId]
  );

  const drawioAddCellsEventListener = useCallback<CellsAddListener>(
    ({ changes, base64 }) => {
      if (!drawio) {
        return;
      }
      const graph = drawio.ui.editor.graph;
      const model = graph.getModel();
      changes.forEach((change) => {
        const modelType = change.engineModelType;
        const entityModelType =
          modelType === Models.EntityMetaModel || modelType === Models.Entity ? Models.Entity : Models.Relation;
        const metaModelName =
          modelType === Models.EntityMetaModel || modelType === Models.RelationMetaModel
            ? change.newAttributes[COMMON_PROPERTIES.commonName]
            : change.newAttributes[COMMON_PROPERTIES.type];
        if (!newContains.current[entityModelType][metaModelName]) {
          newContains.current[entityModelType][metaModelName] = [];
        }
        let newId: string | undefined;
        if (modelType === Models.EntityMetaModel || modelType === Models.RelationMetaModel) {
          const engineModel =
            modelType === Models.EntityMetaModel
              ? new Entity(engine.getMetaEntityByName(metaModelName))
              : new Relation(engine.getMetaRelationByName(metaModelName));
          newId = engineModel.setId();
          newContains.current[entityModelType][metaModelName].push(newId);
          const cell = model.getCell(change.mxId);
          const modelName =
            modelType === Models.EntityMetaModel
              ? `${metaModelName}-${Date.now().toString(16).slice(8)}`
              : metaModelName;
          const attributes = {
            [COMMON_PROPERTIES.id]: newId,
            [COMMON_PROPERTIES.type]: metaModelName,
            [SystemDrawioAttributes.label]: modelName,
            [SystemDrawioAttributes.modelType]: modelType === Models.EntityMetaModel ? Models.Entity : Models.Relation
          };
          setCellAttributes(model, cell, attributes);
          forEach(change.newAttributes, (value, field) => {
            if (systemDrawioAttributesForRemove.find((el) => el === field)) {
              return;
            }
            if (field === COMMON_PROPERTIES.commonName) {
              value = modelName;
            } else if (field === COMMON_PROPERTIES.type) {
              value = metaModelName;
            }
            engineModel.setFieldValue(field, value);
          });
          engine.add(engineModel, senderId);
        } else {
          const modelId = change.newAttributes[COMMON_PROPERTIES.id];
          newContains.current[entityModelType][metaModelName].push(modelId);
        }

        if (modelType === Models.Relation) {
          const modelId = change.newAttributes[COMMON_PROPERTIES.id];
          const relation = engine.getRelationById(modelId);
          let source: MxCell | undefined;
          let target: MxCell | undefined;
          const getEnd = getEnds(relation);

          for (const id in model.cells) {
            const cell = model.cells[id];
            const cellId = getAttributes(cell)[COMMON_PROPERTIES.id];
            if (cellId === getEnd.targetValue) {
              target = cell;
            }
            if (cellId === getEnd.sourceValue) {
              source = cell;
            }
          }

          if (source || target) {
            const edge = model.getCell(change.mxId);
            model.beginUpdate();
            try {
              if (source) {
                graph.getModel().setTerminal(edge, source, true);
              }
              if (target) {
                graph.getModel().setTerminal(edge, target, false);
              }
            } finally {
              model.endUpdate();
            }
            graph.refresh(edge);
          }
        }
        if (newId) {
          sessionModelCreated.current = sessionModelCreated.current.concat({
            type: modelType === Models.EntityMetaModel || modelType === Models.Entity ? Models.Entity : Models.Relation,
            id: newId
          });
        }
      });
      void saveDebounced({ ...initData, contains: newContains.current, base64 }, false);
    },
    [drawio, engine, initData, saveDebounced, senderId]
  );

  const drawioCellsDeletedEventListener = useCallback<CellsDeletedListener>(
    ({ changes, base64 }) => {
      if (!drawio) {
        return;
      }
      changes.forEach((change) => {
        const modelId = change.engineModelId;
        const modelType = change.engineModelType;
        const itWasNew = !!sessionModelCreated.current.find((el) => el.type === modelType && el.id == modelId);
        if (modelType === Models.Entity) {
          if (!newContains.current[modelType]) {
            newContains.current[modelType] = {};
          }
          const model = engine.getEntityById(modelId);
          const metaModelName = model.getMetaModel().getName();
          newContains.current[modelType][metaModelName] = newContains.current[modelType]?.[metaModelName]?.filter(
            (el) => el !== modelId
          );
          if (itWasNew) {
            engine.removeEntity(modelId);
          }
        }
        if (modelType === Models.Relation) {
          if (!newContains.current[modelType]) {
            newContains.current[modelType] = {};
          }
          const model = engine.getRelationById(modelId);
          const metaModelName = model.getMetaModel().getName();
          newContains.current[modelType][metaModelName] = newContains.current[modelType]?.[metaModelName]?.filter(
            (el) => el !== modelId
          );
          if (itWasNew) {
            engine.removeRelation(modelId);
          }
        }
      });
      void saveDebounced({ ...initData, contains: newContains.current, base64 }, false);
    },
    [drawio, engine, initData, saveDebounced]
  );

  const drawioConnectEventListener = useCallback<ConnectListener>(
    ({ changes }) => {
      if (!drawio) {
        return;
      }
      try {
        changes.forEach((change) => {
          // Связи непривязанные к модели игнорируем
          if (!change.relationModelId) {
            return;
          }
          const relation = engine.getRelationById(change.relationModelId);
          const ends = getEnds(relation);
          if (isHandledModelsConnected(change)) {
            if (!change.entityModelId) {
              throw new Error('Попытка связать объект непривязанный к модели');
            }

            const itsNew = !!sessionModelCreated.current.find(
              (el) => el.id === relation.getId() && el.type === Models.Relation
            );

            const entityId = change.entityModelId;
            const entity = engine.getEntityById(entityId);
            const entityType = entity.getMetaModel().getName();
            let relationFieldCanBeConnectToSource = false;
            let relationFieldCanBeConnectToTarget = false;
            if (ends.metaSourceName) {
              const metaSource = relation.getMetaModel().getRelationValue(ends.metaSourceName);
              relationFieldCanBeConnectToSource = metaSource?.type === entityType;
            }
            if (ends.metaTargetName) {
              const metaTarget = relation.getMetaModel().getRelationValue(ends.metaTargetName);
              relationFieldCanBeConnectToTarget = metaTarget?.type === entityType;
            }

            let relationFieldName: string | undefined;
            if (change.isTarget) {
              // Перезаписать можем только для новых связей
              if (ends.metaTargetName && relationFieldCanBeConnectToTarget && (itsNew || !ends.targetValue)) {
                relationFieldName = ends.metaTargetName;
              } else if (ends.metaSourceName && relationFieldCanBeConnectToSource && (itsNew || !ends.sourceValue)) {
                relationFieldName = ends.metaSourceName;
              }
            } else {
              if (ends.metaSourceName && relationFieldCanBeConnectToSource && (itsNew || !ends.sourceValue)) {
                relationFieldName = ends.metaSourceName;
              } else if (ends.metaSourceName && relationFieldCanBeConnectToTarget && (itsNew || !ends.targetValue)) {
                relationFieldName = ends.metaTargetName;
              }
            }
            const graph = drawio.ui.editor.graph;
            const model = graph.getModel();
            const edgeCell = model.getCell(change.mxEdgeId);
            if (relationFieldName) {
              setCellStyle(drawio.globals.mxUtils, graph, model, edgeCell, 'strokeColor', undefined);
              relation.setRelationValue(relationFieldName, entityId, senderId);
            } else if (ends.targetValue !== entityId && ends.sourceValue !== entityId) {
              setCellStyle(drawio.globals.mxUtils, graph, model, edgeCell, 'strokeColor', errorStyleColor);
              console.error('Не удалось установить связь', change, ends, relationFieldName, relation, entity);
              throw new Error(
                `Не удалось установить связь. ${!itsNew ? 'Запрещено менять ранее созданные связи' : ''} `
              );
            }
          } else {
            console.warn('Разрыв связи не будет обработан', change, relation, ends);
          }
        });
      } catch (e) {
        setErrorModalContent((e as Error).message);
      }
    },
    [engine, senderId, drawio]
  );

  useSubscribeChangeModel(drawio, drawioChangeEventListener, true);
  useSubscribeAddCells(drawio, drawioAddCellsEventListener);
  useSubscribeDeleteCells(drawio, drawioCellsDeletedEventListener);
  useSubscribeConnectModel(drawio, drawioConnectEventListener);

  return (
    <>
      <iframe
        src={!process.env.REACT_APP_DRAW_IO_PATH ? DRAW_IO_DEFAULT_SOURCE_PATH : process.env.REACT_APP_DRAW_IO_PATH}
        onLoad={onDrawioIframeLoad}
        ref={setIframeRef}
        style={{ borderStyle: 'none', width: '100%', height: '100%' }}
      />
      <ErrorModal onClose={() => setErrorModalContent('')} errorModalContent={errorModalContent} />
    </>
  );
}
