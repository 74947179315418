import React from 'react';
import { FormRenderProps } from 'react-final-form';
import { Button } from '@smwb/summer-ui';
import { MappingField } from '../mappingField/mappingField';
import s from './configForm.module.less';

export interface FormFields {
  mapping: string;
}

export interface ConfigFormProps {
  onSubmit: FormRenderProps<FormFields>['handleSubmit'];
}

export function ConfigForm({ onSubmit: onSubmitProp }: ConfigFormProps) {
  const onSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    void onSubmitProp(event);
  };

  return (
    <form className={s.root} onSubmit={onSubmit}>
      <MappingField
        className={s.mappingField}
        name="mapping"
        placeholder="Конфигурация карты модели"
        fieldProps={{
          validate: (value) => {
            if (!value) {
              return 'Обязательное поле';
            }
          }
        }}
        helperText="Введите YAML код"
      />
      <Button className={s.submitButton} type="submit">
        Сохранить
      </Button>
    </form>
  );
}
