// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tree_node_overloads {
  padding-left: 15px;
}
.tree_node_overloads :before {
  width: 15px;
  height: 15px;
}
.tree_node_content_overloads {
  height: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/widgets/drawio/configView/sidebar/components/treeNodeItem.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AAFA;EAII,WAAA;EACA,YAAA;AACJ;AAGA;EACE,YAAA;AADF","sourcesContent":[".tree_node_overloads {\n  padding-left: 15px;\n\n  :before {\n    width: 15px;\n    height: 15px;\n  }\n}\n\n.tree_node_content_overloads {\n  height: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
