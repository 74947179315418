import React from 'react';
import { object, ObjectSchema, string } from 'yup';
import {
  BaseWidget,
  ConfigViewProps,
  EditorViewProps
} from '@components/markdownEditor/dataDisplayWidgets/baseWidget/baseWidget';
import { Editor } from './view/editor/editor';
import { Config } from './view/config/config';

export interface ModelLinkValue {
  value: string;
}

const valueSchema: ObjectSchema<Partial<ModelLinkValue>> = object().shape({
  value: string()
});

export class ModelLink extends BaseWidget<ModelLinkValue> {
  public override valueSchema = valueSchema;

  public override getName(): string {
    return 'modelLink';
  }

  public override getMenuItem(): string {
    return 'Поле модели';
  }

  public override getEditorView(): React.FC<EditorViewProps<ModelLinkValue>> | null {
    return Editor;
  }

  public override getConfigView(): React.FC<ConfigViewProps<ModelLinkValue>> | null {
    return Config;
  }

  protected override async getInitConfig(): Promise<ModelLinkValue> {
    return new Promise((resolve) =>
      resolve({
        value: ''
      })
    );
  }
}

CustomWidgets.register(ModelLink);
