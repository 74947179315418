import { EditorViewProps } from '@components/markdownEditor/dataDisplayWidgets/baseWidget/baseWidget';
import { TableBlock } from '../types';
import { useState } from 'react';
import { Table } from './components/table/table';
import debounce from 'lodash/debounce';
import { Sheet } from '@smwb/summer-ui';
import { ModelForm, ModelFormPropsAdd, ModelFormPropsUpdate } from '@baseModel/components/modelForm/modelForm';
import { useIsMobile } from '@hooks/useIsMobile';
import { useEngineQuery } from '@baseModel/hooks/useEngineQuery';
import { COMMON_PROPERTIES } from '@baseModel/basisModel/const';
import { DrawioWidgetValue } from '../../drawio/drawioWidget';
import { map } from 'lodash';
import { useBlockData } from '@components/markdownEditor/dataDisplayWidgets/baseWidget/hooks/useBlock';
import { DataJugglerCondition, Query } from '@baseModel/engine/queryBuilder/types';
import { Entity } from '@baseModel/model/entity';
import { Relation } from '@baseModel/model/relation';

export function EditorView({ configData, engine, onSave, senderId }: EditorViewProps<TableBlock>) {
  const isMobile = useIsMobile();
  const [editModalData, setEditModalData] = useState<ModelFormPropsUpdate | ModelFormPropsAdd | undefined>(undefined);
  const [blockData] = useBlockData<DrawioWidgetValue>(configData.filter?.diagram);

  let entityFilter: DataJugglerCondition<Entity | Relation>[] = [];
  if (blockData) {
    const contains = blockData?.contains;
    if (contains) {
      entityFilter = map(contains[configData.modelType], (el, modelName) => {
        return { fields: { [COMMON_PROPERTIES.type]: { $eq: modelName }, [COMMON_PROPERTIES.id]: { $in: el } } };
      });
    }
  }
  const tableFilter: Query<Entity | Relation> = {
    where: {
      $and: [
        {
          fields: {
            [COMMON_PROPERTIES.type]: { $eq: configData.rowType }
          }
        }
      ]
    }
  };
  if (entityFilter.length) {
    tableFilter.where?.$and?.push({ $or: entityFilter });
  }

  const models = useEngineQuery(
    engine,
    configData.modelType,
    { ...tableFilter },
    senderId,
    !entityFilter.length && !!configData.filter?.diagram
  );

  const onSaveDebounce = debounce<typeof onSave>(onSave, 100);

  return (
    <>
      <Table
        sender={senderId}
        onSave={onSaveDebounce}
        configData={configData}
        models={models ?? []}
        setEditModalData={setEditModalData}
      />
      <Sheet
        isOpen={!!editModalData}
        onClose={() => setEditModalData(undefined)}
        variant="modal"
        placement={isMobile ? 'bottom' : 'right'}
        size="450px"
      >
        {editModalData && <ModelForm {...editModalData} engine={engine} onClose={() => setEditModalData(undefined)} />}
      </Sheet>
    </>
  );
}
