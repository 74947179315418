import { ToggleProps, Toggle } from '@smwb/summer-ui';
import React from 'react';
import { useFieldBasic } from '../../hooks/useField';
import { DataJuggler, DataStoreName, EmptyObject } from '../../utils/dataJuggler';
import { JsonField } from '../../types/jsonDescription';
import { Models } from '../../engine/types';
import { SimpleValueType } from '../../types/simpleValueType';

export interface ToggleFieldEntityBasicProps extends ToggleProps {
  modelType: Models;
  modelName: string;
  fieldName: string;
  dataStoreName: DataStoreName;
  fieldPart?: keyof JsonField;
  sender?: string | EmptyObject;
}

export function ToggleFieldEntityBasic({
  modelType,
  modelName,
  fieldName,
  dataStoreName,
  fieldPart,
  sender,
  ...toggleProps
}: ToggleFieldEntityBasicProps) {
  const { value, setValue } = useFieldBasic(modelType, modelName, fieldName, dataStoreName, sender);
  let fieldValue: string | number | boolean | undefined;
  if (modelType === Models.EntityMetaModel || modelType === Models.RelationMetaModel) {
    if (fieldPart) {
      fieldValue = (value as JsonField)?.[fieldPart];
    } else {
      throw new Error('fieldPart is required for EntityMetaModel and RelationMetaModel');
    }
  } else {
    fieldValue = value as SimpleValueType;
  }
  // TODO smui поправить интерфейс пропсов
  return (
    <Toggle
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      {...(toggleProps as any)}
      checked={typeof fieldValue !== 'boolean' ? !!fieldValue : fieldValue}
      onChange={(e) => {
        if (modelType === Models.EntityMetaModel || modelType === Models.RelationMetaModel) {
          (setValue as typeof DataJuggler.prototype.setFieldValue)(
            fieldName,
            {
              ...(value as JsonField),
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              [fieldPart!]: e.target.checked
            },
            sender
          );
        } else {
          (setValue as typeof DataJuggler.prototype.setFieldValue)(fieldName, e.target.checked);
        }
      }}
    />
  );
}
export function ToggleFieldEntity({ ...props }: Omit<ToggleFieldEntityBasicProps, 'dataStoreName'>) {
  return <ToggleFieldEntityBasic {...props} dataStoreName={DataStoreName.fields} />;
}

export function ToggleCommonEntity({ ...props }: Omit<ToggleFieldEntityBasicProps, 'dataStoreName'>) {
  return <ToggleFieldEntityBasic {...props} dataStoreName={DataStoreName.common} />;
}

export function ToggleRelationEntity({ ...props }: Omit<ToggleFieldEntityBasicProps, 'dataStoreName'>) {
  return <ToggleFieldEntityBasic {...props} dataStoreName={DataStoreName.relations} />;
}
