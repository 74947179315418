import { Graph } from '../../types/drawio/draw';
import { MxUtils } from '../../types/drawio/mx';
import { BaseElementData } from '../../types/baseElementData';
import { findElementByEngineModelId } from './findElementByEngineModelId';
import { GraphElementType, systemToDrawioFieldMapping } from '../../const';
import { COMMON_PROPERTIES } from '@baseModel/basisModel/const';
import { Models } from '@baseModel/engine/types';
import { forEach } from 'lodash';

export function changeGraphElement(graph: Graph, mxUtils: MxUtils, element: BaseElementData) {
  const attrId = element.attributes[COMMON_PROPERTIES.id];
  if (!attrId) {
    console.error('element does not have ID', element);
    return;
  }
  const cell = findElementByEngineModelId(
    graph,
    mxUtils,
    attrId,
    element.modelType === Models.Entity || element.modelType === Models.EntityMetaModel
      ? GraphElementType.vertex
      : GraphElementType.edge
  );
  if (!cell) {
    return;
  }
  graph.model.beginUpdate();
  try {
    const userObject = cell.value;
    if (userObject && typeof userObject !== 'string') {
      const cloneObject = userObject.cloneNode(true) as Element;
      forEach(element.attributes, (attribute, name) => {
        cloneObject.setAttribute(systemToDrawioFieldMapping[name] ?? name, attribute);
      });
      graph.model.setValue(cell, cloneObject);
    } else {
      console.error('cell.value is not UserObject');
    }
  } catch (e) {
    console.error('Ошибка применения атрибутов', e);
  } finally {
    graph.model.endUpdate();
  }
}
