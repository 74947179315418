import { TableBlockColumnTypes } from '../types';

export const columnTypeItems = [
  {
    text: TableBlockColumnTypes.autonumber,
    value: TableBlockColumnTypes.autonumber,
    key: '1'
  },
  {
    text: 'Нет',
    value: '',
    key: '2'
  }
];
