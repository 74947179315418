import { Models } from '@baseModel/engine/types';
import { Engine } from '@baseModel/engine/engine';
import { getNameWithTag } from '../../../../utils/getNameWithTag';
import { getEntityFields } from './getEntityFields';

export const getValueItems = (
  engine: Engine,
  rowType?: string,
  modelType?: Models
): (
  | string
  | {
      name: string;
      items: string[];
    }
)[] => {
  if (!rowType) return [];
  if (modelType === Models.Entity) {
    const fields = engine.getMetaEntityByName(rowType).getFieldNames();
    return fields.map((f) => getNameWithTag(f));
  } else if (modelType === Models.Relation) {
    const metaRelation = engine.getMetaRelationByName(rowType);
    const fields = metaRelation.getFieldNames();
    const fieldItems = fields.map((f) => getNameWithTag(f));
    const relations = metaRelation.getRelationNames();

    const relationsItems = relations.map((f) => {
      return { name: getNameWithTag(f), items: getEntityFields(engine, f, rowType).map((el) => getNameWithTag(f, el)) };
    });
    return [...fieldItems, ...relationsItems];
  }
  return [];
};
