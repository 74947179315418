import React, { useEffect, useState } from 'react';
import { AnyObject } from '@baseModel/utils/dataJuggler';
import { BaseWidget, ConfigViewProps } from '@components/markdownEditor/dataDisplayWidgets/baseWidget/baseWidget';
import { closeConfigBlock } from '@components/markdownEditor/redux/markdownEditor';
import { useDispatch } from 'react-redux';

export interface ConfigProps<T extends AnyObject> extends Omit<ConfigViewProps<T>, 'initData'> {
  Component: NonNullable<ReturnType<BaseWidget<T>['getConfigView']>>;
  getInitConfig: BaseWidget<T>['getInitConfig'];
  blockId?: string;
}

export function Config<T extends AnyObject>({
  Component,
  getInitConfig,
  onSave: onSaveProp,
  engine,
  senderId,
  isNew,
  blockId
}: ConfigProps<T>): ReturnType<React.FC<ConfigViewProps<T>>> {
  const [initData, setInitData] = useState<T>();
  const dispatch = useDispatch();
  useEffect(() => {
    if (isNew) {
      getInitConfig()
        .then((data) => {
          setInitData(data);
        })
        .catch((error) => {
          console.log('Не удалось получить данные из getInitData:', error);
        });
    } else if (blockId) {
      const document = engine.getDocument();
      const block = document.getBlockById(blockId);
      const data = block?.getValue() as T | undefined;

      setInitData(data);
    }
  }, [blockId, engine, getInitConfig, isNew]);

  const onSave: ConfigViewProps<T>['onSave'] = async (data, closeConfigView = true) => {
    await onSaveProp(data);
    if (closeConfigView) {
      dispatch(closeConfigBlock());
    }
  };

  // TODO: Может сделать проп initData в компонент необязательным, чтобы показывать индикатор загрузке в компоненте
  //  и/или прокидывать ещё проп isLoadingData?
  if (initData === undefined) {
    return null;
  }

  return <Component initData={initData} onSave={onSave} engine={engine} isNew={isNew} senderId={senderId} />;
}
