import React, { Children, useEffect, useState } from 'react';
import { Button, ScreenDivider, ScreenDividerProps, ProgressIndicator, useSnackbar } from '@smwb/summer-ui';
import { YamlEditor } from '@components/yamlEditor/yamlEditor';
import { MarkdownEditor } from '@components/markdownEditor/markdownEditor';
import { Tabs } from '@components/basic/tabs/tabs';
import { MetaTableView } from '@components/metaTableView/metaTableView';
import { ConfirmDialog } from '@components/confirmDialog/confirmDialog';
import s from './document.module.less';
import { ModelToolbar } from '@components/modelToolbar/modelToolbar';
import { useSearchParams } from 'react-router-dom';
import { useEngineLoad } from '@baseModel/hooks/useEngineLoad';
import { useIsMobile } from '@hooks/useIsMobile';

const contents = [<YamlEditor />, <Preview />, <MetaTableView />];

const mobileTabs = [
  { label: 'Предпросмотр', content: contents[1] },
  { label: 'Метамодель', content: contents[2] },
  { label: 'YAML', content: contents[0] }
];

const desktopTabs = [mobileTabs[0], mobileTabs[1]];

export function Document() {
  const isMobile = useIsMobile();
  const [searchParams] = useSearchParams();
  const { addSnackbar } = useSnackbar();
  const [engineLoading, engineError] = useEngineLoad(false, searchParams.get('file') || undefined);

  useEffect(() => {
    if (engineError) {
      addSnackbar({ message: engineError, variant: 'danger', position: isMobile ? 'bottom' : 'top-right' });
    }
  }, [addSnackbar, engineError, isMobile]);

  return (
    <>
      {engineLoading && <ProgressIndicator className={s.progressBar} type="indeterminate" variant="linear" />}
      <ModelToolbar />
      {!isMobile && (
        <DocumentScreenDivider>
          {contents[0]}
          {<Tabs tabs={desktopTabs} />}
        </DocumentScreenDivider>
      )}
      {isMobile && <Tabs tabs={mobileTabs} />}
      <ConfirmDialog />
    </>
  );
}

function DocumentScreenDivider({ children, ...props }: ScreenDividerProps & { children: React.ReactNode }) {
  const [isHidden, setIsHidden] = useState(true);
  const child = Children.toArray(children);
  return (
    <ScreenDivider className={s.screen} sizeFirst={isHidden ? 0.5 : 35} {...props}>
      <>
        <div className={s.toggleButton}>
          <Button icon={isHidden ? 'arrow_forward' : 'arrow_back'} onClick={() => setIsHidden((state) => !state)} />
        </div>
        {!isHidden && child[0]}
      </>
      {child[1]}
    </ScreenDivider>
  );
}

function Preview() {
  return (
    <>
      <MarkdownEditor />
      {/*<TableConfiguration />*/}
    </>
  );
}
