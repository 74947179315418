import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableRow } from '@smwb/summer-ui/dist/components/dataDisplay/table';
import { ColumnActionsCell } from '../columnActionsCell';
import style from './columnsPreview.module.less';
import { EditColumnStepper } from './editColumnStepper';
import { Models } from '@baseModel/engine/types';
import { Button, Icon } from '@smwb/summer-ui';
import { confirmDialog } from '@components/confirmDialog/confirmDialog';
import { Engine } from '@baseModel/engine/engine';
import { EntityMetaModel } from '@baseModel/metaModel/entityMetaModel';
import { RelationMetaModel } from '@baseModel/metaModel/relationMetaModel';
import { TableBlockColumn } from '../../../types';
import { getNameWithTag } from '../../../utils/getNameWithTag';

const engine = Engine.getInstance();

export interface TablePreviewProps {
  columns: TableBlockColumn[];
  setColumns: (tableColumns: TableBlockColumn[]) => void;
  modelType: Models.Entity | Models.Relation;
  rowType: string;
}

const tableRows: Array<keyof TableBlockColumn> = ['displayName', 'name', 'type', 'value'];

export function ColumnsPreview({ columns, setColumns, rowType, modelType }: TablePreviewProps) {
  const [activeColumnIdx, setActiveColumnIdx] = useState<number | undefined>();
  const handleAddColumn = () => {
    setColumns([...columns, { value: '', displayName: '', name: '' }]);
    setActiveColumnIdx(() => columns.length);
  };

  /**
   * Добавим все филды, которых еще нет
   */
  const handleAddAllFields = () => {
    let meta: EntityMetaModel | RelationMetaModel | undefined;
    if (modelType === Models.Entity) {
      meta = engine.getMetaEntityByName(rowType);
    } else {
      meta = engine.getMetaRelationByName(rowType);
    }
    if (meta === undefined) {
      return;
    }
    const fields = meta.getFieldNames();
    const newColumns: TableBlockColumn[] = fields
      .filter((fieldName) => !columns.find((column) => column.value === getNameWithTag(fieldName)))
      .map((fieldName) => {
        return {
          value: getNameWithTag(fieldName)
        };
      });
    if (newColumns.length) {
      setColumns([...columns, ...newColumns]);
    }
  };

  const handleCloseStepper = () => {
    columns.pop();
    setColumns([...columns]);
    setActiveColumnIdx(undefined);
  };

  const handleSaveCol = (editColumn: TableBlockColumn, activeColumnIdx: number) => {
    setActiveColumnIdx(undefined);
    setColumns(columns.map((column, index) => (index === activeColumnIdx ? editColumn : column)));
  };

  const handleDeleteColumn = (id: number) => {
    confirmDialog({
      message: 'Безвозвратное удаление. Вы действительно хотите удалить колонку?',
      accept: () => {
        setColumns(columns.filter((c, i) => i !== id));
      }
    });
  };

  return (
    <>
      <div className={style.addCol}>
        <div className={style.addCol}>
          <Button className={style.addButton} type="button" onClick={handleAddColumn} disabled={!modelType || !rowType}>
            <Icon name="add" />
          </Button>
          Добавить колонку
        </div>
        <div className={style.addCol}>
          <Button
            className={style.addButton}
            type="button"
            onClick={handleAddAllFields}
            disabled={!modelType || !rowType}
          >
            <Icon name="playlist_add" />
          </Button>
          Добавить все поля
        </div>
      </div>
      {activeColumnIdx !== undefined && columns[activeColumnIdx] && (
        <div className={style.stepper}>
          <EditColumnStepper
            activeColumnIdx={activeColumnIdx}
            onSubmit={handleSaveCol}
            modelType={modelType}
            currentColumn={columns[activeColumnIdx]}
            rowType={rowType}
            onClose={handleCloseStepper}
          />
        </div>
      )}
      <div onKeyDown={(e) => e.stopPropagation()}>
        <Table>
          <TableBody>
            {tableRows.map((r, idx) => (
              <TableRow key={idx}>
                <TableCell>{r}</TableCell>
                {columns.map((c) => {
                  return <TableCell key={c.value}>{c[r]}</TableCell>;
                })}
              </TableRow>
            ))}

            <TableRow>
              <TableCell></TableCell>
              {columns.map((column, index) => (
                <TableCell key={column.value}>
                  <ColumnActionsCell
                    onDelete={() => handleDeleteColumn(index)}
                    onEdit={() => setActiveColumnIdx(index)}
                  />
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </>
  );
}
