import { REFLECTION_TAG } from '../../consts';
import { Entity } from '@baseModel/model/entity';
import { Relation } from '@baseModel/model/relation';
import { TableBlockColumn, TableBlockColumnTypes } from '../../types';

export function getColumnValue(model: Entity | Relation, column: TableBlockColumn, rowIndex?: number) {
  if (column.type === TableBlockColumnTypes.autonumber) {
    if (rowIndex === undefined) {
      return -1;
    }
    return rowIndex + 1;
  }

  const pathStr = column.value;
  if (!pathStr) {
    return 'empty value';
  }
  const path = pathStr.split('.');
  if (path[0] !== REFLECTION_TAG) {
    return pathStr;
  }
  try {
    // TODO добавить текущую модель?? для релейшен не понимает где брать связанные значения
    return model.getValueRefByPath(path.splice(1).join('.'));
  } catch (e) {
    console.error(e);
    return 'Не найдено';
  }
}
