import { useEffect } from 'react';
import { createSidebar } from '../index';
import { DRAWIO } from '../../../types/drawioGlobal';
import { Engine } from '@baseModel/engine/engine';

export function useSidebar(engine: Engine, drawio?: DRAWIO) {
  useEffect(() => {
    if (drawio?.ui.sidebar) {
      createSidebar(drawio, engine);
    }
  }, [drawio, engine]);
}
