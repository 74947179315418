import { MxRootChange } from './mxRootChange';
import { MxStyleChange } from './mxStyleChange';
import { MxValueChange } from './mxValueChange';
import { MxCell } from './mxCell';
import { MxChildChange } from './mxChildChange';
import { MxTerminalChange } from './mxTerminalChange';

export enum MxEvent {
  CHANGE = 'change',
  CELLS_ADDED = 'cellsAdded',
  CELLS_REMOVED = 'cellsRemoved',
  ADD_CELLS = 'addCells',
  CELL_CONNECTED = 'cellConnected'
}

export enum MxEventDataProperty {
  changes = 'changes',
  edit = 'edit'
}

export type MxEventDataPropertyResult = {
  [MxEventDataProperty.changes]: MxEventsChange[];
  [MxEventDataProperty.edit]: { changes: MxEventsChange[] };
};

export type MxEventsChange = MxRootChange | MxStyleChange | MxValueChange | MxChildChange | MxTerminalChange;

export interface MxEventsData {
  readonly getProperty: <K extends MxEventDataProperty>(type: K) => MxEventDataPropertyResult[K];
}

export enum MxEventObjectProperty {
  cells = 'cells',
  edge = 'edge',
  source = 'source'
}

export type MxEventObjectPropertyResult = {
  [MxEventObjectProperty.cells]: MxCell[] | MxCell;
  [MxEventObjectProperty.edge]: MxCell[] | MxCell;
  [MxEventObjectProperty.source]: string;
};

export interface MxEventObject {
  readonly getProperty: <K extends MxEventObjectProperty>(type: K) => MxEventObjectPropertyResult[K];
}

export enum GraphEvents {
  cellsInserted = 'cellsInserted'
}
