import { MxCell, MxCellClass } from '../../../../types/drawio/mx/mxCell';
import { MxGeometryClass } from '../../../../types/drawio/mx/mxGeometry';
import { Graph } from '../../../../types/drawio/draw';
import { forEach } from 'lodash';
import { vertexCellDefaultStyle } from '../../../../const';

export function createElementVertexCell(
  graph: Graph,
  mxCell: MxCellClass,
  mxGeometry: MxGeometryClass,
  attributes: Record<string, string>,
  style?: string
): MxCell {
  const cell = new mxCell('', new mxGeometry(0, 0, 80, 80), style ?? vertexCellDefaultStyle);
  cell.vertex = true;

  forEach(attributes, (attribute, name) => {
    graph.setAttributeForCell(cell, name, attribute);
  });

  return cell;
}
