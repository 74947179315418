import { stringify } from 'yaml';
import set from 'lodash/set';
import kebabCase from 'lodash/kebabCase';
import isEmpty from 'lodash/isEmpty';
import { Engine } from '@baseModel/engine/engine';
import { BlockType } from '@baseModel/document/blocks/types';
import { AnyObject } from '@baseModel/utils/dataJuggler';
import { JsonDocumentBlock } from '@components/markdownEditor/dataDisplayWidgets/baseWidget/block/block';
import { CustomWidgetsHandler } from '@components/markdownEditor/dataDisplayWidgets';

export type ToYAMLModifyJson = (json: AnyObject) => AnyObject;

export function toYAML(data: ReturnType<Engine['toJSON']>, modifyJson?: ToYAMLModifyJson): string {
  const metaModelEntities: AnyObject = {};
  const metaModelRelations: AnyObject = {};
  const modelEntities: AnyObject[] = [];
  const modelRelations: AnyObject[] = [];
  const document: AnyObject[] = [];

  for (const entityMetaModel of data.entityMetaModels) {
    const { name } = entityMetaModel;

    for (const commonKey in entityMetaModel.common) {
      set(metaModelEntities, [name, kebabCase(commonKey)], entityMetaModel.common[commonKey]);
    }

    if (!isEmpty(entityMetaModel.fields)) {
      set(metaModelEntities, [name, 'fields'], entityMetaModel.fields);
    }
  }

  for (const relationMetaModel of data.relationMetaModels) {
    const { name } = relationMetaModel;

    for (const commonKey in relationMetaModel.common) {
      set(metaModelRelations, [name, kebabCase(commonKey)], relationMetaModel.common[commonKey]);
    }

    if (!isEmpty(relationMetaModel.relations)) {
      set(metaModelRelations, [name, 'ends'], relationMetaModel.relations);
    }

    if (!isEmpty(relationMetaModel.fields)) {
      set(metaModelRelations, [name, 'fields'], relationMetaModel.fields);
    }
  }

  for (const entity of data.entities) {
    modelEntities.push(entity.fields);
  }

  for (const relation of data.relations) {
    modelRelations.push({
      type: relation.metaModelName,
      ends: relation.relations,
      fields: relation.fields
    });
  }

  for (const documentElement of data.document) {
    switch (documentElement.type) {
      case BlockType.markdown: {
        document.push({
          type: documentElement.type,
          id: documentElement.id,
          value: documentElement.value
        });

        break;
      }
      default: {
        const value = documentElement.value as unknown as JsonDocumentBlock['config'];
        const Widget = CustomWidgetsHandler.registeredWidgets.get(documentElement.type);

        if (Widget) {
          const widget = new Widget();
          const Block = widget.getBlock();
          const yamlJson = Block.getValueInJSONForYAML({
            id: documentElement.id,
            type: documentElement.type,
            config: value
          });

          document.push(yamlJson);
        } else {
          console.log(`Неизвестный тип блока: ${value.type}`);
        }
      }
    }
  }

  let json: AnyObject = {};

  set(json, 'version', data.version);
  set(json, 'metamodel.entities', metaModelEntities);
  set(json, 'metamodel.relations', metaModelRelations);
  set(json, 'model.entities', modelEntities);
  set(json, 'model.relations', modelRelations);
  set(json, 'document', document);

  if (modifyJson) {
    json = modifyJson(json);
  }

  return stringify(json);
}
