import { useEffect, useState } from 'react';
import { ProseMirrorView } from './proseMirrorView';
import { ExternalNodesView } from './externalNodesView';
import { Engine } from '@baseModel/engine/engine';
import { baseModelSubscription } from './modelBind/baseModelSubscription';
import { ExternalNodeAttributes } from '@components/markdownEditor/utils/markdownSchemaWithExtraNodes';
import { MENU_ATTRIBUTE_ID } from '@components/markdownEditor/plugins/topMenuView';
import { TopMenu } from '@components/markdownEditor/topMenu/topMenu';
import { markdownSchemaWithExtraNodes } from './utils/markdownSchemaWithExtraNodes';
import './markdownEditor.less';
import { useTypedSelector } from '../../redux/types';
import { blockHighlightPluginKey } from '@components/markdownEditor/plugins/blockHighlight';
import { scrollToBlockAndHighlight } from '@components/markdownEditor/utils/scrollToBlock';
import { ContentList } from '@components/markdownEditor/contentList/contentList';

const engine = Engine.getInstance();
export const MD_EDITOR_ID = 'MATRESHKA_MARKDOWN_EDITOR';

export function MarkdownEditor() {
  const [mountRef, setMountRef] = useState<HTMLDivElement | null>(null);
  const [menuNodes, setMenuNodes] = useState<NodeListOf<HTMLDivElement> | null>(null);
  const [externalNodes, setExternalNodes] = useState<NodeListOf<HTMLDivElement> | null>(null);
  const [proseMirrorView, setProseMirrorView] = useState<ProseMirrorView | null>(null);
  useEffect(() => {
    if (!mountRef) {
      return;
    }
    const document = engine.getDocument();
    const content = document.getMarkdown();
    const proseMirror = new ProseMirrorView(mountRef, content);
    setProseMirrorView(() => proseMirror);
    setExternalNodes(mountRef.querySelectorAll(`[${ExternalNodeAttributes.externalNode}=true]`));
    setMenuNodes(mountRef.querySelectorAll(`[${MENU_ATTRIBUTE_ID}=true]`));
    const unsubscribe = baseModelSubscription(proseMirror, () => {
      setExternalNodes(mountRef.querySelectorAll(`[${ExternalNodeAttributes.externalNode}=true]`));
    });

    return () => {
      proseMirror.destroy();
      unsubscribe();
    };
  }, [mountRef]);

  const highlightedDocumentBlockId = useTypedSelector(
    (state) => state.app.markdownEditor.actions.highlightedDocumentBlockId
  );
  // Если будут проблемы с ререндерами, можно вынести в отдельный дочерний компонент
  useEffect(() => {
    if (!proseMirrorView || !proseMirrorView.getState().doc.content.size) {
      return;
    }
    if (!highlightedDocumentBlockId) {
      const tr = proseMirrorView.getState().tr.setMeta(blockHighlightPluginKey, { removeAll: true });
      proseMirrorView.getDispatch()(tr);
      return;
    }
    scrollToBlockAndHighlight(proseMirrorView.getEditor(), highlightedDocumentBlockId);
  }, [proseMirrorView, highlightedDocumentBlockId]);

  useEffect(() => {
    if (!externalNodes || !mountRef) {
      return;
    }
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList' && mutation.removedNodes.length > 0) {
          // TODO мы можем искать конкретный div, который удалили, но пока так
          const removedExternalNodes = Array.from(mutation.removedNodes).filter(
            (node): node is Element =>
              node.nodeType === Node.ELEMENT_NODE &&
              (node as Element).matches(`[${ExternalNodeAttributes.externalNode}=true]`)
          );
          if (removedExternalNodes.length) {
            setExternalNodes(mountRef.querySelectorAll(`[${ExternalNodeAttributes.externalNode}=true]`));
          }
        }
      }
    });
    externalNodes.forEach((div) => {
      if (div.parentNode && div.parentNode.parentNode) {
        observer.observe(div.parentNode.parentNode, { childList: true });
      }
    });
    return () => observer.disconnect();
  }, [externalNodes, mountRef]);

  return (
    <div className="wrapper">
      <div id={MD_EDITOR_ID} ref={setMountRef} className="editor" />
      <ExternalNodesView externalNodes={externalNodes} />
      <TopMenu menuNodes={menuNodes} proseMirrorView={proseMirrorView} schema={markdownSchemaWithExtraNodes} />
      <ContentList proseMirror={proseMirrorView} />
    </div>
  );
}
