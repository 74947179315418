import { ActionType } from '@baseModel/components/modelForm/types';
import { EntityMetaModel } from '@baseModel/metaModel/entityMetaModel';
import { Entity } from '@baseModel/model/entity';
import { Relation } from '@baseModel/model/relation';
import { RelationMetaModel } from '@baseModel/metaModel/relationMetaModel';

export function getModel(
  actionType: ActionType,
  metaModel?: EntityMetaModel | RelationMetaModel,
  actionModel?: Entity | Relation
): Entity | Relation | undefined {
  if (!metaModel) {
    console.error(`metaModel is undefined`);
    return;
  }
  if (actionType === ActionType.Update && actionModel) {
    return actionModel;
  } else if (actionType === ActionType.Add) {
    if (metaModel instanceof EntityMetaModel) {
      return new Entity(metaModel);
    } else {
      return new Relation(metaModel);
    }
  } else {
    return;
  }
}
