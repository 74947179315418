import { EmptyObject } from '@baseModel/utils/dataJuggler';
import debug, { Debugger } from 'debug';
import snakeCase from 'lodash/snakeCase';

export type Sender = string | EmptyObject;
export type Listener<T> = (value: T, sender?: Sender) => void;
export type Unsubscriber = () => void;

export class Observable<T> {
  protected readonly logger: Debugger;
  private readonly MAX_SUBSCRIBER_COUNT = 200;
  private listeners: Listener<T>[] = [];

  constructor(public readonly name: string, private _value?: T) {
    this.logger = debug(`${snakeCase(this.constructor.name)}:${snakeCase(name)}`);
    this.logger('created');
  }

  /**
   *
   * @param value
   * @param sender - if sender is set, the listener of sender will not be called
   */
  public setValue(value: T, sender?: Sender) {
    if (this.value === value) {
      return;
    }
    this._value = value;
    for (const listener of this.listeners) {
      listener(value, sender);
    }
  }

  public get value(): T | undefined {
    return this._value;
  }

  public subscribe(listener: Listener<T>, sender?: Sender): Unsubscriber {
    if (this.getListenersCount() > this.MAX_SUBSCRIBER_COUNT) {
      console.warn(`subscriber count ${this.name} ${this.getListenersCount()} more than ${this.MAX_SUBSCRIBER_COUNT}`);
    }
    const _listener = (value: T, subscriberId?: Sender) => {
      if (!subscriberId || !sender || subscriberId !== sender) {
        listener(value, subscriberId);
      }
    };
    this.listeners.push(_listener);
    this.logger(
      `new subscriber for ${this.name || 'name not set'} sender ${
        sender || 'not set'
      }, total is ${this.getListenersCount()}`
    );
    return () => {
      this.listeners = this.listeners.filter((l) => l !== _listener);
      this.logger(
        `remove subscriber for ${this.name || 'name not set'} sender ${
          sender || 'not set'
        }, total is ${this.getListenersCount()}`
      );
    };
  }

  public unsubscribeAll(): void {
    this.listeners = [];
  }

  public getListenersCount() {
    return this.listeners.length;
  }
}
