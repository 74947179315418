import { Engine } from '../engine/engine';
import { Models } from '../engine/types';
import { DataJuggler, DataStoreName, EmptyObject, JSONSerialize } from '../utils/dataJuggler';
import { JsonField } from '../types/jsonDescription';
import { Listener, Unsubscriber } from '@utils/observable';
import { ModelError } from '../errors';
import { RELATION_PATH_PART_DIVIDER } from '@baseModel/model/const';

export interface JSONSerializeMetaModel<R> extends JSONSerialize<JsonField, string, R> {
  name: string;
  idFieldName?: string;
}

export abstract class MetaModel<R = never> extends DataJuggler<JsonField, string, R> {
  public abstract readonly modelType: Models;
  private displayName: string | undefined;
  protected engine: Engine | undefined;

  constructor(private readonly name: string) {
    super(name);
  }

  /**
   * Свяжем модель с engine
   * @param engine
   */
  public linkToEngine(engine: Engine) {
    if (this.engine) {
      throw new ModelError(`Модель уже добавлена в engine`);
    }
    this.engine = engine;
    this.validateData();
  }

  public getName() {
    return this.name;
  }

  public getDisplayName() {
    return this.displayName;
  }

  public setDisplayName(displayName: string) {
    this.displayName = displayName;
    this.setCommonValue('displayName', displayName);
  }

  public getIdFieldName() {
    const fieldNames = this.getFieldNames();
    for (const fieldName of fieldNames) {
      if (this.getFieldValue(fieldName)?.['primary-key'] === true) {
        return fieldName;
      }
    }
  }

  public override validateData() {
    super.validateData();
    if (!this.getIdFieldName()) {
      throw new ModelError('Не установлено primary-key');
    }
  }

  public deleteMe() {
    // TODO remove all listeners
    this.logger('delete me');
  }

  public getValueRefByPath(pathStr: string): {
    getter: () => JsonField | string | R | undefined;
    subscribe: (
      listener: Listener<JsonField | undefined> | Listener<string | undefined> | Listener<R | undefined>,
      sender?: string | EmptyObject
    ) => Unsubscriber;
  } {
    if (!pathStr) {
      throw new ModelError(`path is empty`);
    }
    const path = pathStr.split('.');
    const pathPart = path[0];
    const isFieldName = pathPart !== RELATION_PATH_PART_DIVIDER && this.getFieldNames().find((el) => pathPart === el);
    if (isFieldName) {
      if (path.length > 2) {
        console.error('нашли в field, но путь длиннее ' + pathStr);
      }
      return {
        getter: this.getFieldValue.bind(this, pathPart),
        subscribe: this.subscribeData.bind(this, DataStoreName.fields, pathPart)
      };
    }
    const isRelationName = pathPart === RELATION_PATH_PART_DIVIDER;
    if (isRelationName) {
      if (path.length > 3) {
        console.error('нашли в relation, но путь длиннее ' + pathStr);
      }
      return {
        getter: this.getRelationValue.bind(this, path[1]),
        subscribe: this.subscribeData.bind(this, DataStoreName.relations, path[1])
      };
    }

    const isCommonName = this.getCommonNames().find((el) => pathPart === el);
    if (isCommonName) {
      if (path.length > 2) {
        console.error('нашли в common, но путь длиннее ' + pathStr);
      }
      return {
        getter: this.getCommonValue.bind(this, pathPart),
        subscribe: this.subscribeData.bind(this, DataStoreName.common, pathPart)
      };
    }
    throw new ModelError(`имя не найдено для пути ` + pathStr);
  }

  public override toJSON(): JSONSerializeMetaModel<R> {
    const jugglerSerialize = super.toJSON();
    return {
      ...jugglerSerialize,
      name: this.getName(),
      idFieldName: this.getIdFieldName()
    };
  }
}
