import { Models } from '@baseModel/engine/types';
import { SelectFieldProps } from '@smwb/summer-ui';
import { Engine } from '@baseModel/engine/engine';

export const getRowTypeItems = (modelType?: Models): SelectFieldProps['items'] => {
  const engine = Engine.getInstance();
  if (!modelType) return [];
  if (modelType === Models.Entity) {
    const entityNames = engine.getEntityMetaModelsNames();
    return entityNames.map((e, idx) => ({ key: idx, text: e, value: e }));
  } else {
    const relationNames = engine.getRelationMetaModelsNames();
    return relationNames.map((e, idx) => ({ key: idx, text: e, value: e }));
  }
};
export const modelTypes = [
  { key: '1', text: Models.Entity, value: Models.Entity },
  { key: '2', text: Models.Relation, value: Models.Relation }
];
