import { AvailableBlock } from '@baseModel/document/blocks/types';
import { Markdown } from '@baseModel/document/blocks/markdown';
import { EDITOR_SENDER } from '@components/yamlEditor/const';
import { EventEditorDocumentObserver } from '@components/yamlEditor/plugins/yamlBind/types';
import { Engine } from '@baseModel/engine/engine';
import { JsonDocumentMarkdownBlock } from '@baseModel/types/jsonDescription';
import { blockMapping } from '@baseModel/document/document';
import { JsonDocumentBlock } from '@components/markdownEditor/dataDisplayWidgets/baseWidget/block/block';
import { CustomWidgetsHandler } from '@components/markdownEditor/dataDisplayWidgets';

const engine = Engine.getInstance();

export function editorDocumentBlockListener(event: EventEditorDocumentObserver) {
  const {
    changes: { after },
    meta
  } = event;

  const document = engine.getDocument();
  const blocks = document.getBlocks();
  const hasBlock = blocks.find((block) => meta.id === block.getId());

  if (hasBlock && meta.deleted) {
    document.removeBlockById(hasBlock.type, meta.id, EDITOR_SENDER);
    return;
  }

  const isAdd = !hasBlock && after !== undefined;
  let block: AvailableBlock | undefined;

  if (isAdd) {
    if (!after?.type) {
      console.error('type не указан');
      return;
    }

    const newBlockClass = blockMapping[after.type];
    const Widget = CustomWidgetsHandler.registeredWidgets.get(after.type);

    if (newBlockClass === undefined && Widget === undefined) {
      console.error(`block с типом ${after.type} не найден`);
      return;
    }

    if (newBlockClass) {
      block = new newBlockClass(meta.id);
    } else {
      if (Widget) {
        const widget = new Widget();
        const WidgetBlock = widget.getBlock();

        block = new WidgetBlock(widget.valueSchema, widget.getType(), meta.id);
      }
    }
  } else {
    block = hasBlock;
  }

  // Только изменили сортировку
  if (block && !after && !isAdd && meta.changedSortIndex !== undefined) {
    document.setBlockSortIndex(meta.id, meta.changedSortIndex, EDITOR_SENDER);
    return;
  }

  if (block instanceof Markdown) {
    block.setValue(Markdown.getValueFromJSON(<JsonDocumentMarkdownBlock>after || ''), EDITOR_SENDER);
  } else if (block) {
    const Widget = CustomWidgetsHandler.registeredWidgets.get(block.type);
    if (Widget) {
      const widget = new Widget();
      const Block = widget.getBlock();
      block.setValue(Block.getConfigFromJSON(<JsonDocumentBlock>(<unknown>after) || ''), EDITOR_SENDER);
    } else {
      console.error('Widget type', block.type, 'not found');
    }
  }

  if (isAdd && block) {
    document.addBlock(block, meta.changedSortIndex, EDITOR_SENDER);
    return;
  }
}
