import { TableBlockShowLink } from '../types';
import { Engine } from '@baseModel/engine/engine';

export function getHierarchyLinkTitle(engine: Engine, link?: TableBlockShowLink): string {
  if (!link) {
    return '';
  }
  const relationDisplayName = engine.getMetaRelationByName(link.relation).getDisplayName() ?? link.relation;
  return `Связь: ${link.endSelf} <- ${relationDisplayName}  ${link.endTarget ? `-> ${link.endSelf}` : ''}`;
}
