import { ComparisonOperator, LogicalCondition } from '@baseModel/engine/queryBuilder/types';
import { SimpleValueType } from '@baseModel/types/simpleValueType';

export function matchesConditionValue(value?: SimpleValueType, logicalCondition?: LogicalCondition): boolean {
  for (const operator in logicalCondition) {
    const operand = logicalCondition[operator as ComparisonOperator];
    switch (operator as ComparisonOperator) {
      case ComparisonOperator.$eq:
        if (value !== operand) return false;
        break;
      case ComparisonOperator.$ne:
        if (value === operand) return false;
        break;
      case ComparisonOperator.$in:
        if (!Array.isArray(operand) || !operand.some((el) => el === value)) return false;
        break;
      default:
        return false;
    }
  }
  return true;
}
