import { SidebarElement } from '../index';
import { Models } from '@baseModel/engine/types';
import { SystemDrawioAttributes, systemToDrawioFieldMapping } from '../../../const';
import { Engine } from '@baseModel/engine/engine';

export function convertModelToSidebarElement(
  engine: Engine,
  modelId: string,
  modelType: Models.Entity | Models.Relation
): SidebarElement {
  const model = modelType === Models.Entity ? engine.getEntityById(modelId) : engine.getRelationById(modelId);
  const attributes: Record<string, string> = {
    [SystemDrawioAttributes.modelType]: modelType
  };
  for (const [fieldName, fieldValue] of model.getFieldIterator()) {
    attributes[systemToDrawioFieldMapping[fieldName] ?? fieldName] = fieldValue.toString();
  }
  return {
    style: model.getMetaModel().getCommonValue('style'),
    label: attributes[SystemDrawioAttributes.label] ?? modelId,
    group: model.getMetaModel().getName(),
    attributes
  };
}
