export const COMMON_PROPERTIES = {
  // название будущей метамодели
  commonName: '__name',
  id: '__id',
  type: '__type'
};
export const ENTITY_MODEL = {
  // имя entity метамодели
  name: 'entity',
  // имя relation метамодели связи модели с ее полями
  fieldRelation: 'entity-field',
  // Связь поля с entity
  fieldEndName: 'entity',
  displayName: 'display-name',
  description: 'description',
  ...COMMON_PROPERTIES
};
export const RELATION_MODEL = {
  // имя entity метамодели
  name: 'relation',
  // имя relation метамодели связи модели с ее полями
  fieldRelation: 'relation-field',
  fieldEndName: 'relation',
  // имя relation метамодели связи модели с ее связями
  entityRelation: 'entity-relation',
  relationEndName: 'relation',
  entityEndName: 'entity',
  displayName: 'display-name',
  description: 'description',
  ...COMMON_PROPERTIES
};
export const FIELD_MODEL = {
  name: 'field',
  fieldEndName: 'field'
};
