import { SidebarElement } from '../index';
import { Models } from '@baseModel/engine/types';
import { SystemDrawioAttributes, systemToDrawioFieldMapping } from '../../../const';
import { COMMON_PROPERTIES } from '@baseModel/basisModel/const';

export function convertMetaModelToSidebarElement(
  modelName: string,
  modelType: Models.EntityMetaModel | Models.RelationMetaModel
): SidebarElement {
  return {
    label: modelName,
    attributes: {
      [systemToDrawioFieldMapping[COMMON_PROPERTIES.commonName]]: modelName,
      [COMMON_PROPERTIES.type]: modelType,
      [SystemDrawioAttributes.modelType]: modelType
    }
  };
}
