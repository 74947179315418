import { MxCell, MxCellClass } from '../../../../types/drawio/mx/mxCell';
import { createElementEdgeCell } from './createElementEdgeCell';
import { Graph, Sidebar } from '../../../../types/drawio/draw';
import { MxGeometryClass, MxPointClass } from '../../../../types/drawio/mx';

export function createSidebarElementEdge(
  sidebar: Sidebar,
  graph: Graph,
  mxCell: MxCellClass,
  mxPoint: MxPointClass,
  mxGeometry: MxGeometryClass,
  attributes: Record<string, string>,
  uuid: string,
  style?: string
): { node: HTMLElement; cell: MxCell } {
  const edge = createElementEdgeCell(graph, mxCell, mxPoint, mxGeometry, attributes, style);
  edge.uuid = uuid;
  const node = sidebar.createEdgeTemplateFromCells([edge], 100, 0, '');
  return { node, cell: edge };
}
