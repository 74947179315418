import { TableBlockColumn } from '../../types';
import { Models } from '@baseModel/engine/types';
import { Engine } from '@baseModel/engine/engine';
import { getColumnHeader } from './getColumnHeader';

export function getColumnName(
  engine: Engine,
  modelType: Models.Entity | Models.Relation,
  rowType: string,
  column: TableBlockColumn
): string {
  if (column.name) {
    return column.name;
  }
  const isEntity = modelType === Models.Entity;
  const metaModel = isEntity ? engine.getMetaEntityByName(rowType) : engine.getMetaRelationByName(rowType);
  return getColumnHeader(metaModel, column);
}
