import { Models } from '@baseModel/engine/types';

export enum EventNames {
  change,
  add,
  delete
}

export interface HandledChangesBase {
  event: EventNames;
  engineModelType: Models;
  newAttributes: Record<string, string>;
}

export interface HandledAttributesChanges extends HandledChangesBase {
  oldAttributes: Record<string, string>;
  event: EventNames.change;
  engineModelId: string;
}

export interface HandledModelsAdd extends HandledChangesBase {
  event: EventNames.add;
  mxId: string;
}

export interface HandledModelsDelete extends Omit<HandledChangesBase, 'newAttributes'> {
  event: EventNames.delete;
  engineModelId: string;
}

export interface HandledModelsConnection {
  mxEdgeId: string;
  relationModelId?: string;
  isTarget: boolean;
  isConnected: boolean;
  entityModelId: string | undefined;
}

export interface HandledModelsConnected extends HandledModelsConnection {
  isConnected: true;
}

export interface HandledModelsDisconnected extends HandledModelsConnection {
  isConnected: false;
  entityModelId: undefined;
}

export interface ChangesListener {
  (ev: { changes: HandledAttributesChanges[] }): void;
}

export interface ChangesWithBase64Listener {
  (ev: { changes: HandledAttributesChanges[]; base64: string }): void;
}

export interface CellsAddListener {
  (ev: { changes: HandledModelsAdd[]; base64: string }): void;
}

export interface CellsDeletedListener {
  (ev: { changes: HandledModelsDelete[]; base64: string }): void;
}

export interface ConnectListener {
  (ev: { changes: (HandledModelsConnected | HandledModelsDisconnected)[] }): void;
}
