import { useRef } from 'react';
import isEqual from 'fast-deep-equal';
import cloneDeep from 'lodash/cloneDeep';

export function useDeepMemo<T>(value: T): T {
  const ref = useRef<T>(value);
  if (!isEqual(value, ref.current)) {
    ref.current = cloneDeep(value);
  }

  return ref.current;
}
