import { useEffect } from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import styles from '../../../../styles/index.less?raw';

export function useStyleConnect(iframeRef: HTMLIFrameElement | null) {
  useEffect(() => {
    if (iframeRef) {
      iframeRef.addEventListener('load', () => {
        const iframeDocument = iframeRef.contentDocument || iframeRef.contentWindow?.document;
        if (iframeDocument) {
          const styleElement = iframeDocument.createElement('style');
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          styleElement.appendChild(iframeDocument.createTextNode(styles));
          iframeDocument.head.appendChild(styleElement);

          const googleFontElement = iframeDocument.createElement('link');
          googleFontElement.rel = 'stylesheet';
          googleFontElement.href =
            'https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200&v=1669291999100';
          iframeDocument.head.appendChild(googleFontElement);
        }
      });
    }
  }, [iframeRef]);
}
