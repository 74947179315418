import { ProseMirrorView } from '../proseMirrorView';
import { Node } from 'prosemirror-model';
import { BlockType } from '@baseModel/document/blocks/types';
import { MARKDOWN_NEW_BLOCK_ID } from '@baseModel/document/document';

export interface BlockIdentify {
  id: string;
  type: BlockType;
}

export function getBlockIdInCursorPosition(proseMirror: ProseMirrorView): BlockIdentify {
  const state = proseMirror?.getState();
  const dispatch = proseMirror?.getDispatch();
  if (!state || !dispatch) {
    console.error('instance is empty');
    return {
      id: '0',
      type: BlockType.markdown
    };
  }
  const cursorPosition = state.selection.$anchor.pos;
  // ts упорно не верит, что переменная может быть установленна в цикле ниже
  let currentNode: Node | undefined = new Node();
  // let startPos = 0;
  state.doc.nodesBetween(0, cursorPosition, (node) => {
    if (node.attrs.id !== MARKDOWN_NEW_BLOCK_ID) {
      currentNode = node;
    }
    return false;
  });

  const type = BlockType.markdown;
  if (currentNode.type === undefined) {
    console.warn('Нода в позиции курсора не найдена');
    return {
      id: '0',
      type
    };
  }
  const id: string = (currentNode.attrs?.id as string) || '0';
  return {
    id,
    type
  };
}
