import { matchesConditionValue } from '@baseModel/engine/queryBuilder/matchesConditionValue';
import { ConditionValue, LogicalCondition } from '@baseModel/engine/queryBuilder/types';
import { JsonField, JsonSource } from '@baseModel/types/jsonDescription';
import { ANY_OF_FIELDS, SYSTEM_KEYS } from '@baseModel/engine/queryBuilder/const';
import { SimpleValueType } from '@baseModel/types/simpleValueType';

function getMatchForAnyMetaFields<T extends JsonSource | JsonField | undefined>(
  properties: Map<string, T>,
  condition: ConditionValue<T>
): boolean {
  for (const anyProperty of properties.values()) {
    for (const conditionKey in condition) {
      if (matchesConditionValue(anyProperty?.[conditionKey], condition[conditionKey])) {
        return true;
      }
    }
  }
  return false;
}

function getMatchForAnyModelFields(properties: Map<string, SimpleValueType>, condition: LogicalCondition): boolean {
  for (const anyProperty of properties.values()) {
    if (matchesConditionValue(anyProperty, condition)) {
      return true;
    }
  }
  return false;
}

export function matchesConditionMapMetaModels<T extends JsonSource | JsonField | undefined>(
  jugglerProperties: IterableIterator<[string, T]>,
  conditions: { [name: string]: ConditionValue<T> }
): boolean {
  const properties = new Map(jugglerProperties);

  // Этому условию должно соответствовать хотя бы одно любое поле
  const conditionForAnyField = conditions[ANY_OF_FIELDS];
  let conditionForAnyFieldAccepted = !conditionForAnyField;
  for (const conditionsKey in conditions) {
    const keyIsSystem = SYSTEM_KEYS.some((el) => el === conditionsKey);
    const condition = conditions[conditionsKey];
    const propertyValue = properties.get(conditionsKey);
    if (!propertyValue && !keyIsSystem && !matchesConditionValue(undefined, condition)) {
      return false;
    }

    if (keyIsSystem && !conditionForAnyFieldAccepted && conditionsKey === ANY_OF_FIELDS) {
      conditionForAnyFieldAccepted = getMatchForAnyMetaFields(properties, condition);
    }

    if (!keyIsSystem) {
      for (const conditionKey in condition) {
        if (!matchesConditionValue(propertyValue?.[conditionKey], condition[conditionKey])) {
          return false;
        }
      }
    }
  }

  return conditionForAnyFieldAccepted;
}

export function matchesConditionMapModels(
  jugglerProperties: IterableIterator<[string, SimpleValueType]>,
  conditions: { [name: string]: LogicalCondition }
): boolean {
  const properties = new Map(jugglerProperties);

  // Этому условию должно соответствовать хотя бы одно любое поле
  const conditionForAnyField = conditions[ANY_OF_FIELDS];
  let conditionForAnyFieldAccepted = !conditionForAnyField;
  for (const conditionsKey in conditions) {
    const keyIsSystem = SYSTEM_KEYS.some((el) => el === conditionsKey);
    const condition = conditions[conditionsKey];
    const propertyValue = properties.get(conditionsKey);
    if (!propertyValue && !keyIsSystem && !matchesConditionValue(undefined, condition)) {
      return false;
    }
    if (keyIsSystem && !conditionForAnyFieldAccepted && conditionsKey === ANY_OF_FIELDS) {
      conditionForAnyFieldAccepted = getMatchForAnyModelFields(properties, condition);
    }
    if (!keyIsSystem) {
      if (!matchesConditionValue(propertyValue, condition)) {
        return false;
      }
    }
  }

  return conditionForAnyFieldAccepted;
}
