import { EventListener } from '../types/drawio/draw';
import { MxEvent } from '../types/drawio/mx';
import { MxEventDataProperty, MxEventsData } from '../types/drawio/mx/mxEvent';
import { useEffect } from 'react';
import { DRAWIO } from '../types/drawioGlobal';
import { ConnectListener, HandledModelsConnected, HandledModelsDisconnected } from '../types/events';
import { getAttributes } from '../utils/drawio/getAttributes';
import { COMMON_PROPERTIES } from '@baseModel/basisModel/const';
import { isMxTerminalChange } from '../guards/isMxTerminalChange';

export function useSubscribeConnectModel(drawio: DRAWIO | undefined, listener: ConnectListener): void {
  useEffect(() => {
    if (!drawio) {
      return;
    }
    const {
      ui: {
        editor: { graph }
      }
    } = drawio;
    const model = graph.model;
    const drawioEventListener: EventListener<MxEventsData> = function (_, evt) {
      const changes = evt.getProperty(MxEventDataProperty.edit).changes.filter(isMxTerminalChange);
      const result: (HandledModelsConnected | HandledModelsDisconnected)[] = [];
      changes.forEach((change) => {
        const edge = change.cell; // Ребро, которое изменилось
        const terminal = change.terminal; // Новая вершина (терминал)
        const isSource = change.source; // true, если это источник, false - цель
        const edgeAttributes = getAttributes(edge);
        const relationModelId = edgeAttributes[COMMON_PROPERTIES.id];
        const isTarget = !isSource;
        const isConnected = !!terminal;
        if (isConnected && terminal?.id) {
          const vertex = model.getCell(terminal.id);
          const vertexAttributes = getAttributes(vertex);
          // Объекты непривязанные к модели не обрабатываем
          if (!vertexAttributes[COMMON_PROPERTIES.type]) {
            return;
          }
          const connection: HandledModelsConnected = {
            mxEdgeId: edge.getId(),
            relationModelId,
            isTarget,
            isConnected,
            entityModelId: vertexAttributes[COMMON_PROPERTIES.id]
          };
          result.push(connection);
        } else {
          const connection: HandledModelsDisconnected = {
            mxEdgeId: edge.getId(),
            relationModelId,
            isTarget,
            isConnected: false,
            entityModelId: undefined
          };
          result.push(connection);
        }
      });
      listener({ changes: result });
    };
    model.addListener(MxEvent.CHANGE, drawioEventListener);
    return () => {
      model.removeListener(MxEvent.CHANGE, drawioEventListener);
    };
  }, [drawio, listener]);
}
