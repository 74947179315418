import { SimpleValueType } from '@baseModel/types/simpleValueType';
import { JsonField } from '@baseModel/types/jsonDescription';

export function validationField(config: JsonField) {
  return (value: SimpleValueType) => {
    if (config.required && !value) {
      return 'Обязательное поле.';
    }

    if (config.type === 'integer' && value && !/^\d*$/.test(value.toString())) {
      return 'Поле должно быть числом.';
    }

    return undefined;
  };
}
