import React, { useState } from 'react';
import { Button, useSnackbar } from '@smwb/summer-ui';
import { GenerateMetaModel } from './generateMetaModel';
import { Engine } from '@baseModel/engine/engine';
import { EditorViewProps } from '@components/markdownEditor/dataDisplayWidgets/baseWidget/baseWidget';
import { WidgetValue } from './types';
import { useIsMobile } from '@hooks/useIsMobile';

export function GenerateMetaModelButton({ configData }: EditorViewProps<WidgetValue>) {
  const isMobile = useIsMobile();
  const { addSnackbar } = useSnackbar();
  const [inProgress, setInProgress] = useState(false);

  const generate = () => {
    setInProgress(true);
    new GenerateMetaModel(Engine.getInstance())
      .generate()
      .then((id: string) => {
        const url = window.location.href.replace(/\?file=.*$/, '') + '?file=' + id;
        window.open(url, '_blank');
      })
      .catch((e: Error) => {
        addSnackbar({
          message: e.message,
          position: isMobile ? 'bottom' : 'top-right',
          variant: 'danger'
        });
      })
      .finally(() => {
        setInProgress(false);
      });
  };
  return <Button disabled={inProgress} label={configData.title} onClick={generate} />;
}
