import { MxCell } from '../../types/drawio/mx/mxCell';
import { Model } from '../../types/drawio/draw';
import { forEach } from 'lodash';

export function setCellAttributes(model: Model, cell: MxCell, attributes: Record<string, string>) {
  if (cell && !!cell.value && typeof cell?.value !== 'string') {
    const cloneObject = cell.value.cloneNode(true) as Element;
    try {
      model.beginUpdate();
      forEach<Record<string, string>>(attributes, (attribute, name) => {
        cloneObject.setAttribute(name, attribute);
      });
      model.setValue(cell, cloneObject);
    } finally {
      model.endUpdate();
    }
  } else {
    console.error('cell is undefined', cell);
  }
}
