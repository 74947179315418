import React, { forwardRef } from 'react';
import { Toggle as SmwbToggle, ToggleProps } from '@smwb/summer-ui';

export const Toggle = forwardRef<HTMLInputElement, ToggleProps>(function Toggle(props, ref) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore Типы правильные, проблема в @smwb/summer-ui пакете
  return <SmwbToggle ref={ref} {...props} />;
});

export type { ToggleProps };
