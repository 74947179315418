import { MxCell, MxCellClass } from '../../../../types/drawio/mx/mxCell';
import { createElementVertexCell } from './createElementVertexCell';
import { Graph, Sidebar } from '../../../../types/drawio/draw';
import { MxGeometryClass } from '../../../../types/drawio/mx';

export function createSidebarElementVertex(
  sidebar: Sidebar,
  graph: Graph,
  mxCell: MxCellClass,
  mxGeometry: MxGeometryClass,
  attributes: Record<string, string>,
  uuid: string,
  style?: string
): { node: Element; cell: MxCell } {
  const cell = createElementVertexCell(graph, mxCell, mxGeometry, attributes, style);
  cell.uuid = uuid;
  const node = sidebar.createVertexTemplateFromCells([cell], 80, 80, '');
  return { node, cell };
}
