import { SimpleValueType } from './simpleValueType';

export type JsonEnds = Record<string, JsonSource>;
export type JsonFields = Record<string, JsonField>;

export interface JsonEntity {
  __type: string;
  __id: string;

  [name: string]: SimpleValueType;
}

export interface JsonRelation {
  type: string;
  ends: Record<string, number>;
  fields: Record<string, SimpleValueType>;
}

export interface JsonMetaEntity {
  'display-name': string;
  fields: JsonFields;
  style: string;
}

export interface JsonMetaRelation extends JsonMetaEntity {
  ends: JsonEnds;
}

export interface JsonMetaEntities {
  [name: string]: JsonMetaEntity;
}

export interface JsonMetaRelations {
  [name: string]: JsonMetaRelation;
}

export interface JsonField {
  type: string;
  'primary-key'?: boolean;
  required?: boolean;
  unique?: boolean;
  'display-name': string;
  description?: string;

  [name: string]: string | boolean | number | undefined;
}

export interface JsonSource {
  type: string;
  'display-name': string;
  target?: boolean;
  required?: boolean;
  description?: string;

  [name: string]: string | boolean | number | undefined;
}

export enum JsonDocumentBlockType {
  markdown = 'markdown'
}

export interface JsonDocumentMarkdownBlock {
  id: string;
  type: JsonDocumentBlockType.markdown;
  value: string | null;
}

export enum ModelType {
  entities = 'entities',
  relations = 'relations'
}

export type JsonDocumentBlocks = JsonDocumentMarkdownBlock;

export enum JsonRootBlock {
  version = 'version',
  metamodel = 'metamodel',
  model = 'model',
  document = 'document'
}

export interface JsonDescription {
  [JsonRootBlock.version]?: string;
  [JsonRootBlock.metamodel]: {
    [ModelType.entities]: JsonMetaEntities;
    [ModelType.relations]: JsonMetaRelations;
  };
  [JsonRootBlock.model]: {
    [ModelType.entities]: JsonEntity[];
    [ModelType.relations]: JsonRelation[];
  };
  [JsonRootBlock.document]: JsonDocumentBlocks[];
}
