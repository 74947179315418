import { EventListener } from '../types/drawio/draw';
import { MxEvent, MxUtils, MxValueChange } from '../types/drawio/mx';
import { MxEventDataProperty, MxEventsChange, MxEventsData } from '../types/drawio/mx/mxEvent';
import { useEffect } from 'react';
import { DRAWIO } from '../types/drawioGlobal';
import { ChangesListener, ChangesWithBase64Listener, EventNames, HandledAttributesChanges } from '../types/events';
import { getAttributes } from '../utils/drawio/getAttributes';
import { COMMON_PROPERTIES } from '@baseModel/basisModel/const';
import { getBase64 } from '../utils/drawio/getBase64';
import { SystemDrawioAttributes } from '../const';
import { Models } from '@baseModel/engine/types';

function handleAttributeChange(
  previousValue: Node | null,
  newValue: Node | null
): HandledAttributesChanges | undefined {
  const previousAttributes = !previousValue ? {} : getAttributes(previousValue);
  const newAttributes = !newValue ? {} : getAttributes(newValue);
  const modelId = newAttributes[COMMON_PROPERTIES.id];
  const modelIdOldValue = previousAttributes[COMMON_PROPERTIES.id];

  const engineModelType = newAttributes[SystemDrawioAttributes.modelType] as Models;
  const modelTypeOldValue = previousAttributes[SystemDrawioAttributes.modelType] as Models;

  // Это новая сущность и в изменениях ее обрабатывать не надо
  if (!modelIdOldValue || !modelTypeOldValue) {
    return;
  }

  if (!engineModelType) {
    return;
  }

  if (modelId !== modelIdOldValue) {
    console.error('model Id has changed', modelIdOldValue, '->', modelId, previousValue, newValue);
    return;
  }

  if (engineModelType !== modelTypeOldValue) {
    console.error('model Type has changed', modelTypeOldValue, '->', engineModelType, previousValue, newValue);
    return;
  }

  const changes: HandledAttributesChanges = {
    engineModelType,
    engineModelId: modelId,
    event: EventNames.change,
    oldAttributes: {},
    newAttributes: {}
  };

  const changedAttributes = Object.keys(newAttributes).filter((attrName) => {
    return previousAttributes[attrName] !== newAttributes[attrName];
  });
  if (changedAttributes.length > 0) {
    changedAttributes.forEach((attrName) => {
      const oldVal = previousAttributes[attrName];
      const newVal = newAttributes[attrName];
      changes.oldAttributes[attrName] = oldVal;
      changes.newAttributes[attrName] = newVal;
    });
    return changes;
  }
}

function handleModelChanges(
  mxUtils: MxUtils,
  mxValueChange: MxValueChange,
  changes: MxEventsChange[]
): HandledAttributesChanges[] | undefined {
  const handledChanges: HandledAttributesChanges[] = [];
  changes.forEach((change) => {
    if (change instanceof mxValueChange) {
      const previousValue = typeof change.previous === 'string' ? null : change.previous;
      const newValue = change.value;
      if (!!newValue && typeof newValue !== 'string' && mxUtils.isNode(newValue)) {
        const result = handleAttributeChange(previousValue, newValue);
        if (result) {
          handledChanges.push(result);
        }
      } else {
        console.error('newValue is not Node !!!!!!!!!!!!!!!!!!', previousValue, newValue);
      }
    }
  });
  return handledChanges.length ? handledChanges : undefined;
}

export function useSubscribeChangeModel(
  drawio: DRAWIO | undefined,
  listener: ChangesWithBase64Listener,
  getBase64onChanges: true
): void;
export function useSubscribeChangeModel(
  drawio: DRAWIO | undefined,
  listener: ChangesListener,
  getBase64onChanges?: false
): void;
export function useSubscribeChangeModel(
  drawio: DRAWIO | undefined,
  listener: ChangesWithBase64Listener | ChangesListener,
  getBase64onChanges = false
): void {
  useEffect(() => {
    if (!drawio) {
      return;
    }
    const {
      ui: {
        editor: { graph }
      },
      globals: { mxCodec, mxUtils, mxValueChange, mxGeometryChange, mxTerminalChange, mxRootChange }
    } = drawio;
    const model = graph.model;
    const drawioEventListener: EventListener<MxEventsData> = function (_, evt) {
      const changes = evt.getProperty(MxEventDataProperty.changes);
      const result = handleModelChanges(mxUtils, mxValueChange, changes);
      if (
        !result?.length &&
        !changes.some(
          (el) => el instanceof mxGeometryChange || el instanceof mxTerminalChange || el instanceof mxRootChange
        )
      ) {
        return;
      }
      if (getBase64onChanges) {
        const base64 = getBase64(graph, mxCodec, mxUtils);
        listener({ changes: result ?? [], base64 });
        return;
      }
      listener({ changes: result ?? [], base64: '' });
    };
    model.addListener(MxEvent.CHANGE, drawioEventListener);
    return () => {
      model.removeListener(MxEvent.CHANGE, drawioEventListener);
    };
  }, [drawio, getBase64onChanges, listener]);
}
