import { Models } from '@baseModel/engine/types';
import Stepper from '@components/basic/stepper/stepper';
import { useState } from 'react';
import { EditColumnField } from './editColumnField';
import { Engine } from '@baseModel/engine/engine';
import { TableBlockColumn, TableBlockColumnTypes } from '../../../types';
import { columnTypeItems } from '../../const';
import { EditColumnMenu } from './editColumnFieldMenu';

interface Props {
  currentColumn: TableBlockColumn;
  modelType: Models.Entity | Models.Relation;
  onSubmit: (tableColumn: TableBlockColumn, activeColumnIdx: number) => void;
  onClose: () => void;
  rowType: string;
  activeColumnIdx: number;
}

const engine = Engine.getInstance();

export const EditColumnStepper = ({ activeColumnIdx, currentColumn, modelType, onSubmit, onClose, rowType }: Props) => {
  const [editColumnValue, setEditColumnValue] = useState<TableBlockColumn>(currentColumn);
  const isAutoNumColumn = editColumnValue?.type === TableBlockColumnTypes.autonumber;

  const handleChangeColumnField = (fieldName: keyof TableBlockColumn, value?: string) => {
    setEditColumnValue((prev) => ({ ...prev, [fieldName]: value }));
  };

  const steps = [
    {
      label: 'Выберите тип колонки',
      content: (
        <>
          <EditColumnField
            items={columnTypeItems}
            name="type"
            onChange={handleChangeColumnField}
            value={editColumnValue.type || ''}
            label="Type"
          />
        </>
      )
    },

    {
      label: 'Отображаемое имя ( пустое возьмется с displayName)',
      cantNextStep: isAutoNumColumn,
      content: (
        <>
          <EditColumnField
            name="displayName"
            onChange={handleChangeColumnField}
            value={editColumnValue.displayName ?? ''}
            isTextField
            label="Display-name"
          />
        </>
      )
    },

    {
      label: 'Установите отображаемое значение',
      content: (
        <>
          <EditColumnMenu
            label="Значение или связь"
            onChange={(v) => handleChangeColumnField('value', v)}
            value={editColumnValue.value}
            engine={engine}
            rowType={rowType}
            modelType={modelType}
          />
        </>
      )
    }
  ];

  return <Stepper steps={steps} onSubmit={() => onSubmit(editColumnValue, activeColumnIdx)} onClose={onClose} />;
};
