import { REFLECTION_TAG } from '../../consts';
import { EntityMetaModel } from '@baseModel/metaModel/entityMetaModel';
import { RelationMetaModel } from '@baseModel/metaModel/relationMetaModel';
import { TableBlockColumn } from '../../types';

export function getColumnHeader(metaModel: EntityMetaModel | RelationMetaModel, column: TableBlockColumn) {
  if (column.displayName) {
    return column.displayName;
  }
  const pathStr = column.value;
  if (!pathStr) {
    return 'empty header';
  }
  const path = pathStr.split('.');
  if (path[0] !== REFLECTION_TAG) {
    return pathStr;
  }

  try {
    const ref = metaModel.getValueRefByPath(path.slice(1).join('.'));
    const value = ref.getter();

    if (value === undefined) {
      return 'Не найдено';
    }
    if (typeof value === 'string') {
      return value;
    }
    if (value['display-name']) {
      return value['display-name'];
    }
    return JSON.stringify(value);
  } catch (e) {
    console.error(e);
    return 'Не найдено';
  }
}
