import { ErrorBoundary } from 'react-error-boundary';
import './styles/index.less';

import React from 'react';
import { BrowserRouter, Routes, Route, HashRouter, Navigate } from 'react-router-dom';
import { Layout } from '@components/layout/layout';
import { Document } from '@pages/document/document';
import { SnackbarProvider } from '@smwb/summer-ui';
import { Urls } from './routes';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { FallbackRender } from '@components/fallbackRender/fallbackRender';
import { CustomWidgetsHandler } from '@components/markdownEditor/dataDisplayWidgets';

const isDev = process.env.REACT_APP_USE_HASH_ROUTER === '1' && process.env.PUBLIC_URL;

export function App() {
  const RouterAPI = isDev ? HashRouter : BrowserRouter;
  const WidgetConfigLayout = CustomWidgetsHandler.getConfigComponent();

  return (
    <ErrorBoundary
      fallbackRender={FallbackRender}
      onReset={(details) => {
        console.log('ErrorBoundary details', details);
      }}
    >
      <Provider store={store}>
        <DndProvider backend={HTML5Backend}>
          <SnackbarProvider>
            <>
              <WidgetConfigLayout />
              <RouterAPI>
                <Routes>
                  <Route path={isDev ? process.env.PUBLIC_URL : '/'} element={<Layout />}>
                    <Route path={Urls.documents} element={<Document />} />
                  </Route>
                  <Route
                    path="/*"
                    element={<Navigate to={`${isDev ? process.env.PUBLIC_URL : ''}/${Urls.documents}`} />}
                  />
                </Routes>
              </RouterAPI>
            </>
          </SnackbarProvider>
        </DndProvider>
      </Provider>
    </ErrorBoundary>
  );
}
