import React from 'react';
import {
  BaseWidget,
  ConfigViewProps,
  EditorViewProps
} from '@components/markdownEditor/dataDisplayWidgets/baseWidget/baseWidget';
import { GenerateMetaModelButton } from './generateMetaModelButton';
import { object, ObjectSchema, string } from 'yup';
import { WidgetValue } from './types';

const valueSchema: ObjectSchema<Partial<WidgetValue>> = object().shape({
  title: string()
});

export class GenerateMetaModelWidget extends BaseWidget<WidgetValue> {
  public override valueSchema = valueSchema;

  public override getName(): string {
    return 'generateMetaModel';
  }

  public override getMenuItem(): undefined {
    return;
  }

  public override getEditorView(): React.FC<EditorViewProps<WidgetValue>> | null {
    return GenerateMetaModelButton;
  }

  public override getConfigView(): React.FC<ConfigViewProps<WidgetValue>> | null {
    return null;
  }

  protected getInitConfig(): Promise<undefined> {
    return Promise.resolve(undefined);
  }
}

CustomWidgets.register(GenerateMetaModelWidget);
